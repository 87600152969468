import { Dispatch, SetStateAction } from "react";
import { PLATFORM_VIEW_MODE } from "../components/express/types/express.enum";
import { ACCESS_TYPES, COMPANY_TYPES } from "../utility/enums/user.enums";

export interface Company {
  parent_Id: string | null;
  name: string;
  email: string;
  authToken: string;
  website: string;
  isActive: boolean;
  isDeleted: boolean;
  companyType: COMPANY_TYPES;
  countries: [];
  campaigns: [];
  categories: [];
  emailTemplates: CompanyEmailTemplate[];
  networks: CompanyNetwork[];
  contactDetails: {
    phones: number[];
    addresses: string[];
  };
  pagePlacements: [];
  timeZone: {
    id: string;
    displayName: string;
    supportsDaylightSavingTime: boolean;
    baseUtcOffset: string;
  };
  logoUrl: string;
  id: string;
}

export interface UserCompany {
  company_Id: string;
  companyType: COMPANY_TYPES;
  userRole?: number;
  companyCurrentType: COMPANY_TYPES;
  name: string;
  platformViewMode: PLATFORM_VIEW_MODE;
  userAccess?: UserCompanyAccess;
}

export interface UserCompanyAccess {
  access: ACCESS_TYPES[];
}

export interface CompanyNetwork {
  id: string;
  external_Pid: number;
  external_Aid: number;
  commissionSharing_Id: string | null;
  name: string;
  uniqueIdentifier: string;
}

export interface CompanyEmailTemplate {
  emailTemplateId: string;
  emailTemplateName: string;
}

export interface CompanyContextType {
  company?: UserCompany;
  setCompany: Dispatch<SetStateAction<UserCompany>>;
}

export interface CompanyCategory {
  id: string;
  name: string;
}

interface RegistrationCountry {
  id: string;
  isoCode: string;
  name: string;
  currencySymbol: string;
}

export interface Network {
  id: string;
  name: string;
  external_Pid: string;
  external_Aid: string;
  uniqueIdentifier: string;
}

export interface NetworkSettings {
  name: string;
  logoUrl: string;
  uniqueIdentifier: string;
  brandSwapId: string;
  id: string;
}

interface ContactDetails {
  phones: string[];
  addresses: string[];
}

interface PagePlacement {
  id: string;
  name: string;
  url: string;
  pageType_Id: string;
  identifier: string;
}

interface Timezone {
  id: string;
  name: string;
  displayName: string;
  stdOffset: string;
  dstOffset: string;
}

export interface EmailTemplate {
  emailTemplateId: string;
  emailTemplateName: string;
}

interface CommissionAmount {
  amount: number;
  percentage: number;
}

interface CompanyMetrics {
  averagePaymentTime: number | null;
  approvalPercentage: number;
  conversionRate: number;
  epc: number;
  validationDays: number;
  index: number;
  updatedAt: string | null;
}

interface Payments {
  origin: string | null;
  completionStatus: string;
  accountId: string | null;
  refreshURL: string | null;
}

interface ActivationSettings {
  platform: string;
  scriptURL: string;
}

export interface Finance {
  name: string;
  address: string;
  addressTwo: string;
  city: string;
  state: string;
  vat: string;
  postCode: string;
  paymentDetails: {
    sortCode: string;
    accountName: string;
    accountNumber: string;
    iban: string;
    swift: string;
    paymentDays: string;
    allowVAT: boolean;
    vat: number;
  };
}

export interface CompanyDataLayerKeyMap {
  parent: string;
  key: string;
}

export interface CompanyDataLayerKey {
  allowFilters: boolean;
  format: string;
  key: string;
  map: CompanyDataLayerKeyMap;
  name: string;
}

export interface CompanyDataLayer {
  keys: CompanyDataLayerKey[];
}

export interface CompanySettings {
  allowOrderCollection: boolean;
  dataLayer: CompanyDataLayer;
  showDLKeysInReports: boolean;
}

export interface CompanySettingsInterface {
  parent_Id: string | null;
  name: string;
  email: string;
  url: string | null;
  companyType: string;
  countries: any[]; // Replace `any` with appropriate type if known
  registrationCountry: RegistrationCountry;
  categories: any[]; // Replace `any` with appropriate type if known
  excludedCategories: any[]; // Replace `any` with appropriate type if known
  exclusiveAdvertisers: any[]; // Replace `any` with appropriate type if known
  exclusiveCategories: any[]; // Replace `any` with appropriate type if known
  networks: Network[];
  contactDetails: ContactDetails;
  pagePlacements: PagePlacement[];
  timezone: Timezone;
  logoUrl: string;
  allowedDomains: any[]; // Replace `any` with appropriate type if known
  emailTemplates: EmailTemplate[];
  commissionSharingId: string;
  platformViewMode: string;
  autoApproveOffers: boolean;
  commissionAmount: CommissionAmount;
  companyMetrics: CompanyMetrics;
  payments: Payments;
  activationSettings: ActivationSettings;
  id: string;
  authToken: string;
  isActive: boolean;
  groupSettings: {
    parentId: string | null;
    isMaster: boolean;
    isInGroup: boolean;
  };
  weekDayStart: number;
  finance: Finance;
  settings: CompanySettings;
}

const emptyFinance: Finance = {
  name: "",
  address: "",
  addressTwo: "",
  city: "",
  state: "",
  vat: "",
  postCode: "",
  paymentDetails: {
    sortCode: "",
    accountName: "",
    accountNumber: "",
    iban: "",
    swift: "",
    paymentDays: "",
    allowVAT: false,
    vat: 0,
  },
};

export const emptyCompany: CompanySettingsInterface = {
  parent_Id: null,
  name: "",
  email: "",
  url: null,
  authToken: "",
  companyType: "",
  countries: [],
  registrationCountry: {
    isoCode: "GB",
    name: "United Kingdom",
    currencySymbol: "£",
    id: "64255ba1f404f74a0bd1fad0",
  },
  categories: [],
  excludedCategories: [],
  exclusiveAdvertisers: [],
  exclusiveCategories: [],
  networks: [],
  contactDetails: {
    phones: [],
    addresses: [],
  },
  pagePlacements: [],
  timezone: {
    id: "640f0f329cb5c4a06ed2f592",
    name: "Europe/Amsterdam",
    displayName: "Europe/Amsterdam (UTC+01:00)",
    stdOffset: "+01:00",
    dstOffset: "+02:00",
  },
  logoUrl: "",
  allowedDomains: [],
  emailTemplates: [],
  commissionSharingId: "",
  platformViewMode: "",
  autoApproveOffers: false,
  commissionAmount: {
    amount: 0,
    percentage: 0,
  },
  companyMetrics: {
    averagePaymentTime: null,
    approvalPercentage: 0,
    conversionRate: 0,
    epc: 0,
    validationDays: 0,
    index: 0,
    updatedAt: null,
  },
  payments: {
    origin: null,
    completionStatus: "notstarted",
    accountId: null,
    refreshURL: null,
  },
  activationSettings: {
    platform: "",
    scriptURL: "",
  },
  id: "",
  isActive: true,
  groupSettings: {
    parentId: null,
    isMaster: false,
    isInGroup: false,
  },
  weekDayStart: 1,
  finance: emptyFinance,
  settings: {
    allowOrderCollection: true,
    dataLayer: {
      keys: [],
    },
    showDLKeysInReports: false,
  },
};

export enum COMPANY_SETTINGS_STEPS {
  details = "details",
  offers = "offers",
  layout = "layout",
}

export interface WeekDays {
  name: string;
  value: number;
}
