import { Dropdown, Empty, message } from "antd";
import { useEffect, useMemo, useState } from "react";
import useAuth from "../../hooks/useAuth.hook";
import useAxios from "../../hooks/useAxios.hook";
import { DropDown } from "../../types/global.interfaces";
import "./css/insight.scss";
import {
  InsightCampaign,
  InsightCampaignPlacement,
  InsightsCampaignDaily,
  InsightsHostPartners,
} from "../../types/events.interfaces";
import Checkbox, { CheckboxChangeEvent } from "antd/lib/checkbox/Checkbox";
import InsightWidgets from "./Insight-widgets.component";
import { PlacementStageInsight } from "../../types/templates.interfaces";
import { Filters } from "./Insight";

const getPlacementsName = (placement?: InsightCampaignPlacement[]): string => {
  const pName: string[] = placement
    ? placement.map((value: InsightCampaignPlacement) => value.name)
    : [];
  return pName.join(", ");
};

const InsightNew = ({
  activeClass,
  insightFilters,
  filterTrigger,
}: {
  activeClass: PlacementStageInsight;
  insightFilters: Filters;
  filterTrigger: number;
}) => {
  const { auth } = useAuth();
  const { company } = useAuth();
  const { myAxios } = useAxios();
  const [insightCampaings, setInsightCampaigns] = useState<InsightCampaign[]>();
  const [selectedCampaign, setSelectedCampaign] = useState<InsightCampaign>();
  const [selectedPlacements, setSelectedPlacements] = useState<
    InsightCampaignPlacement[]
  >([]);
  const [partnersOffers, setPartnersOffers] = useState<InsightsHostPartners>();
  const [campaignDaily, setCampaignDaily] = useState<InsightsCampaignDaily>();
  const [loading, setLoading] = useState<boolean>(false);
  const [start, setStart] = useState<boolean>(true);
  const [placementIds, setPlacementIds] = useState<string[]>([]);

  const fetchPartnersOffers = async (companyId: string, campaignId: string) => {
    setLoading(true);
    const { response } = await myAxios({
      method: "post",
      url: `insights/host/partners/${companyId}/${campaignId}`,
      data: {
        ...insightFilters,
        placementIds: placementIds,
      },
    });

    if (response?.data?.status) {
      setPartnersOffers(response.data.result);
    } else {
      message.error("Failed to get partners offers", 1);
    }
    setLoading(false);
  };

  const fetchCampaignDaily = async (companyId: string, campaignId: string) => {
    setLoading(true);
    const { response } = await myAxios({
      method: "post",
      url: `/insights/host/campaignsdaily/${companyId}/${campaignId}`,
      data: {
        ...insightFilters,
        placementIds: placementIds,
      },
    });

    if (response?.data?.status) {
      setCampaignDaily(response.data.result.data);
    } else {
      message.error("Failed to get partners offers", 1);
    }
    setLoading(false);
  };

  const fetchCampaigns = async (companyId: string) => {
    setLoading(true);
    const { response } = await myAxios({
      method: "get",
      url:
        activeClass === "all"
          ? `campaign/forinsights/${companyId}`
          : `campaign/forinsights/${companyId}/${activeClass}`,
    });

    if (response?.data?.status) {
      setInsightCampaigns(response.data.result.data);
    } else {
      message.error("Failed to get Campaings", 1);
    }
  };

  // get the campaign first
  useEffect(() => {
    if (company && auth) fetchCampaigns(company.company_Id);
    setStart(true);
    // eslint-disable-next-line
  }, [company, auth, activeClass]);

  // then call the offers and campaign daily campaign first
  useMemo(() => {
    if (selectedCampaign && company) {
      fetchPartnersOffers(company.company_Id, selectedCampaign.id);
      fetchCampaignDaily(company.company_Id, selectedCampaign.id);
      setStart(false);
    }
    // eslint-disable-next-line
  }, [selectedCampaign, company, filterTrigger]);

  const filterByCampaigns: DropDown[] = useMemo(() => {
    setLoading(true);
    let _insightCampaigns: DropDown[] = [
      { label: "no data", key: "no-data", disabled: true },
    ];
    if (insightCampaings && insightCampaings?.length > 0) {
      const _campaign = selectedCampaign
        ? insightCampaings.find((f) => f.id === selectedCampaign.id)
        : insightCampaings[0];
      if (_campaign) {
        const _placementIds = _campaign.placementSettings.map((p) => p._id);
        setSelectedCampaign(_campaign);
        setSelectedPlacements(_campaign.placementSettings);
        setPlacementIds(_placementIds);
      }
      _insightCampaigns = insightCampaings?.map((campaign) => ({
        label: campaign.name,
        key: campaign.id,
      }));
    }

    setLoading(false);
    return _insightCampaigns;
    // eslint-disable-next-line
  }, [insightCampaings]);

  const handleChangeCampaign = (
    key: string,
    _insightCampaigns: InsightCampaign[] | undefined
  ) => {
    const _campaign = _insightCampaigns?.find((f) => f.id === key);
    if (_campaign) {
      const _placementIds = _campaign.placementSettings.map((p) => p._id);
      setSelectedPlacements(_campaign.placementSettings);
      setPlacementIds(_placementIds);
      setSelectedCampaign(_campaign);
    } else {
      setPlacementIds([]);
      setSelectedPlacements([]);
      setSelectedCampaign(undefined);
    }
  };

  const handleChangePlacement = (
    checked: boolean,
    _insightPlacements: InsightCampaignPlacement
  ) => {
    if (checked) {
      setPlacementIds([...placementIds, _insightPlacements._id]);
      setSelectedPlacements((prevState) => [...prevState, _insightPlacements]);
    } else {
      handleRemovePlacement(_insightPlacements._id);
    }
  };

  const handleCheckedPlacement = (id: string): boolean => {
    let isChecked = selectedPlacements.map((p) => p._id).includes(id);
    return isChecked;
  };

  const handleRemovePlacement = (id: string) => {
    const _placements = selectedPlacements.filter((f) => f._id !== id);
    setSelectedPlacements(_placements);
    setPlacementIds((prevState) => {
      return prevState.filter((f) => f !== id);
    });
  };

  return (
    <>
      <div className="layout-options">
        <div className="layout-actions">
          <Dropdown
            className="min150 mright20"
            menu={{
              items: filterByCampaigns,
              selectable: true,
              selectedKeys: [selectedCampaign?.id || "No data"],
              onClick: (data) => {
                handleChangeCampaign(data.key, insightCampaings);
              },
            }}
            trigger={["click"]}
          >
            <div className="button-dropdown button-icon flex-center-space-between">
              <span className="max-1line">
                Campaign: {selectedCampaign?.name || "No data"}
              </span>{" "}
              <span className="icon small icon-arrow-down"></span>
            </div>
          </Dropdown>
        </div>

        <div className="filters">
          <Dropdown
            className="dropdown-medium"
            dropdownRender={(menu) => {
              return (
                <div className="dropdown">
                  {selectedPlacements && selectedPlacements.length > 0 && (
                    <>
                      <div className="dropdown-title">Selected placements:</div>
                      <ul className="list-tags">
                        {selectedPlacements?.map(
                          (placement: InsightCampaignPlacement) => {
                            return (
                              <li key={`selected-placements-${placement._id}`}>
                                {placement.name}
                                <span
                                  className="icon icon-remove mleft10 font-hover"
                                  onClick={() =>
                                    handleRemovePlacement(placement._id)
                                  }
                                ></span>
                              </li>
                            );
                          }
                        )}
                      </ul>
                    </>
                  )}
                  <div className="dropdown-title">Placements found:</div>
                  <ul>
                    {selectedCampaign?.placementSettings?.map(
                      (placement: InsightCampaignPlacement) => {
                        return (
                          <li key={`details-plc-${placement._id}`}>
                            <Checkbox
                              checked={handleCheckedPlacement(placement._id)}
                              onChange={(e: CheckboxChangeEvent) =>
                                handleChangePlacement(
                                  e.target.checked,
                                  placement
                                )
                              }
                              className="checkbox-active"
                            >
                              {placement.name}
                            </Checkbox>
                          </li>
                        );
                      }
                    )}
                    {selectedCampaign &&
                      selectedCampaign?.placementSettings.length === 0 && (
                        <li>
                          <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description="No data"
                          />
                        </li>
                      )}
                  </ul>
                </div>
              );
            }}
            trigger={["click"]}
          >
            <div className="button-dropdown button-icon flex-center-space-between">
              <span className="max-1line">
                {" "}
                Placements: {getPlacementsName(selectedPlacements) ||
                  "No data"}{" "}
              </span>
              <span className="icon small icon-arrow-down"></span>
            </div>
          </Dropdown>
        </div>
      </div>

      <InsightWidgets
        placementType={activeClass}
        loading={loading}
        partnersOffers={partnersOffers}
        campaignDaily={campaignDaily}
        campaignId={selectedCampaign?.id}
        companyId={company?.company_Id}
        filters={insightFilters}
      ></InsightWidgets>
    </>
  );
};

export default InsightNew;
