import { InfoCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

interface Props {
  record: DataType;
}

interface DataType {
  key?: React.Key;
  brand?: string;
  offer?: string;
  impressions?: number;
  clicks?: number;
  shareOfClicks?: string;
  ctr?: string;
  sales?: number;
  cr?: string;
  cpa?: string;
  commision?: string;
  epc?: string;
  ecpm?: string;
  transactionsWebPendingCount: number;
  transactionsWebApprovedCount: number;
  transactionsAutoEmailPendingCount: number;
  transactionsAutoEmailApprovedCount: number;
  transactionsManualEmailPendingCount: number;
  transactionsManualEmailApprovedCount: number;
  transactionsSharePendingCount: number;
  transactionsShareApprovedCount: number;
  totalWebPendingAmount: number;
  totalWebApprovedAmount: number;
  totalAutoEmailApprovedAmount: number;
  totalAutoEmailPendingAmount: number;
  totalManualEmailPendingAmount: number;
  totalManualEmailApprovedAmount: number;
  totalSharePendingAmount: number;
  totalShareApprovedAmount: number;
}

const SalesTooltip = ({ record }: Props) => {
  return (
    <Tooltip
      overlayInnerStyle={{
        backgroundColor: "white",
        color: "black",
        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
        width: "250px",
      }}
      className="sales-tooltip"
      title={
        <div className="sales-tooltip-content">
          {(record.transactionsWebPendingCount > 0 ||
            record.transactionsWebApprovedCount > 0) && (
            <div className="sales-tooltip-content-item">
              <h1 className="sales-tooltip-content-item-title">Web</h1>
              {record.transactionsWebPendingCount > 0 && (
                <p className="sales-tooltip-content-item-count">
                  Pending: {record.transactionsWebPendingCount}
                </p>
              )}
              {record.transactionsWebApprovedCount > 0 && (
                <p className="sales-tooltip-content-item-count">
                  Approved: {record.transactionsWebApprovedCount}
                </p>
              )}
            </div>
          )}

          {(record.transactionsAutoEmailPendingCount > 0 ||
            record.transactionsAutoEmailApprovedCount > 0 ||
            record.transactionsManualEmailPendingCount > 0 ||
            record.transactionsManualEmailApprovedCount > 0) && (
            <>
              <h1 className="sales-tooltip-content-item-title">Email</h1>

              {(record.transactionsAutoEmailPendingCount > 0 ||
                record.transactionsAutoEmailApprovedCount > 0) && (
                <div className="sales-tooltip-content-item">
                  <h1 className="sales-tooltip-content-item-subtitle mleft-10">
                    Auto
                  </h1>
                  {record.transactionsAutoEmailPendingCount > 0 && (
                    <p className="sales-tooltip-content-item-count">
                      Pending: {record.transactionsAutoEmailPendingCount}
                    </p>
                  )}
                  {record.transactionsAutoEmailApprovedCount > 0 && (
                    <p className="sales-tooltip-content-item-count">
                      Approved: {record.transactionsAutoEmailApprovedCount}
                    </p>
                  )}
                </div>
              )}

              {(record.transactionsManualEmailPendingCount > 0 ||
                record.transactionsManualEmailApprovedCount > 0) && (
                <div className="sales-tooltip-content-item">
                  <h1 className="sales-tooltip-content-item-subtitle mleft-10">
                    Manual
                  </h1>
                  {record.transactionsManualEmailPendingCount > 0 && (
                    <p className="sales-tooltip-content-item-count">
                      Pending: {record.transactionsManualEmailPendingCount}
                    </p>
                  )}
                  {record.transactionsManualEmailApprovedCount > 0 && (
                    <p className="sales-tooltip-content-item-count">
                      Approved: {record.transactionsManualEmailApprovedCount}
                    </p>
                  )}
                </div>
              )}
            </>
          )}

          {(record.transactionsSharePendingCount > 0 ||
            record.transactionsShareApprovedCount > 0) && (
            <div className="sales-tooltip-content-item">
              <h1 className="sales-tooltip-content-item-title">Share</h1>
              {record.transactionsSharePendingCount > 0 && (
                <p className="sales-tooltip-content-item-count">
                  Pending: {record.transactionsSharePendingCount}
                </p>
              )}
              {record.transactionsShareApprovedCount > 0 && (
                <p className="sales-tooltip-content-item-count">
                  Approved: {record.transactionsShareApprovedCount}
                </p>
              )}
            </div>
          )}
        </div>
      }
    >
      <InfoCircleOutlined style={{ marginLeft: 8, cursor: "pointer" }} />
    </Tooltip>
  );
};

export default SalesTooltip;
