import { Collapse } from "antd";
import CollapsePanel from "antd/lib/collapse/CollapsePanel";
import {
  DealLayoutSettings,
  elementSettings,
  MainLayoutSettings,
  OtherSettingsInterface,
  PlacementSettings,
} from "../../../../../../../types/campaign.interfaces";
import { LayoutDevices } from "../../../../../const/views";
import MainLayoutSettingsComponent from "../../../options/templateOptions/MainLayoutSettings.component";
import {
  ButtonShapes,
  ElementSettings,
  LocationSettings,
} from "../../../../../../templates-previews/types/params.interfaces";
import DealLayoutSettingsComponent from "../../../options/templateOptions/DealLayoutSettings.component";
import EmbeddedElementSettings from "../../../options/templateOptions/ElementSettings.component";
import { PlacementStage } from "../../../../../../../types/templates.interfaces";
import OtherSettings from "../../../options/templateOptions/OtherSettings";

interface Props {
  updateMainButtonShapeSettings: (value: ButtonShapes) => void;
  updateDealLayout: (value: DealLayoutSettings) => void;
  updateMainLayoutSettings: (value: MainLayoutSettings) => void;
  updateLocationSettings: (value: LocationSettings) => void;
  updateElementSettings: (value: ElementSettings) => void;
  updateOtherSettings: (value: OtherSettingsInterface) => void;
  layoutDevice: LayoutDevices;
  currentPlacement: PlacementSettings;
  defaultTitle: string;
  campaignId: string;
  showSelectSettings?: boolean;
}

const SettingsEs = ({
  updateMainButtonShapeSettings,
  updateMainLayoutSettings,
  updateDealLayout,
  updateElementSettings,
  updateOtherSettings,
  currentPlacement,
  layoutDevice,
  campaignId,
  showSelectSettings,
}: Props) => {
  return (
    <>
      <div className="settings-options-list">
        <Collapse defaultActiveKey={[""]} accordion>
          <CollapsePanel
            showArrow={false}
            header={
              <div className="option-title">
                Element <span className="icon icon-collapse-mix"></span>
              </div>
            }
            key="element"
          >
            <div className="settings-option">
              <EmbeddedElementSettings
                settings={
                  currentPlacement.templateSettings[layoutDevice].settings
                    ?.embeddedElement || elementSettings
                }
                updateElementSettings={updateElementSettings}
                layoutDevice={layoutDevice}
                campaignId={campaignId}
                template={
                  currentPlacement.templateSettings[layoutDevice].template
                }
              />
            </div>
          </CollapsePanel>
          {currentPlacement.templateSettings[layoutDevice].template?.stage ===
            PlacementStage.pre &&
            showSelectSettings && (
              <CollapsePanel
                showArrow={false}
                header={
                  <div className="option-title">
                    Settings <span className="icon icon-collapse-mix"></span>
                  </div>
                }
                key="other-settings"
              >
                <div className="settings-option">
                  <OtherSettings
                    otherSettings={
                      currentPlacement.templateSettings[layoutDevice].settings
                        ?.otherSettings
                    }
                    updateOtherSettings={updateOtherSettings}
                  />
                </div>
              </CollapsePanel>
            )}
          <CollapsePanel
            showArrow={false}
            header={
              <div className="option-title">
                Main Layout <span className="icon icon-collapse-mix"></span>
              </div>
            }
            key="main-layout"
          >
            <div className="settings-option">
              <MainLayoutSettingsComponent
                updateMainButtonShape={updateMainButtonShapeSettings}
                buttonShape={
                  currentPlacement.templateSettings[layoutDevice].button.shape
                }
                mainLayout={
                  currentPlacement.templateSettings[layoutDevice].settings
                    ?.mainLayout
                }
                updateMainLayoutSettings={updateMainLayoutSettings}
                template={
                  currentPlacement.templateSettings[layoutDevice].template
                }
                showSelectSettings={showSelectSettings}
              />
            </div>
          </CollapsePanel>
          <CollapsePanel
            showArrow={false}
            header={
              <div className="option-title">
                Deal Layout <span className="icon icon-collapse-mix"></span>
              </div>
            }
            key="deal-layout"
          >
            <div className="settings-option">
              <DealLayoutSettingsComponent
                template={
                  currentPlacement.templateSettings[layoutDevice].template
                }
                displayOnly={currentPlacement.displayOnly}
                dealLayout={
                  currentPlacement.templateSettings[layoutDevice].settings
                    ?.dealLayout
                }
                updateDealLayoutSettings={updateDealLayout}
                layoutDevice={layoutDevice}
              />
            </div>
          </CollapsePanel>
          {/* <CollapsePanel
                    showArrow={false}
                    header={
                        <div className="option-title">
                            Layout <span className="icon icon-collapse-mix"></span>
                        </div>
                    }
                    key="general">
                    <div className="settings-option">
                        <ActionGeneralSettings settings={currentPlacement.templateSettings} updateSettings={updateSettings} layoutDevice={layoutDevice} />
                    </div>
                </CollapsePanel>

                <CollapsePanel
                    showArrow={false}
                    header={
                        <div className="option-title">
                            Header <span className="icon icon-collapse-mix"></span>
                        </div>
                    }
                    key="header">
                    <div className="settings-option">
                        <ActionHeaderSettings placement={currentPlacement} defaultTitle={defaultTitle} updateSettings={updateSettings} layoutDevice={layoutDevice} />
                    </div>
                </CollapsePanel>

                <CollapsePanel
                    showArrow={false}
                    header={
                        <div className="option-title">
                            Text & images <span className="icon icon-collapse-mix"></span>
                        </div>
                    }
                    key="text">
                    <div className="settings-option">
                        <ActionTextImagesSettings settings={currentPlacement.templateSettings} updateSettings={updateSettings} layoutDevice={layoutDevice} />
                    </div>
                </CollapsePanel> */}

          {/* <CollapsePanel
                    showArrow={false}
                    header={
                        <div className="option-title">
                            Image representation <span className="icon icon-collapse-mix"></span>
                        </div>
                    }
                    key="image">
                    <div className="settings-option">
                        <PopupImageRepresentationSettings settings={currentPlacement.templateSettings} updateSettings={updateSettings} layoutDevice={layoutDevice} />
                    </div>
                </CollapsePanel> */}

          {/* {!currentPlacement.displayOnly &&
                    <CollapsePanel
                        showArrow={false}
                        header={
                            <div className="option-title">
                                Get offer <span className="icon icon-collapse-mix"></span>
                            </div>
                        }
                        key="readmore">
                        <div className="settings-option">
                            <ActionGetButtonSettings settings={currentPlacement.templateSettings} updateSettings={updateSettings} layoutDevice={layoutDevice} />
                        </div>
                    </CollapsePanel>
                } */}
        </Collapse>
      </div>
    </>
  );
};

export default SettingsEs;
