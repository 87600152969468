import { LoadingOutlined } from "@ant-design/icons";
import { Empty } from "antd";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { useEffect, useRef, useState } from "react";
import { InsightsCampaignPartnersData } from "../../../../types/events.interfaces";
import { PlacementStageInsight } from "../../../../types/templates.interfaces";

interface Props {
  loading: boolean;
  partnersData: InsightsCampaignPartnersData[];
  placementStage: PlacementStageInsight;
}

const PartnersSalesChart = ({
  loading,
  partnersData,
  placementStage,
}: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [options, setOptions] = useState<Highcharts.Options>();

  useEffect(() => {
    const getChartData = (data: InsightsCampaignPartnersData[]) => {
      const _allData = data.map((value) => ({
        name: value.brand.oname,
        y:
          placementStage === PlacementStageInsight.pre
            ? value.totalAutoEmailSalesCount
            : value.totalSalesCount,
      }));

      const _data = _allData.filter((f) => f.y > 0).sort((a, b) => b.y - a.y);

      const _chartOptions: Highcharts.Options = {
        title: {
          text: "",
        },
        subtitle: {
          text: "",
        },
        chart: {
          height: containerRef?.current?.clientHeight,
          width: containerRef?.current?.clientWidth,
        },
        tooltip: {
          shared: true,
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            showInLegend: true,
            cursor: "pointer",
          },
        },
        legend: {
          align: "center",
          layout: "vertical",
          verticalAlign: "bottom",
          labelFormat: "<b>{name}</b> - {percentage:.1f}% - {y} Sales",
          backgroundColor: "var(--secondary-color)",
          maxHeight: 90,
          itemMarginTop: 5,
          borderRadius: 6,
          padding: 10,
        },
        series: [
          {
            name: "Clicks",
            type: "pie",
            colorByPoint: true,
            data: _data,
            tooltip: {
              pointFormat: "{point.percentage:.1f}%<br>{point.y} Sales",
            },
          },
        ],
        credits: {
          enabled: false,
        },
      };

      setOptions(_chartOptions);
    };

    partnersData && getChartData(partnersData);
  }, [partnersData, placementStage]);

  return (
    <>
      <div className="widget-description"></div>
      {partnersData && partnersData?.length > 0 ? (
        <div ref={containerRef} className="widget-content">
          {options && (
            <div
              id="chart-container"
              className={`chart-container ${
                loading ? "chart-container-loading" : ""
              }`}
            >
              <HighchartsReact highcharts={Highcharts} options={options} />

              {loading && <LoadingOutlined />}
            </div>
          )}
        </div>
      ) : (
        <>
          <Empty className="mtop20 mbot20" />
        </>
      )}
      <div className="widget-footer"></div>
    </>
  );
};

export default PartnersSalesChart;
