import { useRef } from "react";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  CampaignType,
  TemplateOptionSettings,
} from "../../../../../../../types/campaign.interfaces";
import { Offer } from "../../../../../../../types/offer.interfaces";
import OfferItem from "../preview/offer-item/OfferItem.component";

interface Props {
  displayOnly: boolean;
  settings: TemplateOptionSettings;
  offers: Offer[];
  disabled: boolean;
  campaign: CampaignType;
}

const SwiperComponent = ({
  displayOnly,
  settings,
  offers,
  disabled,
  campaign,
}: Props) => {
  const refNavPrev = useRef<HTMLDivElement | null>(null);
  const refNavNext = useRef<HTMLDivElement | null>(null);

  return (
    <>
      {offers.length > 0 && (
        <Swiper
          className={`swiper-container  ${
            disabled ? "swiper-disabled swiper-no-swiping" : ""
          }`}
          modules={[Navigation]}
          navigation={{
            prevEl: refNavPrev.current,
            nextEl: refNavNext.current,
          }}
          onBeforeInit={(swiper: any) => {
            swiper.params.navigation.prevEl = refNavPrev.current;
            swiper.params.navigation.nextEl = refNavNext.current;
          }}
          slidesPerView={disabled ? (offers.length > 4 ? 4 : "auto") : "auto"}
          loop={!disabled && offers.length > 4}
          onSlideChange={() => {}}
        >
          {offers.map((o: Offer, i: number) => {
            return (
              <SwiperSlide
                key={`slide${i}`}
                style={{ color: settings?.dealLayout.textColor }}
              >
                <OfferItem
                  offer={o}
                  settings={settings?.dealLayout}
                  otherSettings={settings?.otherSettings}
                  displayOnly={displayOnly}
                  campaign={campaign}
                />
              </SwiperSlide>
            );
          })}
          {offers.length > 4 && (
            <>
              <div className="swiper-button-prev" ref={refNavPrev}>
                <svg
                  width="34"
                  height="34"
                  viewBox="0 0 34 34"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="17"
                    cy="17"
                    r="17"
                    transform="rotate(-180 17 17)"
                    fill={settings.dealLayout.backgroundColor}
                  />
                  <rect
                    x="18.6094"
                    y="23"
                    width="8.6192"
                    height="1.72384"
                    rx="0.86192"
                    transform="rotate(-135 18.6094 23)"
                    fill={settings.dealLayout.textColor}
                  />
                  <rect
                    x="20.3135"
                    y="12.2188"
                    width="8.6192"
                    height="1.72384"
                    rx="0.86192"
                    transform="rotate(135 20.3135 12.2188)"
                    fill={settings.dealLayout.textColor}
                  />
                </svg>
              </div>
              <div className="swiper-button-next" ref={refNavNext}>
                <svg
                  width="34"
                  height="34"
                  viewBox="0 0 34 34"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="17"
                    cy="17"
                    r="17"
                    fill={settings.dealLayout.backgroundColor}
                  />
                  <rect
                    x="15.3906"
                    y="11"
                    width="8.6192"
                    height="1.72384"
                    rx="0.86192"
                    transform="rotate(45 15.3906 11)"
                    fill={settings.dealLayout.textColor}
                  />
                  <rect
                    x="14.1719"
                    y="22.2656"
                    width="8.6192"
                    height="1.72384"
                    rx="0.86192"
                    transform="rotate(-45 14.1719 22.2656)"
                    fill={settings.dealLayout.textColor}
                  />
                </svg>
              </div>
            </>
          )}
        </Swiper>
      )}
    </>
  );
};

export default SwiperComponent;
