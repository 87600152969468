import { Empty } from "antd";
import { useMemo } from "react";
import {
  CampaignType,
  PlacementSettings,
  templateDefaultSettings,
} from "../../../../../types/campaign.interfaces";
import {
  compactTemplates,
  LayoutType,
  PlacementStage,
  Template,
  TemplateFilters,
} from "../../../../../types/templates.interfaces";
import PreviewTemplate from "../../../../templates-previews/PreviewTemplate.component";
import { LayoutClass } from "../../../const/views";
import PreviewPopupC13 from "../items/templatec13/preview/PreviewPopupC13.component";
import PreviewEmbeddedCompact from "../items/templateEmbeddedCompact/preview/PreviewEmbeddedCompact.component";
import PreviewEmbeddedS from "../items/templateEs/preview/PreviewEmbeddedS.component";
import PreviewEmbeddedSTop from "../items/templateEsTop/preview/PreviewEmbeddedSTop.component";
import PreviewPopupFilters48 from "../items/templateFilters48/preview/PreviewPopupFilters48.component";
import PreviewPopupFiltersM48 from "../items/templateFiltersMobile8/preview/PreviewPopupFiltersM48.component";
import PreviewPopupN13 from "../items/templaten13/preview/PreviewPopupN13.component";
import PreviewPopupN36 from "../items/templaten36/preview/PreviewPopupN13.component";
import PreviewNewLook from "../items/templateNewLook/preview/PreviewNewLook.component";
import templateImage from "./../../../../../assets/images/campaign-template3.jpg";
import PreviewDME6 from "../items/templateDME6/preview/PreviewDME6.component";
import PreviewNewLookHorizontal from "../items/templateNLH/preview/PreviewNewLookHorizontal.component";
import PreviewPopupFeature from "../items/templatenFeature/preview/PreviewPopupFeature.component";
import PreviewPopupFeatureMobile from "../items/templatenFeatureMobile/preview/PreviewPopupFeatureMobile.component";
import PreviewPopupSuper from "../items/templateSuper/preview/PreviewPopupSuper.component";

interface Props {
  isSingle: boolean;
  currentPlacement: PlacementSettings;
  campaign: CampaignType;
  list: Template[] | undefined;
  filters: TemplateFilters;
  template: Template;
  selectedTemplate: Template | undefined;
  offersCount: string | undefined;
  setCurrentPlacement: React.Dispatch<
    React.SetStateAction<PlacementSettings | undefined>
  >;
  setTemplate: React.Dispatch<React.SetStateAction<Template | undefined>>;
}

const TemplateList = ({
  currentPlacement,
  list,
  campaign,
  template,
  selectedTemplate,
  filters,
  setTemplate,
  setCurrentPlacement,
  offersCount,
}: Props) => {
  const getPreviewTitle = () => {
    return currentPlacement?.promoMessage &&
      currentPlacement.promoMessage !== " "
      ? currentPlacement.promoMessage
      : campaign.description;
  };

  const getPreviewComponent = useMemo((): JSX.Element => {
    let jsxElm = <></>;
    switch (template?.identifier) {
      // case "templateUlt":
      //     jsxElm = (
      //         <PopupLayout
      //             description={getPreviewTitle()}
      //             campaign={campaign}
      //             settings={
      //                 currentPlacement?.templateSettings[filters.deviceType].type ===
      //                     LayoutClass.action
      //                     ? currentPlacement?.templateSettings[filters.deviceType]?.action
      //                     : currentPlacement?.templateSettings[filters.deviceType]?.popup
      //             }
      //             displayOnly={filters.stage === PlacementStage.pre}
      //             buttonShape={
      //                 currentPlacement?.templateSettings[filters.deviceType]?.button
      //                     .shape
      //             }
      //         />
      //     );
      //     break;
      // Default Slider template with large images from 1 to 3 items
      case "template-dlp1pre":
      case "template-dlp2pre":
      case "template-dlp3pre":
      case "template-dlp1post":
      case "template-dlp2post":
      case "template-dlp3post":
        jsxElm = (
          <PreviewPopupN13
            description={getPreviewTitle()}
            campaign={campaign}
            offersCount={offersCount}
            settings={
              currentPlacement?.templateSettings[filters.deviceType].settings ||
              templateDefaultSettings
            }
            displayOnly={filters.stage === PlacementStage.pre}
            buttonShape={
              currentPlacement?.templateSettings[filters.deviceType]?.button
                .shape
            }
          />
        );
        break;
      // Compact view template with 3 items
      case "template-msp3pre":
      case "template-msp3post":
      case "template-dsp3post":
      case "template-dsp3pre":
        jsxElm = (
          <PreviewPopupC13
            description={getPreviewTitle()}
            campaign={campaign}
            offersCount={offersCount}
            settings={
              currentPlacement?.templateSettings[filters.deviceType].settings ||
              templateDefaultSettings
            }
            displayOnly={filters.stage === PlacementStage.pre}
            buttonShape={
              currentPlacement?.templateSettings[filters.deviceType]?.button
                .shape
            }
          />
        );
        break;
      // Six pack template with load more
      case "template-dlp3+pre":
      case "template-dlp3+post":
        jsxElm = (
          <PreviewPopupN36
            description={getPreviewTitle()}
            campaign={campaign}
            settings={
              currentPlacement?.templateSettings[filters.deviceType].settings ||
              templateDefaultSettings
            }
            displayOnly={filters.stage === PlacementStage.pre}
            buttonShape={
              currentPlacement?.templateSettings[filters.deviceType]?.button
                .shape
            }
            defaultSix={false}
          />
        );
        break;
      // Feature template
      case "template-feature-pre":
      case "template-feature-post":
        jsxElm = (
          <PreviewPopupFeature
            description={getPreviewTitle()}
            campaign={campaign}
            settings={
              currentPlacement?.templateSettings[filters.deviceType].settings ||
              templateDefaultSettings
            }
            displayOnly={filters.stage === PlacementStage.pre}
            buttonShape={
              currentPlacement?.templateSettings[filters.deviceType]?.button
                .shape
            }
          />
        );
        break;

      // Super template
      case "template-super-pre":
      case "template-super-post":
        jsxElm = (
          <PreviewPopupSuper
            description={getPreviewTitle()}
            campaign={campaign}
            settings={
              currentPlacement?.templateSettings[filters.deviceType].settings ||
              templateDefaultSettings
            }
            displayOnly={filters.stage === PlacementStage.pre}
            buttonShape={
              currentPlacement?.templateSettings[filters.deviceType]?.button
                .shape
            }
          />
        );
        break;
      // Feature mobile template
      case "template-feature-mobile-pre":
      case "template-feature-mobile-post":
        jsxElm = (
          <PreviewPopupFeatureMobile
            description={getPreviewTitle()}
            campaign={campaign}
            settings={
              currentPlacement?.templateSettings[filters.deviceType].settings ||
              templateDefaultSettings
            }
            displayOnly={filters.stage === PlacementStage.pre}
            buttonShape={
              currentPlacement?.templateSettings[filters.deviceType]?.button
                .shape
            }
          />
        );
        break;
      case "template-dlp6pre":
      case "template-dlp6post":
        jsxElm = (
          <PreviewPopupN36
            description={getPreviewTitle()}
            campaign={campaign}
            settings={
              currentPlacement?.templateSettings[filters.deviceType].settings ||
              templateDefaultSettings
            }
            displayOnly={filters.stage === PlacementStage.pre}
            buttonShape={
              currentPlacement?.templateSettings[filters.deviceType]?.button
                .shape
            }
            defaultSix={true}
          />
        );
        break;

      case "template-Es":
        jsxElm = (
          <PreviewEmbeddedS
            description={getPreviewTitle()}
            campaign={campaign}
            settings={
              currentPlacement?.templateSettings[filters.deviceType].settings ||
              templateDefaultSettings
            }
            displayOnly={filters.stage === PlacementStage.pre}
            buttonShape={
              currentPlacement?.templateSettings[filters.deviceType]?.button
                .shape
            }
          />
        );

        break;
      case "template-EsTop":
        jsxElm = (
          <PreviewEmbeddedSTop
            description={getPreviewTitle()}
            campaign={campaign}
            settings={
              currentPlacement?.templateSettings[filters.deviceType].settings ||
              templateDefaultSettings
            }
            displayOnly={filters.stage === PlacementStage.pre}
            buttonShape={
              currentPlacement?.templateSettings[filters.deviceType]?.button
                .shape
            }
            device={filters.deviceType}
          />
        );

        break;
      case "template-Ec1":
      case "template-Ec2":
      case "template-Ec3":
        jsxElm = (
          <PreviewEmbeddedCompact
            description={getPreviewTitle()}
            campaign={campaign}
            settings={
              currentPlacement?.templateSettings[filters.deviceType].settings ||
              templateDefaultSettings
            }
            displayOnly={filters.stage === PlacementStage.pre}
            buttonShape={
              currentPlacement?.templateSettings[filters.deviceType]?.button
                .shape
            }
            offersCount={offersCount || "3"}
          />
        );

        break;
      case "template-NL":
        jsxElm = (
          <PreviewNewLook
            description={getPreviewTitle()}
            campaign={campaign}
            settings={
              currentPlacement?.templateSettings[filters.deviceType].settings ||
              templateDefaultSettings
            }
            displayOnly={filters.stage === PlacementStage.pre}
            buttonShape={
              currentPlacement?.templateSettings[filters.deviceType]?.button
                .shape
            }
            offersCount={offersCount || "3"}
          />
        );

        break;

      case "template-dmp8pre":
      case "template-dmp8post":
        jsxElm = (
          <PreviewPopupFilters48
            description={getPreviewTitle()}
            campaign={campaign}
            settings={
              currentPlacement?.templateSettings[filters.deviceType].settings ||
              templateDefaultSettings
            }
            displayOnly={filters.stage === PlacementStage.pre}
            buttonShape={
              currentPlacement?.templateSettings[filters.deviceType]?.button
                .shape
            }
          />
        );
        break;

      case "template-msp8pre":
      case "template-msp8post":
        jsxElm = (
          <PreviewPopupFiltersM48
            description={getPreviewTitle()}
            campaign={campaign}
            offersCount={offersCount}
            settings={
              currentPlacement?.templateSettings[filters.deviceType].settings ||
              templateDefaultSettings
            }
            displayOnly={filters.stage === PlacementStage.pre}
            buttonShape={
              currentPlacement?.templateSettings[filters.deviceType]?.button
                .shape
            }
          />
        );
        break;

      case "template-NLH":
        jsxElm = (
          <PreviewNewLookHorizontal
            description={getPreviewTitle()}
            campaign={campaign}
            settings={
              currentPlacement?.templateSettings[filters.deviceType].settings ||
              templateDefaultSettings
            }
            displayOnly={filters.stage === PlacementStage.pre}
            buttonShape={
              currentPlacement?.templateSettings[filters.deviceType]?.button
                .shape
            }
          />
        );
        break;
      case "template-DME6":
        jsxElm = (
          <PreviewDME6
            description={getPreviewTitle()}
            campaign={campaign}
            settings={
              currentPlacement?.templateSettings[filters.deviceType].settings ||
              templateDefaultSettings
            }
            displayOnly={filters.stage === PlacementStage.pre}
            buttonShape={
              currentPlacement?.templateSettings[filters.deviceType]?.button
                .shape
            }
            device={filters.deviceType}
          />
        );
        break;

      default:
        jsxElm = (
          <>
            <div className="select-template">
              {" "}
              <Empty
                image={Empty.PRESENTED_IMAGE_SIMPLE}
                description="Select a template"
              />{" "}
            </div>
          </>
        );
        break;
    }
    return jsxElm;
    // eslint-disable-next-line
  }, [template, selectedTemplate, filters, list]);

  const handleTemplateChange = (_template: Template) => {
    const _type = currentPlacement.templateSettings[filters.deviceType].type;
    const _currentSettings = currentPlacement.templateSettings[
      filters.deviceType
    ]?.settings
      ? currentPlacement.templateSettings[filters.deviceType]?.settings
      : templateDefaultSettings;
    if (currentPlacement.templateSettings[filters.deviceType]?.settings) {
      setCurrentPlacement({
        ...currentPlacement,
        templateSettings: {
          ...currentPlacement.templateSettings,
          [filters.deviceType]: {
            ...currentPlacement.templateSettings[filters.deviceType],
            type:
              _template.layoutType === LayoutType.embedded
                ? LayoutClass.inline
                : _type === LayoutClass.inline
                ? LayoutClass.popup
                : _type,
            settings: {
              ..._currentSettings,
              mainLayout: {
                ..._currentSettings?.mainLayout,
                headerFontSize: compactTemplates.includes(
                  _template?.identifier || ""
                )
                  ? "16"
                  : "18",
                subHeaderFontSize: compactTemplates.includes(
                  _template?.identifier || ""
                )
                  ? "12"
                  : "16",
              },
            },
          },
        },
      });
    }

    setTemplate(_template);
  };

  return (
    <>
      <div className="widget-layout widget-layout-template">
        <div className="interaction-pages">
          <div className="interaction-section">
            <ul className="layout-template-list">
              {list?.map((_template: Template) => {
                return (
                  <li
                    className={`${
                      template?.identifier === _template.identifier
                        ? "active-noselect"
                        : ""
                    } ${
                      selectedTemplate?.identifier === _template.identifier
                        ? "active-selected"
                        : ""
                    } template-list-item`}
                    key={`template-list-item-${_template.identifier}`}
                    onClick={() => handleTemplateChange(_template)}
                  >
                    <span>{_template.name}</span>
                    <img
                      src={
                        _template.imageURL ? _template.imageURL : templateImage
                      }
                      alt={_template.name}
                      title={_template.name}
                    />
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
        <div id="interaction-view" className="interaction-view">
          <div
            className={`view-content ${
              filters.deviceType === "desktop" ? "desktop" : "mobile"
            }`}
          >
            <div className="template-layout template-blur">
              {template.layoutType !== LayoutType.embedded && (
                <PreviewTemplate
                  isInline={false}
                  layoutView={filters.deviceType}
                />
              )}
            </div>

            {getPreviewComponent}
          </div>
        </div>
      </div>
    </>
  );
};

export default TemplateList;
