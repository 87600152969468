import { Empty, message } from "antd";
import { useState, useEffect } from "react";
import useAxios from "../../../../../hooks/useAxios.hook";
import { SalesFunnelDataItem } from "../../../../../types/graphs.interfaces";
import { PlacementStageInsight } from "../../../../../types/templates.interfaces";
import Click from "./../../../../../assets/images/icons/click.svg";
import Eye from "./../../../../../assets/images/icons/eye.svg";
import Cart from "./../../../../../assets/images/icons/cart.svg";
import { LoadingOutlined } from "@ant-design/icons";
import { formatFixedNumber } from "../../../../../utility/numbers/numbers";

interface Props {
  companyId?: string;
  campaignId?: string;
  offerId?: string;
  filters?: {
    dateFilterType?: string;
    fromDate?: string;
    endDate?: string;
    placementIds?: string[];
  };
  placementType?: PlacementStageInsight;
  salesNumber?: number;
  CTR?: number;
  CR?: number;
  filterTrigger?: number;
}

const SalesFunnelData = ({
  companyId,
  campaignId,
  offerId,
  filters,
  placementType,
  salesNumber,
  CTR,
  CR,
  filterTrigger,
}: Props) => {
  const { myAxios } = useAxios();
  const [loading, setLoading] = useState<boolean>(false);
  const [salesFunnelData, setSalesFunnelData] = useState<SalesFunnelDataItem>();

  useEffect(() => {
    if (!salesNumber) {
      return;
    }
    if (!campaignId) {
      fetchSalesFunnelDataAdvanced();
      return;
    }
    fetchSalesFunnelData();
    // eslint-disable-next-line
  }, [filterTrigger]);

  const fetchSalesFunnelData = async () => {
    setLoading(true);

    const { response } = await myAxios({
      method: "post",
      url: `/insights/offer/funnel/${companyId}/${campaignId}/${offerId}`,
      data: filters,
    });

    if (response?.data?.status) {
      setSalesFunnelData(response.data.result.data?.[0]);
    } else {
      message.error("Failed to get partners offers", 1);
    }
    setLoading(false);
  };

  const fetchSalesFunnelDataAdvanced = async () => {
    setLoading(true);

    const { response } = await myAxios({
      method: "post",
      url: `/insights/sales/funnel/${companyId}`,
      data: {
        ...filters,
        offerId: offerId || "",
      },
    });

    if (response?.data?.status) {
      setSalesFunnelData(response.data.result.data?.[0]);
    } else {
      message.error("Failed to get partners offers", 1);
    }
    setLoading(false);
  };

  const checkForZero = (param1: number, param2?: number): boolean => {
    if (!param2) {
      return param1 > 0;
    }
    return param1 > 0 && param2 > 0;
  };

  const calculateCTR = (): string => {
    if (salesFunnelData) {
      return `${
        checkForZero(
          salesFunnelData.distinctViews,
          salesFunnelData.totalDistinctClicks
        )
          ? (
              (salesFunnelData.totalDistinctClicks /
                salesFunnelData.distinctViews) *
              100
            ).toFixed(2)
          : "0.00"
      }%`;
    }
    return "0.00%";
  };

  const calculateCR = (): string => {
    if (salesFunnelData) {
      return `${
        checkForZero(
          salesFunnelData.totalDistinctClicks,
          salesFunnelData.totalSalesCount
        )
          ? (
              (salesFunnelData.totalSalesCount /
                salesFunnelData.totalDistinctClicks) *
              100
            ).toFixed(2)
          : "0.00"
      }%`;
    }
    return "0.00%";
  };

  return (
    <>
      {campaignId && offerId && <h1 className="title">Sales metrics</h1>}
      {!salesNumber ? (
        <div className="no-orders">
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No data" />
        </div>
      ) : (
        <div className="three-boxes mtop10">
          <div
            className={`three-boxes-item ${
              !campaignId && !offerId ? "three-boxes-item--white" : ""
            }`}
          >
            <div className="three-boxes-item-header">
              <img src={Eye} alt="" className="three-boxes-item-header-image" />
              <div className="three-boxes-item-header-title">Impressions</div>
            </div>
            <div className="three-boxes-item-content">
              {loading ? (
                <LoadingOutlined />
              ) : (
                <div className="three-boxes-item-content-number">
                  {formatFixedNumber(
                    campaignId && offerId
                      ? salesFunnelData?.distinctViews
                        ? salesFunnelData?.distinctViews
                        : 0
                      : salesFunnelData?.distinctOpen
                      ? salesFunnelData?.distinctOpen
                      : 0
                  )}
                </div>
              )}
            </div>
          </div>
          <div
            className={`three-boxes-item ${
              !campaignId && !offerId ? "three-boxes-item--white" : ""
            }`}
          >
            <div className="three-boxes-item-header">
              <img
                src={Click}
                alt=""
                className="three-boxes-item-header-image"
              />
              <div className="three-boxes-item-header-title">Clicks</div>
            </div>

            <div className="three-boxes-item-content">
              {loading ? (
                <LoadingOutlined />
              ) : (
                <>
                  <div className="three-boxes-item-content-number">
                    {formatFixedNumber(
                      placementType === PlacementStageInsight.pre
                        ? salesFunnelData?.distinctSelected
                          ? salesFunnelData?.distinctSelected
                          : 0
                        : salesFunnelData?.totalDistinctClicks
                        ? salesFunnelData?.totalDistinctClicks
                        : 0
                    )}
                  </div>
                  <div className="three-boxes-item-content-percentage">
                    {CTR && CTR > 0 ? CTR : calculateCTR()}
                  </div>
                </>
              )}
            </div>
          </div>
          <div
            className={`three-boxes-item ${
              !campaignId && !offerId ? "three-boxes-item--white" : ""
            }`}
          >
            <div className="three-boxes-item-header">
              <img
                src={Cart}
                alt=""
                className="three-boxes-item-header-image"
              />
              <div className="three-boxes-item-header-title">Orders</div>
            </div>
            <div className="three-boxes-item-content">
              {loading ? (
                <LoadingOutlined />
              ) : (
                <>
                  <div className="three-boxes-item-content-number">
                    {formatFixedNumber(
                      salesFunnelData?.totalSalesCount
                        ? salesFunnelData?.totalSalesCount
                        : 0
                    )}
                  </div>
                  <div className="three-boxes-item-content-percentage">
                    {CR && CR > 0 ? CR : calculateCR()}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SalesFunnelData;
