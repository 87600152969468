export interface Brand {
  brand: string;
  brandid: string;
  currencySymbol: string;
  oname: string;
  oid: string;
}

export interface SalesFunnelDataItem {
  shareClicks: string;
  brand: Brand;
  cpa: number;
  cpastr: string;
  ctr: number;
  cr: number;
  epc: number;
  ecpm: number;
  totalAutoEmailApprovedAmount: number;
  totalAutoEmailPendingAmount: number;
  transactionsAutoEmailPendingCount: number;
  totalAutoEmailSalesCommission: number;
  totalAutoEmailSalesCount: number;
  transactionsAutoEmailApprovedCount: number;
  totalViews: number;
  totalWebClicks: number;
  totalReadMore: number;
  totalEmailClicks: number;
  distinctReadMore: number;
  totalShareClicks: number;
  totalShareButtonClicks: number;
  distinctShareClicks: number;
  distinctShareButtonClicks: number;
  totalSalesCount: number;
  totalCommission: number;
  transactionsPendingCount: number;
  transactionsApprovedCount: number;
  totalAmountPending: number;
  totalAmountApproved: number;
  totalDistinctClicks: number;
  distinctSelected: number;
  totalEmailPostAuto: number;
  totalEmailPostManual: number;
  totalEmailSends: number;
  distinctViews: number;
  totalSelected: number;
  distinctEmailClicks: number;
  distinctWebClicks: number;
  distinctEmailPostAuto: number;
  distinctEmailSends: number;
  distinctEmailPostManual: number;
  totalDistinctAutoEmailSends: number;
  distinctOpen: number;
}
export interface SalesFunnelData {
  data: SalesFunnelDataItem[];
  fromDate: string;
  toDate: string;
  fromDateStr: string;
  toDateStr: string;
  count: number | null;
  totalPages: number;
}

export interface HeatMapDataItem {
  day: number;
  hour: number;
  count: number;
}

export const calculateCount = (
  data: HeatMapDataItem[],
  day: number,
  hour: number
): number => {
  return data
    .filter((entry) => entry.day === day && entry.hour === hour)
    .reduce((total, entry) => total + entry.count, 0);
};

export const updateHeatMapData = (
  initHeatMapData: any[],
  updates: { day: number; hour: number; count: number }[]
): any[] => {
  let heatMapData = initHeatMapData;
  updates.forEach((update) => {
    const { day, hour, count } = update;
    // Find the index of the matching day and hour in the heatMapData array
    const index = heatMapData.findIndex(
      (data) => data[0] === day - 1 && data[1] === hour
    );
    if (index !== -1) {
      // Update the count
      heatMapData[index][2] = count;
    }
  });
  return heatMapData;
};

export enum DeviceTypeForChart {
  MOBILE = "Mobile",
  DESKTOP = "Desktop",
  TABLET = "Tablet",
}

export interface PerformanceByDeviceType {
  deviceType: DeviceTypeForChart;
  count: number;
}

export interface DataByDataLayerKey {
  key: string | null;
  count: number;
}
