import { SearchOutlined } from "@ant-design/icons";
import {
  Alert,
  Button,
  Checkbox,
  Dropdown,
  Input,
  message,
  Typography,
} from "antd/lib";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import React, { MouseEvent, useEffect, useRef, useState } from "react";
import { CompanyCategory } from "../../../../types/company.interfaces";
import useAxios from "../../../../hooks/useAxios.hook";
import {
  // Advertiser,
  ExpressCategoryItem,
  // ExpressCompanyItem,
} from "../../types/express.types";
import {
  EXPRESS_ONBOARDING,
  registered_COUNTRY,
} from "../../types/express.enum";
import useExpress from "../../../../hooks/useExpress.hook";
import { Country, Timezone } from "../../../../types/global.interfaces";
interface Props {
  setCurrentStep: React.Dispatch<React.SetStateAction<EXPRESS_ONBOARDING>>;
}

const ExpressDetails = ({ setCurrentStep }: Props) => {
  const { campaign, company, setCompany, setOnboardingStatus } = useExpress();

  const [loading, setLoading] = useState<boolean>(false);

  const { myAxios } = useAxios();

  // Categories
  const [categories, setCategories] = useState<CompanyCategory[]>();
  const [filteredCategories, setFilteredCategories] = useState<
    CompanyCategory[]
  >([]);
  const [searchCategories, setSearchCategories] = useState<string>("");
  const [selectedCategories, setSelectedCategories] = useState<
    ExpressCategoryItem[]
  >(company?.categories || []);
  const categoriesRef = useRef<HTMLInputElement>(null);

  // Categories not to work with
  const [notCategories, setNotCategories] = useState<CompanyCategory[]>();
  const [filteredNotCategories, setFilteredNotCategories] = useState<
    CompanyCategory[]
  >([]);
  const [searchNotCategories, setSearchNotCategories] = useState<string>("");
  const [selectedNotCategories, setSelectedNotCategories] = useState<
    ExpressCategoryItem[]
  >(company?.excludedCategories || []);
  const categoriesNotRef = useRef<HTMLInputElement>(null);

  // Advertisers
  // const [advertisers, setAdvertisers] = useState<ExpressCompanyItem[]>();
  // const [filteredAdvertisers, setFilteredAdvertisers] = useState<
  //   ExpressCompanyItem[]
  // >([]);
  // const [searchAdvertisers, setSearchAdvertisers] = useState<string>("");
  // const [selectedAdvertisers, setSelectedAdvertisers] = useState<
  //   ExpressCompanyItem[]
  // >(company?.exclusiveAdvertisers || []);
  // const advertisersRef = useRef<HTMLInputElement>(null);

  // Country
  const [filteredregistered, setFilteredRegistered] = useState<Country[]>([]);
  const [searchRegistered, setSearchRegistered] = useState<string>("");
  const [selectedRegisteredCountry, setSelectedRegisteredCountry] = useState<
    Country | undefined
  >(
    company?.registrationCountry || {
      id: "64255ba1f404f74a0bd1fb54",
      isoCode: "US",
      name: "United States",
      currencySymbol: "$",
    }
  );
  const registeredRef = useRef<HTMLInputElement>(null);

  //timezones
  const [timezones, setTimezones] = useState<Timezone[]>();
  const [filteredTimezones, setFilteredTimezones] = useState<Timezone[]>([]);
  const [searchTimezones, setSearchTimezones] = useState<string>("");
  const [selectedTimezone, setSelectedTimezone] = useState<Timezone>();
  const timezoneRef = useRef<HTMLInputElement>(null);

  const [privacyPolicy, setPrivacyPolicy] = useState<boolean>(false);
  const [termsConditions, setTermsConditions] = useState<boolean>(false);

  useEffect(() => {
    if (categories) {
      if (searchCategories) {
        const newCategories: CompanyCategory[] = structuredClone(categories);
        setFilteredCategories(
          newCategories.filter((f) =>
            f.name.toLowerCase().includes(searchCategories.toLowerCase())
          ) || []
        );
      } else {
        setFilteredCategories(categories);
      }
    }
    // eslint-disable-next-line
  }, [searchCategories]);

  useEffect(() => {
    if (notCategories) {
      if (searchNotCategories) {
        const newCategories: CompanyCategory[] = structuredClone(notCategories);
        setFilteredNotCategories(
          newCategories.filter((f) =>
            f.name.toLowerCase().includes(searchNotCategories.toLowerCase())
          ) || []
        );
      } else {
        setFilteredNotCategories(notCategories);
      }
    }
    // eslint-disable-next-line
  }, [searchNotCategories]);

  // useEffect(() => {
  //   if (advertisers) {
  //     if (searchAdvertisers) {
  //       const newAdvertisers: ExpressCompanyItem[] =
  //         structuredClone(advertisers);
  //       setFilteredAdvertisers(
  //         newAdvertisers.filter((f) =>
  //           f.name.toLowerCase().includes(searchAdvertisers.toLowerCase())
  //         ) || []
  //       );
  //     } else {
  //       setFilteredAdvertisers(advertisers);
  //     }
  //   }
  //   // eslint-disable-next-line
  // }, [searchAdvertisers]);

  useEffect(() => {
    if (timezones) {
      if (searchTimezones) {
        const newTimezones: Timezone[] = structuredClone(timezones);
        setFilteredTimezones(
          newTimezones.filter((f) =>
            f.displayName.toLowerCase().includes(searchTimezones.toLowerCase())
          ) || []
        );
      } else {
        setFilteredTimezones(timezones);
      }
    }
    // eslint-disable-next-line
  }, [searchTimezones]);

  useEffect(() => {
    setCompany(
      (prevState) =>
        prevState && {
          ...prevState,
          categories: selectedCategories ? selectedCategories : [],
          excludedCategories: selectedNotCategories
            ? selectedNotCategories
            : [],
          // exclusiveAdvertisers: selectedAdvertisers ? selectedAdvertisers : [],
          registrationCountry: selectedRegisteredCountry
            ? selectedRegisteredCountry
            : registered_COUNTRY,
          timezoneId: selectedTimezone?.id || selectedTimezone?.id || "",
        }
    );
    // eslint-disable-next-line
  }, [
    selectedCategories,
    selectedNotCategories,
    // selectedAdvertisers,
    selectedRegisteredCountry,
    selectedTimezone,
  ]);

  useEffect(() => {
    getCompanyCategories();
    // getAdvertisers();
    getCountries();
    document.title = "BrandSwap Express - Onboarding details";
    setOnboardingStatus(
      (prevState) =>
        prevState && { completed: false, next: EXPRESS_ONBOARDING.details }
    );
    // eslint-disable-next-line
  }, []);

  const handleClearCategories = (event: MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    setSelectedCategories([]);
    categoriesRef?.current?.focus();
  };

  const handleChangeCategory = (
    checked: boolean,
    category: CompanyCategory
  ) => {
    let _selectedCategories: ExpressCategoryItem[] = [];

    if (checked) {
      _selectedCategories = [
        ...selectedCategories,
        { categoryId: category.id, name: category.name },
      ];
    } else {
      selectedCategories?.forEach((value: ExpressCategoryItem) => {
        if (value.categoryId !== category.id) {
          _selectedCategories.push(value);
        }
      });
    }

    setSelectedCategories(_selectedCategories);
  };

  const handleRemoveCategory = (categoryId: string) => {
    const _selectedCategories: ExpressCategoryItem[] = structuredClone(
      selectedCategories
    ).filter((f: ExpressCategoryItem) => f.categoryId !== categoryId);
    setSelectedCategories(_selectedCategories);
  };

  const getOfferCategoriesName = (
    cat?: ExpressCategoryItem[] | undefined
  ): string => {
    const catName: string[] = cat
      ? cat.map((value: ExpressCategoryItem) => value.name)
      : [];
    return catName.join(", ");
  };

  const handleCheckedCategories = (id: string): boolean => {
    const isChecked: boolean = !!selectedCategories?.find(
      (value: ExpressCategoryItem) => value.categoryId === id
    );
    return isChecked;
  };

  // Not Categories
  const handleClearNotCategories = (event: MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    setSelectedNotCategories([]);
    categoriesNotRef?.current?.focus();
  };

  const handleChangeNotCategory = (
    checked: boolean,
    category: CompanyCategory
  ) => {
    let _selectedCategories: ExpressCategoryItem[] = [];

    if (checked) {
      _selectedCategories = [
        ...selectedNotCategories,
        { categoryId: category.id, name: category.name },
      ];
    } else {
      selectedNotCategories?.forEach((value: ExpressCategoryItem) => {
        if (value.categoryId !== category.id) {
          _selectedCategories.push(value);
        }
      });
    }

    setSelectedNotCategories(_selectedCategories);
  };

  const handleRemoveNotCategory = (categoryId: string) => {
    const _selectedCategories: ExpressCategoryItem[] = structuredClone(
      selectedNotCategories
    ).filter((f: ExpressCategoryItem) => f.categoryId !== categoryId);
    setSelectedNotCategories(_selectedCategories);
  };

  const getOfferNotCategoriesName = (
    cat?: ExpressCategoryItem[] | undefined
  ): string => {
    const catName: string[] = cat
      ? cat.map((value: ExpressCategoryItem) => value.name)
      : [];
    return catName.join(", ");
  };

  const handleCheckedNotCategories = (id: string): boolean => {
    const isChecked: boolean = !!selectedNotCategories?.find(
      (value: ExpressCategoryItem) => value.categoryId === id
    );
    return isChecked;
  };

  const getCompanyCategories = async () => {
    const { response } = await myAxios({
      method: "GET",
      url: "CompanyCategory",
    });

    if (response?.data?.status) {
      setCategories(response?.data?.result);
      setFilteredCategories(response?.data?.result);
      setNotCategories(response?.data?.result);
      setFilteredNotCategories(response?.data?.result);
    } else {
      message.error("Failed to get categories", 1);
    }
  };

  // const getAdvertisers = async () => {
  //   const { response } = await myAxios({
  //     method: "POST",
  //     url: "/company/search?page=1&pageSize=200",
  //     data: {
  //       companyType: "all",
  //       isMaster: "all",
  //       name: "",
  //       networkIdentifiers: ["cj"],
  //     },
  //   });

  //   if (response?.data?.status) {
  //     setAdvertisers(
  //       response?.data?.result?.data.map((item: Advertiser) => ({
  //         companyId: item.id,
  //         name: item.name,
  //       }))
  //     );
  //     setFilteredAdvertisers(
  //       response?.data?.result?.data.map((item: Advertiser) => ({
  //         companyId: item.id,
  //         name: item.name,
  //       }))
  //     );
  //   } else {
  //     message.error("Failed to get categories", 1);
  //   }
  // };

  // Advertisers
  // const handleClearAdvertisers = (event: MouseEvent<HTMLSpanElement>) => {
  //   event.preventDefault();
  //   setSelectedAdvertisers([]);
  //   advertisersRef?.current?.focus();
  // };

  // const handleChangeAdvertiser = (
  //   checked: boolean,
  //   advertiser: ExpressCompanyItem
  // ) => {
  //   let _selectedAdvertisers: ExpressCompanyItem[] = [];

  //   if (checked) {
  //     _selectedAdvertisers = [...selectedAdvertisers, advertiser];
  //   } else {
  //     selectedAdvertisers?.forEach((value: ExpressCompanyItem) => {
  //       if (value.companyId !== advertiser.companyId) {
  //         _selectedAdvertisers.push(value);
  //       }
  //     });
  //   }

  //   setSelectedAdvertisers(_selectedAdvertisers);
  // };

  // const handleRemoveAdvertiser = (advertiserId: string) => {
  //   const _selectedAdvertisers: ExpressCompanyItem[] = structuredClone(
  //     selectedAdvertisers
  //   ).filter((i: ExpressCompanyItem) => i.companyId !== advertiserId);
  //   setSelectedAdvertisers(_selectedAdvertisers);
  // };

  // const getOfferAdvertisersName = (
  //   item?: ExpressCompanyItem[] | undefined
  // ): string => {
  //   const advName: string[] = item
  //     ? item.map((value: ExpressCompanyItem) => value.name)
  //     : [];
  //   return advName.join(", ");
  // };

  // const handleCheckedAdvertisers = (id: string): boolean => {
  //   const isChecked: boolean = !!selectedAdvertisers?.find(
  //     (value: ExpressCompanyItem) => value.companyId === id
  //   );
  //   return isChecked;
  // };

  //timezone
  const handleChangeTimezone = (t: Timezone) => {
    setSelectedTimezone(t);
  };

  const getTimezones = async () => {
    const { response } = await myAxios({
      method: "GET",
      url: `support/timezones/${selectedRegisteredCountry?.isoCode}`,
    });

    if (response?.data?.status) {
      if (company?.timezoneId) {
        if (company?.timezoneId === "640f0f329cb5c4a06ed2f639") {
          setSelectedTimezone(response?.data?.result?.[0]);
        } else {
          const findTimezone = response?.data?.result.find(
            (t: Timezone) => t.id === company.timezoneId
          );
          if (findTimezone) {
            setSelectedTimezone(findTimezone);
          } else {
            setSelectedTimezone(response?.data?.result?.[0]);
          }
        }
      }

      setTimezones(response?.data?.result);
      setFilteredTimezones(response?.data?.result);
    } else {
      message.error("Failed to get timezones", 1);
    }
  };

  const getCountries = async () => {
    const { response } = await myAxios({
      method: "GET",
      url: "support/countries/US,GB",
    });

    if (response?.data?.status) {
      setFilteredRegistered(response?.data?.result);
    } else {
      message.error("Failed to get regions", 1);
    }
  };

  useEffect(() => {
    getTimezones();
    // eslint-disable-next-line
  }, [selectedRegisteredCountry]);

  const postCompanyExpress = async () => {
    setLoading(true);
    const { response } = await myAxios({
      method: "POST",
      url: `/onboarding/express/company/details`,
      data: {
        campaign,
        company,
        onboardingStatus: {
          completed: false,
          next: EXPRESS_ONBOARDING.offers,
        },
      },
    });
    if (response?.data?.status) {
      message.success({
        content: "Your details are saved",
        className: "express-message",
        duration: 1,
      });
      setCompany(response?.data.result.company);
      setCurrentStep(EXPRESS_ONBOARDING.offers);
    } else {
      message.error("Failed to get categories", 1);
    }
    setLoading(false);
  };

  const isValid = (): boolean => {
    return !!selectedCategories.length && privacyPolicy && termsConditions;
  };

  const handleContinue = () => {
    if (isValid()) {
      postCompanyExpress();
    }
  };

  return (
    <>
      <div className="express-onboarding-description">
        <Alert
          description="Please fill the details below to complete your registration"
          type="info"
        />
      </div>

      <div className="widget widget-express express-details">
        <form className="express-form" onSubmit={(e) => e.preventDefault()}>
          <div className="express-row">
            <div className="express-label">
              How would you categorise your business? *
            </div>
            <div className="express-input express-dropdown">
              <div className="label-input-dropdown">
                <Dropdown
                  dropdownRender={(menu) => {
                    return (
                      <div className="dropdown">
                        <div className="dropdown-search">
                          <SearchOutlined className="search-icon" />
                          <input
                            ref={categoriesRef}
                            type="text"
                            placeholder="Search"
                            className="default-input"
                            value={searchCategories}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => setSearchCategories(event.target.value)}
                          />
                        </div>
                        {selectedCategories.length > 0 && (
                          <>
                            <div className="dropdown-title">
                              Selected Categories:
                            </div>
                            <ul className="list-tags">
                              {selectedCategories?.map(
                                (category: ExpressCategoryItem) => {
                                  return (
                                    <li
                                      key={`selected-cat-${category.categoryId}`}
                                    >
                                      {category.name}
                                      <span
                                        className="icon icon-remove mleft10 font-hover"
                                        onClick={() =>
                                          handleRemoveCategory(
                                            category.categoryId
                                          )
                                        }
                                      ></span>
                                    </li>
                                  );
                                }
                              )}
                              <span
                                className="font-xsmall font-hover clear-categories"
                                onClick={handleClearCategories}
                              >
                                Clear all
                              </span>
                            </ul>
                          </>
                        )}
                        <div className="dropdown-title">Categories found:</div>
                        {filteredCategories.length > 0 ? (
                          <>
                            <ul>
                              {filteredCategories?.map(
                                (category: CompanyCategory) => {
                                  return (
                                    <li key={`details-cat-${category.id}`}>
                                      <Checkbox
                                        checked={handleCheckedCategories(
                                          category.id
                                        )}
                                        onChange={(e: CheckboxChangeEvent) =>
                                          handleChangeCategory(
                                            e.target.checked,
                                            category
                                          )
                                        }
                                        className="checkbox-express"
                                      >
                                        {category.name}
                                      </Checkbox>
                                    </li>
                                  );
                                }
                              )}
                            </ul>
                          </>
                        ) : (
                          <>
                            <span className="font-xsmall">No item found</span>
                          </>
                        )}
                      </div>
                    );
                  }}
                  trigger={["click"]}
                >
                  <Typography.Link>
                    <div className="label-input-wrapper label-input-select">
                      <label htmlFor="categories">
                        <Input
                          id="categories"
                          disabled={true}
                          value={getOfferCategoriesName(selectedCategories)}
                          className="express-disabled"
                          placeholder="Select categories"
                        />
                        <span className="express-dropdown-icon"></span>
                      </label>
                    </div>
                  </Typography.Link>
                </Dropdown>
              </div>
            </div>
          </div>
          <div className="express-row">
            <div className="express-label">
              Categories you don't want to work with? (optional)
            </div>
            <div className="express-input express-dropdown">
              <div className="label-input-dropdown">
                <Dropdown
                  dropdownRender={(menu) => {
                    return (
                      <div className="dropdown">
                        <div className="dropdown-search">
                          <SearchOutlined className="search-icon" />
                          <input
                            ref={categoriesNotRef}
                            type="text"
                            placeholder="Search"
                            className="default-input"
                            value={searchNotCategories}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => setSearchNotCategories(event.target.value)}
                          />
                        </div>
                        {selectedNotCategories.length > 0 && (
                          <>
                            <div className="dropdown-title">
                              Selected Categories:
                            </div>
                            <ul className="list-tags">
                              {selectedNotCategories?.map(
                                (category: ExpressCategoryItem) => {
                                  return (
                                    <li
                                      key={`selected-cat-${category.categoryId}`}
                                    >
                                      {category.name}
                                      <span
                                        className="icon icon-remove mleft10 font-hover"
                                        onClick={() =>
                                          handleRemoveNotCategory(
                                            category.categoryId
                                          )
                                        }
                                      ></span>
                                    </li>
                                  );
                                }
                              )}
                              <span
                                className="font-xsmall font-hover clear-categories"
                                onClick={handleClearNotCategories}
                              >
                                Clear all
                              </span>
                            </ul>
                          </>
                        )}
                        <div className="dropdown-title">Categories found:</div>
                        {filteredNotCategories.length > 0 ? (
                          <>
                            <ul>
                              {filteredNotCategories?.map(
                                (category: CompanyCategory) => {
                                  return (
                                    <li key={`details-cat-${category.id}`}>
                                      <Checkbox
                                        checked={handleCheckedNotCategories(
                                          category.id
                                        )}
                                        onChange={(e: CheckboxChangeEvent) =>
                                          handleChangeNotCategory(
                                            e.target.checked,
                                            category
                                          )
                                        }
                                        className="checkbox-express"
                                      >
                                        {category.name}
                                      </Checkbox>
                                    </li>
                                  );
                                }
                              )}
                            </ul>
                          </>
                        ) : (
                          <>
                            <span className="font-xsmall">No item found</span>
                          </>
                        )}
                      </div>
                    );
                  }}
                  trigger={["click"]}
                >
                  <Typography.Link>
                    <div className="label-input-wrapper label-input-select">
                      <label htmlFor="categories">
                        <Input
                          id="categories"
                          disabled={true}
                          value={getOfferNotCategoriesName(
                            selectedNotCategories
                          )}
                          className="express-disabled"
                          placeholder="Select categories"
                        />
                        <span className="express-dropdown-icon"></span>
                      </label>
                    </div>
                  </Typography.Link>
                </Dropdown>
              </div>
            </div>
          </div>
          {/* <div className="express-row">
            <div className="express-label">
              Advertisers you would like to work with (optional)
            </div>
            <div className="express-input express-dropdown">
              <div className="label-input-dropdown">
                <Dropdown
                  dropdownRender={(menu) => {
                    return (
                      <div className="dropdown">
                        <div className="dropdown-search">
                          <SearchOutlined className="search-icon" />
                          <input
                            ref={advertisersRef}
                            type="text"
                            className="default-input"
                            value={searchAdvertisers}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => setSearchAdvertisers(event.target.value)}
                          />
                          <span
                            className="close-icon"
                            onClick={handleClearAdvertisers}
                          >
                            <span className="icon icon-remove"></span>
                          </span>
                        </div>

                        {selectedAdvertisers.length > 0 && (
                          <>
                            <div className="dropdown-title">
                              Selected Advertisers:
                            </div>
                            <ul className="list-tags">
                              {selectedAdvertisers?.map(
                                (item: ExpressCompanyItem) => {
                                  return (
                                    <li key={`selected-adv-${item.companyId}`}>
                                      {item.name}
                                      <span
                                        className="icon icon-remove mleft10 font-hover"
                                        onClick={() =>
                                          handleRemoveAdvertiser(item.companyId)
                                        }
                                      ></span>
                                    </li>
                                  );
                                }
                              )}
                            </ul>
                          </>
                        )}
                        <div className="dropdown-title">Advertisers found:</div>

                        {filteredAdvertisers.length > 0 ? (
                          <>
                            <ul>
                              {filteredAdvertisers?.map(
                                (item: ExpressCompanyItem) => {
                                  return (
                                    <li key={`details-adv-${item.companyId}`}>
                                      <Checkbox
                                        checked={handleCheckedAdvertisers(
                                          item.companyId
                                        )}
                                        onChange={(e: CheckboxChangeEvent) =>
                                          handleChangeAdvertiser(
                                            e.target.checked,
                                            item
                                          )
                                        }
                                        className="checkbox-active"
                                      >
                                        {item.name}
                                      </Checkbox>
                                    </li>
                                  );
                                }
                              )}
                            </ul>
                          </>
                        ) : (
                          <>
                            <span className="font-xsmall">No item found</span>
                          </>
                        )}
                      </div>
                    );
                  }}
                  trigger={["click"]}
                >
                  <Typography.Link>
                    <div className="label-input-wrapper label-input-select">
                      <label htmlFor="categories">
                        <Input
                          disabled={true}
                          value={getOfferAdvertisersName(selectedAdvertisers)}
                          className="express-disabled"
                          placeholder="Advertisers you would like"
                        />
                        <span className="express-dropdown-icon"></span>
                      </label>
                    </div>
                  </Typography.Link>
                </Dropdown>
              </div>
            </div>
          </div> */}

          <div className="express-row">
            <div className="express-label">Registered country</div>
            <div className="express-input express-dropdown">
              <div className="label-input-dropdown">
                <Dropdown
                  dropdownRender={(menu) => {
                    return (
                      <div className="dropdown">
                        <div className="dropdown-search">
                          <SearchOutlined className="search-icon" />
                          <input
                            ref={registeredRef}
                            type="text"
                            placeholder="Search"
                            className="default-input"
                            value={searchRegistered}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => setSearchRegistered(event.target.value)}
                          />
                        </div>

                        <div className="dropdown-title">Countries found:</div>

                        {filteredregistered.length > 0 ? (
                          <>
                            <ul>
                              {filteredregistered?.map((country: Country) => {
                                return (
                                  <li key={`details-cat-${country.id}`}>
                                    <span
                                      onClick={() => {
                                        setSelectedRegisteredCountry(country);
                                      }}
                                      className={`font-hover ${
                                        country.id ===
                                        selectedRegisteredCountry?.id
                                          ? "font-active"
                                          : ""
                                      }`}
                                    >
                                      {country.name}
                                    </span>
                                  </li>
                                );
                              })}
                            </ul>
                          </>
                        ) : (
                          <>
                            <span className="font-xsmall">No item found</span>
                          </>
                        )}
                      </div>
                    );
                  }}
                  trigger={["click"]}
                >
                  <Typography.Link>
                    <div className="label-input-wrapper label-input-select">
                      <label htmlFor="categories">
                        <Input
                          disabled={true}
                          value={selectedRegisteredCountry?.name || ""}
                          className="express-disabled"
                          placeholder="Select company country"
                        />
                        <span className="express-dropdown-icon"></span>
                      </label>
                    </div>
                  </Typography.Link>
                </Dropdown>
              </div>
            </div>
          </div>

          <div className="express-row">
            <div className="express-label">Timezone</div>
            <div className="express-input express-dropdown">
              <div className="label-input-dropdown">
                <Dropdown
                  dropdownRender={(menu) => {
                    return (
                      <div className="dropdown">
                        <div className="dropdown-search">
                          <SearchOutlined className="search-icon" />
                          <input
                            ref={timezoneRef}
                            type="text"
                            placeholder="Search"
                            className="default-input"
                            value={searchTimezones}
                            onChange={(
                              event: React.ChangeEvent<HTMLInputElement>
                            ) => setSearchTimezones(event.target.value)}
                          />
                        </div>
                        <div className="dropdown-title">Timezones found:</div>
                        {selectedTimezone && (
                          <>
                            {filteredTimezones.length > 0 ? (
                              <>
                                <ul>
                                  {filteredTimezones?.map((t: Timezone) => {
                                    return (
                                      <li key={`details-cat-${t.id}`}>
                                        <span
                                          onClick={() => {
                                            handleChangeTimezone(t);
                                          }}
                                          className={`font-hover ${
                                            t.id === selectedTimezone?.id
                                              ? "font-active"
                                              : ""
                                          }`}
                                        >
                                          {t.displayName}
                                        </span>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </>
                            ) : (
                              <>
                                <span className="font-xsmall">
                                  No item found
                                </span>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    );
                  }}
                  trigger={["click"]}
                >
                  <Typography.Link>
                    <div className="label-input-wrapper label-input-select">
                      <label htmlFor="categories">
                        <Input
                          disabled={true}
                          value={selectedTimezone?.displayName}
                          className="express-disabled"
                          placeholder="Select timezone"
                        />
                        <span className="express-dropdown-icon"></span>
                      </label>
                    </div>
                  </Typography.Link>
                </Dropdown>
              </div>
            </div>
          </div>

          <Checkbox
            className="express-checkbox"
            onChange={() => setPrivacyPolicy((prev) => !prev)}
          >
            I agree to the BrandSwap{" "}
            <a
              href="https://brandswap.com/privacy/"
              rel="noopener noreferrer"
              target="_blank"
              className="express-link"
            >
              {" "}
              privacy policy
            </a>
          </Checkbox>
          <Checkbox
            className="express-checkbox"
            onChange={() => setTermsConditions((prev) => !prev)}
          >
            I agree to the BrandSwap{" "}
            <a
              href="https://brandswap.com/terms-and-conditions-of-brandswap-limited/"
              rel="noopener noreferrer"
              target="_blank"
              className="express-link"
            >
              {" "}
              terms and conditions
            </a>
          </Checkbox>
        </form>
      </div>

      <div className="express-button">
        <Button
          className="express-button-success"
          loading={loading}
          disabled={!isValid()}
          type="default"
          onClick={handleContinue}
        >
          Continue
        </Button>
      </div>
    </>
  );
};

export default ExpressDetails;
