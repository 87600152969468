import { Radio, Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import {
  otherSettingsDefault,
  OtherSettingsInterface,
} from "../../../../../../types/campaign.interfaces";

interface Props {
  otherSettings: OtherSettingsInterface | undefined;
  updateOtherSettings: (value: OtherSettingsInterface) => void;
}

const OtherSettings = ({ otherSettings, updateOtherSettings }: Props) => {
  const setNewSettings = (
    key: keyof OtherSettingsInterface,
    value: string | number | boolean
  ) => {
    const _otherSettings = otherSettings ? otherSettings : otherSettingsDefault;
    const newSettings: OtherSettingsInterface = {
      ..._otherSettings,
      [key]: value,
    };

    updateOtherSettings(newSettings);
  };

  const handleSelectAllChange = (value: boolean) => {
    setNewSettings("showSelectAll", value);
  };
  const handleSelectTypeChange = (value: boolean) => {
    setNewSettings("showCheckboxSelect", value);
  };

  return (
    <>
      <Radio.Group
        className="mbot20 "
        onChange={(e) => {
          handleSelectTypeChange(e.target.value);
        }}
        value={otherSettings?.showCheckboxSelect || false}
      >
        <div className="option-col">
          <Radio value={true} className="settings-text">
            "Checkbox" selector
          </Radio>
        </div>
        <div className="option-col">
          <Radio value={false} className="settings-text">
            "Switch" selector
          </Radio>
        </div>
      </Radio.Group>
      <div className="option-col mbot20">
        <Checkbox
          checked={otherSettings?.showSelectAll}
          onChange={(e: CheckboxChangeEvent) =>
            handleSelectAllChange(e.target.checked)
          }
        >
          <span className="settings-text">Include "Select all" option</span>
        </Checkbox>
      </div>
    </>
  );
};

export default OtherSettings;
