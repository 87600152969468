import {
  CampaignType,
  LayoutSettings,
} from "../../../types/campaign.interfaces";
import { DATALAYER_TYPE } from "../../express/const/campaigns.enum";
import {
  ActionSettings,
  BehaviourSettings,
  ButtonLocationSettingsT,
  ButtonSettings,
  ButtonShapes,
  ButtonSizesSettings,
  ButtonStyleSettingsT,
  ElementSettings,
  GeneralSettings,
  ImageSettings,
  ImageSizes,
  InlineSettings,
  LocationPosition,
  LocationSettings,
  PopupSettings,
  GetButtonSettings,
  TextAlign,
  TextSettings,
  TextTransform,
  DisplayBy,
  ButtonNumberSettingsT,
  ImageRepresentationSettings,
  ImageRepresentation,
} from "../../templates-previews/types/params.interfaces";
import { LayoutClass } from "./views";

export enum CAMPAIGNSTEPS {
  details = "details",
  offers = "offers",
  layout = "layout",
}

export const CAMPAIGN_STEP_ARRAY: CAMPAIGNSTEPS[] = [
  CAMPAIGNSTEPS.details,
  CAMPAIGNSTEPS.offers,
  CAMPAIGNSTEPS.layout,
];

export const getCampaignStepIndex = (step: CAMPAIGNSTEPS): number => {
  return CAMPAIGN_STEP_ARRAY.findIndex((s) => s === step);
};

const orderExtractorSettings = {
  allowExtraction: false,
  productExtractorKeys: {
    htmlElement: "",
    productsSelector: "",
    productNameSelector: "",
    productPriceSelector: "",
    totalPriceSelector: "",
  },
};

const textSettings: TextSettings = {
  textColor: "#000000",
  fontFamily: "Poppins",
  fontWeight: 0,
  fontSize: "14",
  textTransform: TextTransform.capitalize,
};

const buttonStyleSettings: ButtonStyleSettingsT = {
  backgroundColor: "#ffffff",
  borderTopLeftRadius: 12,
  borderTopRightRadius: 12,
  borderBottomLeftRadius: 12,
  borderBottomRightRadius: 12,
  circleSize: 100,
  ...textSettings,
};

const imageRepresentation: ImageRepresentationSettings = {
  position: ImageRepresentation.left,
};

const imageSettings: ImageSettings = {
  size: ImageSizes.medium,
  rounding: 5,
};

const imageMobileSettings: ImageSettings = {
  size: ImageSizes.xsmall,
  rounding: 5,
};

const generalSettings: GeneralSettings = {
  backgroundColor: "#f2f2f2",
  offers: "3",
  align: TextAlign.left,
};

const buttonNumberSettings: ButtonNumberSettingsT = {
  display: false,
  backgroundColor: "#ff0000",
  textColor: "#ffffff",
};

const getButtonSettings: GetButtonSettings = {
  backgroundColor: "#d1d1d1",
  textColor: "#000000",
  fontFamily: "Poppins",
  fontSize: "14",
  fontWeight: 0,
  rounding: 12,
};

const behaviourSettings: BehaviourSettings = {
  displayBy: DisplayBy.time,
  afterTime: 0,
};

const locationSettings: LocationSettings = {
  location: LocationPosition.left,
  transition: 0,
};

const buttonLocationSettings: ButtonLocationSettingsT = {
  location: LocationPosition.left,
  x: 0,
  y: 0,
};

const buttonSizesSettings: ButtonSizesSettings = {
  type: "fixed",
  w: 150,
  h: 40,
  circle: 100,
};

const elementSettings: ElementSettings = {
  attribute: "",
  existingElement: true,
  elementType: "id",
  fullWidth: true,
  width: 300,
  height: 300,
  marginType: "%",
  margin: 0,
  paddingTop: 0,
  paddingBottom: 0,
  extraElementSelector: "",
  extraElementType: "id",
  extraElementBackground: "#CBEFE8",
  extraElementColor: "#009D7E",
  extraElementText: "You have qualified for free gifts!",
  extraElementTextBold: "Don`t forget to make your selection below",
};

const buttonSettings: ButtonSettings = {
  location: buttonLocationSettings,
  text: "GET OFFERS",
  sizes: buttonSizesSettings,
  shape: ButtonShapes.roundingAll,
  style: buttonStyleSettings,
  number: buttonNumberSettings,
};

const popupSettings: PopupSettings = {
  minimized: false,
  horizontal: "Vertical",
  general: generalSettings,
  location: locationSettings,
  header: textSettings,
  text: textSettings,
  image: imageSettings,
  getButton: getButtonSettings,
  imageRepresentation: imageRepresentation,
};

const popupMobileSettings: PopupSettings = {
  minimized: true,
  horizontal: "Vertical",
  general: generalSettings,
  location: locationSettings,
  header: textSettings,
  text: textSettings,
  image: imageMobileSettings,
  getButton: getButtonSettings,
  imageRepresentation: imageRepresentation,
};

const actionSettings: ActionSettings = {
  minimized: false,
  horizontal: "Vertical",
  general: generalSettings,
  location: locationSettings,
  behaviour: behaviourSettings,
  header: textSettings,
  text: textSettings,
  image: imageSettings,
  getButton: getButtonSettings,
  imageRepresentation: imageRepresentation,
};

const actionMobileSettings: ActionSettings = {
  minimized: true,
  horizontal: "Vertical",
  general: generalSettings,
  location: locationSettings,
  behaviour: behaviourSettings,
  header: textSettings,
  text: textSettings,
  image: imageMobileSettings,
  getButton: getButtonSettings,
  imageRepresentation: imageRepresentation,
};

const inlineSettings: InlineSettings = {
  minimized: false,
  element: elementSettings,
  general: generalSettings,
  header: textSettings,
  text: textSettings,
  image: imageSettings,
  getButton: getButtonSettings,
  imageRepresentation: imageRepresentation,
};

export const templateSettings: LayoutSettings = {
  desktop: {
    template: undefined,
    type: LayoutClass.button,
    button: buttonSettings,
    popup: popupSettings,
    inline: inlineSettings,
    action: actionSettings,
    orderExtractor: orderExtractorSettings,
  },
  mobile: {
    template: undefined,
    type: LayoutClass.button,
    button: buttonSettings,
    popup: popupMobileSettings,
    inline: inlineSettings,
    action: actionMobileSettings,
    orderExtractor: orderExtractorSettings,
  },
};

export const CAMPAIGN_INIT: CampaignType = {
  name: "",
  description: "",
  isActive: false,
  company_id: "",
  startDate: "",
  endDate: "",
  completionStatus: {
    status: false,
    next: CAMPAIGNSTEPS.details,
  },
  generalOptions: {
    automaticallyCheckOptIn: false,
    emailCaptureOnAddToOrder: false,
    emailCaptureOnOffers: false,
    saveOfferOptions: {
      allowSavingOffers: false,
      allowEmail: false,
      allowSms: false,
    },
    sendOneEmailPerOffer: false,
    showSelectedOffers: false,
    showAddToOrder: false,
    storeEmailForFuture: false,
    useEmailFromDataLayer: false,
    htmlSelector: "",
    frequencyCapping: {
      isEnabled: false,
      frequency: 0,
      reset: 0,
    },
    dataLayer: {
      htmlLocation: "",
      type: DATALAYER_TYPE.none,
      allowSavingDataLayerKeys: false,
    },
    allowInReport: false,
    randomSlots: false,
  },
  sharingOptions: {
    allowSharing: false,
    shareAdvertiserOffer: false,
    shareMessage: "",
    sharingPlatforms: {
      platforms: [],
    },
  },
  cookieOptions: {
    allowCookies: false,
    allowSessionCookies: false,
    allowPersistentCookies: true,
  },
  campaignAccessOptions: {
    openToEveryone: true,
    accessParam: "",
  },
  placementSettings: [],
  allowSharing: false,
  offerCount: 1,
  templateId: "",
  offers: [],
};
