import { useEffect, useRef, useState } from "react";
import { Grid, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import {
  CampaignType,
  TemplateOptionSettings,
} from "../../../../../../../types/campaign.interfaces";
import { Offer } from "../../../../../../../types/offer.interfaces";
import OfferItem from "../preview/offer-item/OfferItem.component";
import "swiper/css";
import "swiper/css/grid";
import { LayoutDevices } from "../../../../../const/views";

interface Props {
  displayOnly: boolean;
  settings: TemplateOptionSettings;
  campaign: CampaignType;
  offers: Offer[];
  device: LayoutDevices;
}

const SwiperComponent = ({
  displayOnly,
  settings,
  campaign,
  offers,
  device,
}: Props) => {
  const refNavPrev = useRef<HTMLDivElement | null>(null);
  const refNavNext = useRef<HTMLDivElement | null>(null);
  const elementRef = useRef<HTMLDivElement | null>(null);
  const [swiperWidth, setSwiperWidth] = useState(0);
  const [paginationNeeded, setPaginationNeeded] = useState(false);
  const [swiperFitContentClass, setSwiperFitContent] = useState<boolean>(false);

  // swiper item that are fully displayed with margin 20px
  const swiperItemDisplayed = Math.ceil(swiperWidth / 250);

  // swiper item that are fully displayed with last item without margin
  const swiperSize =
    Math.ceil((swiperWidth - swiperItemDisplayed * 20) / 230) * 2;

  useEffect(() => {
    // Check if the element is defined
    if (elementRef.current) {
      // Get the width of the element
      setSwiperWidth(elementRef?.current?.offsetWidth);
    }
  }, []);

  //set event for default active slide offers
  useEffect(() => {
    if (swiperSize !== 0) {
      setPaginationNeeded(offers.length > swiperSize);
    }
    // eslint-disable-next-line
  }, [swiperSize]);

  useEffect(() => {
    setSwiperFitContent(offers.length < swiperSize / 2);
  }, [offers, swiperSize]);

  return (
    <div ref={elementRef}>
      {offers.length > 0 && (
        <Swiper
          className={`${
            !paginationNeeded
              ? `swiper-container dme6-swiper-container ${
                  device === LayoutDevices.mobile
                    ? ""
                    : `dme6-swiper-container-${settings?.mainLayout?.align}`
                }`
              : `swiper-container`
          }`}
          modules={[Grid, Navigation]}
          grid={{
            rows: swiperFitContentClass ? 1 : 2,
          }}
          navigation={{
            prevEl: refNavPrev.current,
            nextEl: refNavNext.current,
          }}
          onBeforeInit={(swiper: any) => {
            swiper.params.navigation.prevEl = refNavPrev.current;
            swiper.params.navigation.nextEl = refNavNext.current;
          }}
          slidesPerView={device === LayoutDevices.mobile ? 1 : "auto"}
          slidesPerGroup={device === LayoutDevices.mobile ? 1 : swiperSize / 2}
          preventInteractionOnTransition={
            device === LayoutDevices.mobile ? true : false
          }
        >
          {offers.map((o: Offer, i: number) => {
            return (
              <SwiperSlide
                key={`slide${i}`}
                style={{ color: settings?.dealLayout.textColor }}
              >
                <OfferItem
                  offer={o}
                  campaign={campaign}
                  settings={settings?.dealLayout}
                  otherSettings={settings?.otherSettings}
                  displayOnly={displayOnly}
                />
              </SwiperSlide>
            );
          })}
          <div className="swiper-navigation-dme6">
            <div className="swiper-button-prev" ref={refNavPrev}>
              <svg
                width="34"
                height="34"
                viewBox="0 0 34 34"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="18.6094"
                  y="23"
                  width="8.6192"
                  height="1.72384"
                  rx="0.86192"
                  transform="rotate(-135 18.6094 23)"
                  fill={settings.mainLayout.textColor}
                />
                <rect
                  x="20.3135"
                  y="12.2188"
                  width="8.6192"
                  height="1.72384"
                  rx="0.86192"
                  transform="rotate(135 20.3135 12.2188)"
                  fill={settings.mainLayout.textColor}
                />
              </svg>
            </div>
            <div className="swiper-button-next" ref={refNavNext}>
              <svg
                width="34"
                height="34"
                viewBox="0 0 34 34"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="15.3906"
                  y="11"
                  width="8.6192"
                  height="1.72384"
                  rx="0.86192"
                  transform="rotate(45 15.3906 11)"
                  fill={settings.mainLayout.textColor}
                />
                <rect
                  x="14.1719"
                  y="22.2656"
                  width="8.6192"
                  height="1.72384"
                  rx="0.86192"
                  transform="rotate(-45 14.1719 22.2656)"
                  fill={settings.mainLayout.textColor}
                />
              </svg>
            </div>
          </div>
        </Swiper>
      )}
    </div>
  );
};

export default SwiperComponent;
