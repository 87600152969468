import { InfoCircleOutlined, LoadingOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons"
import { Button, Checkbox, Dropdown, Empty, message, Modal, Select, Typography } from "antd"
import { CheckboxChangeEvent } from "antd/lib/checkbox"
import Upload, { RcFile } from "antd/lib/upload"
import { Dispatch, MouseEvent, useEffect, useRef, useState } from "react"
import useAuth from "../../../hooks/useAuth.hook"
import useAxios from "../../../hooks/useAxios.hook"
import { postOfferImage } from "../../../services/upload/upload.service"
import { CompanyCategory, CompanyEmailTemplate, CompanyNetwork } from "../../../types/company.interfaces"
import { Pagination, Retailer, SelectOption } from "../../../types/global.interfaces";
import { ImageUpload, OfferCreate, OfferHostDetails } from "../../../types/offer.interfaces";
import { HTTPS_URL_REGEX } from "../../../utility/regexp"

interface Props {
    offer: OfferCreate,
    setOffer: Dispatch<React.SetStateAction<OfferCreate>>
    categories: CompanyCategory[] | undefined,
    networks: CompanyNetwork[],
    emailTemplates: CompanyEmailTemplate[],
}

const emptyHost = {
    hostId: '',
    hostName: '',
    imageUrl: '',
    cpa: {
        value: '',
        valueType: 'percentage'
    },
    destinationUrl: ''
}


const beforeUpload = (file: RcFile) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/gif';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG/GiF file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }

    return isLt2M;
};

const CreateOfferHost = ({ offer, setOffer, categories, networks, emailTemplates }: Props) => {
    const [isModalOpen, setModalOpen] = useState<boolean>(false);
    const [isEdit, setEdit] = useState<boolean>(false);
    const [filteredRetailers, setFilteredRetailers] = useState<Retailer[]>([]);
    const [hostSpecific, setHostSpecific] = useState<OfferHostDetails>(emptyHost);
    const [hostInEdit, setHostInEdit] = useState<OfferHostDetails>();
    const retailersRef = useRef<HTMLInputElement>(null);
    const { company } = useAuth();
    const { myAxios } = useAxios();
    const [retailers, setRetailers] = useState<Retailer[]>();
    const [searchRetailer, setSearchRetailer] = useState<string>('');
    const [pagination] = useState<Pagination>({
        page: 1,
        pageSize: 200
    });


    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState<string>();

    const handleUploadRequest = async (option: any) => {
        setLoading(true);
        if (option?.file) {
            const response = await postOfferImage(option.file);

            if (response?.data?.status) {
                const image: ImageUpload = response.data.result;
                setImageUrl(image.filepath);
                setHostSpecific(prevState => ({ ...prevState, imageUrl: image.filepath }))
            } else {
                message.error("Failed to upload image", 1);
            }
        }
        setLoading(false)
        return option
    }

    const fetchRetailersById = async (id: string) => {
        const { response } = await myAxios({ method: 'GET', url: `company/all?companyid=${id}&page=${pagination.page}&pageSize=${pagination.pageSize}` });
        if (response?.data?.status) {
            const _retailers: Retailer[] = response?.data?.result?.data;
            const selectedHosts = offer.offerHostDetails.map(h => h.hostId);
            setRetailers(_retailers);
            setFilteredRetailers(_retailers.filter(f => !selectedHosts.includes(f.id)));
        } else {
            message.error("Failed to get company", 1);
        }
    }

    useEffect(() => {
        const selectedHosts = offer.offerHostDetails.map(h => h.hostId);
        retailers && setFilteredRetailers(retailers.filter(f => !selectedHosts.includes(f.id)));
        // eslint-disable-next-line
    }, [offer.offerHostDetails]);

    useEffect(() => {
        const selectedHosts = offer.offerHostDetails.map(h => h.hostId);
        const _retailers = retailers?.filter(f => !selectedHosts.includes(f.id));
        if (_retailers) {
            if (searchRetailer) {
                const newRetailers: Retailer[] = structuredClone(_retailers);
                setFilteredRetailers(newRetailers.filter(f => f.name.toLowerCase().includes(searchRetailer.toLowerCase())) || []);
            } else {
                setFilteredRetailers(_retailers);
            }
        }
        // eslint-disable-next-line
    }, [searchRetailer]);

    useEffect(() => {
        company?.company_Id && fetchRetailersById(company.company_Id);
        // eslint-disable-next-line
    }, [company]);

    const handleClearRetailers = (event: MouseEvent<HTMLSpanElement>) => {
        event.preventDefault();
        setSearchRetailer('');
        retailersRef?.current?.focus();
    }

    const handleChangeRetailer = (checked: boolean, retailer: Retailer) => {
        checked && setHostSpecific(prevState => ({ ...prevState, hostId: retailer.id, hostName: retailer.name }));
    }

    const typeList: SelectOption[] = [
        {
            label: 'Percentage',
            value: 'percentage'
        },
        {
            label: 'Number',
            value: 'number'
        },
    ];

    const isValidUrl = (url: string): boolean => {
        return url?.length > 0 ? HTTPS_URL_REGEX.test(url) : true;
    }

    const isHostValid = (host: OfferHostDetails): boolean => {
        return !!host.hostId && Number(host.cpa.value) > 0 && isValidUrl(host.destinationUrl) && host?.destinationUrl?.length > 0
    }

    const handleAddHost = () => {
        if (isHostValid(hostSpecific)) {
            if (isEdit) {
                const _offerHostDetails = offer.offerHostDetails.map(h => h.hostId === hostInEdit?.hostId ? hostSpecific : h);
                setOffer(prevState => prevState && ({ ...prevState, offerHostDetails: _offerHostDetails }));
                setHostInEdit(undefined);
            } else {
                setOffer(prevState => prevState && ({ ...prevState, offerHostDetails: [...prevState.offerHostDetails, hostSpecific] }));
            }
            setHostSpecific(emptyHost);
            setModalOpen(false);
        }
    }

    const handleRemove = (id: string) => {
        setOffer(prevState => prevState && ({ ...prevState, offerHostDetails: prevState.offerHostDetails.filter(f => f.hostId !== id) }))
    }

    const handleOpenEditModal = (host: OfferHostDetails) => {
        setEdit(true);
        setImageUrl(host.imageUrl);
        setHostSpecific(host);
        setHostInEdit(host);
        setModalOpen(true);
    }

    const handleOpenAddModal = () => {
        setHostSpecific(emptyHost);
        setEdit(false);
        setModalOpen(true);
    }

    const openConfirmModal = (id: string) => {
        Modal.confirm({
            title: 'Do you want to remove this host from the selected list?',
            content: 'You can add it again from the available form',
            okText: 'Remove',
            icon: <></>,
            maskClosable: true,
            width: 520,
            closable: true,
            closeIcon: <span className="ant-modal-close-icon"><span className="icon icon-remove"></span></span>,
            cancelText: 'Cancel',
            cancelButtonProps: {
                className: 'button-default'
            },
            okButtonProps: {
                className: 'button-danger'
            },
            onOk: () => handleRemove(id)
        })
    }

    return <>
        <div className="widget-header">

            <div className="widget-icon-box mtop5">
                <span className="icon icon-settings"></span>
            </div>
            <div className="grow mleft10">
                <h3 className="widget-s-title">
                    Host Specific <InfoCircleOutlined id="guide-offer-hostspecific" className="font-hover font-small" />
                </h3>

                <div className="widget-form-description">
                    Specific offer for selected host
                </div>
            </div>
        </div>

        <div className="widget-content">

            <div className="widget-button">
                <Button className="button-success" onClick={handleOpenAddModal}>Add new host</Button>
            </div>

            <div className="widget-table">
                <div className="table-wrapper table-scrollable-wrapper">
                    <div className="table-scrollable">
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        Image
                                    </th>
                                    <th>
                                        Host
                                    </th>
                                    <th>
                                        Url
                                    </th>
                                    <th>
                                        CPA
                                    </th>
                                    <th>

                                    </th>
                                </tr>
                            </thead>

                            <tbody>
                                {offer.offerHostDetails?.map((host: OfferHostDetails, index: number) => {
                                    return <tr key={`host-${index}`}>
                                        <td>
                                            <img className="table-image mright10" src={host.imageUrl ? host.imageUrl : offer?.imageUrl} alt="" />
                                        </td>
                                        <td>
                                            {host.hostName}
                                        </td>
                                        <td>
                                            {host.destinationUrl}
                                        </td>
                                        <td>
                                            {host.cpa.value}{host.cpa.valueType === 'percentage' ? '%' : ''}
                                        </td>
                                        <td>
                                            <span className="table-actions">
                                                <span className="label label-select table-label mright10" onClick={() => handleOpenEditModal(host)}>Edit</span>
                                                <span className="table-icon icon-remove-square" onClick={() => openConfirmModal(host.hostId)} ></span>
                                            </span>
                                        </td>
                                    </tr>
                                })}
                                {offer.offerHostDetails?.length === 0 &&
                                    <tr>
                                        <td className="text-center" colSpan={5}>
                                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No host specific" />
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>

        <Modal
            title={<></>}
            closable={true}
            width='50vw'
            closeIcon={<span className="ant-modal-close-icon"><span className="icon icon-remove"></span></span>}
            maskClosable={true}
            open={isModalOpen}
            // onOk={onAddPages}
            onCancel={() => setModalOpen(false)}
            footer={<></>}
        >
            <div className="modal-title">
                BrandSwap host
            </div>
            <div className="modal-description">
                Please select the host to whom offer will be available
            </div>

            <div className="modal-content-table">

                <div className="label-wrapper">
                    <Upload
                        customRequest={handleUploadRequest}
                        name="avatar"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        multiple={false}
                        beforeUpload={beforeUpload}
                        maxCount={1}
                    >
                        {loading ?
                            <div>
                                <LoadingOutlined />
                                <div style={{ marginTop: 8 }}>Uploading</div>
                            </div>
                            :
                            <>
                                {imageUrl ?
                                    <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> :
                                    <div>
                                        <PlusOutlined />
                                        <div style={{ marginTop: 8 }}>Upload</div>
                                    </div>
                                }
                            </>}
                    </Upload>
                </div>

                <div className="label-input-dropdown">
                    <Dropdown
                        dropdownRender={(menu) => {
                            return <div className="dropdown">
                                <div className="dropdown-search">
                                    <SearchOutlined className="search-icon" />
                                    <input ref={retailersRef} type="text" className="default-input" value={searchRetailer} onChange={(event: React.ChangeEvent<HTMLInputElement>) => setSearchRetailer(event.target.value)} />
                                    <span className="close-icon" onClick={handleClearRetailers}>
                                        <span className="icon icon-remove"></span>
                                    </span>
                                </div>
                                <div className="dropdown-title">Hosts found:</div>
                                <ul>
                                    {filteredRetailers?.map((retailer: Retailer) => {
                                        return <li key={`details-cat-${retailer.id}`}>
                                            <Checkbox checked={retailer.id === hostSpecific?.hostId} onChange={(e: CheckboxChangeEvent) => handleChangeRetailer(e.target.checked, retailer)} className="checkbox-active">{retailer.name}</Checkbox>
                                        </li>
                                    })}
                                </ul>
                            </div>
                        }}
                        autoFocus={true}
                        trigger={['click']}>
                        <Typography.Link>
                            <div className="label-input-wrapper label-input-select">
                                <label htmlFor="page" tabIndex={0}>
                                    <span className="label-input-name">Selected host:</span>
                                    <span className="icon icon-arrow-down"></span>
                                    <input disabled value={hostSpecific.hostName} type="text" className="label-input" />
                                </label>
                            </div>
                        </Typography.Link>
                    </Dropdown>
                </div>

                <div className={`label-input-wrapper ${!isValidUrl(hostSpecific.destinationUrl) ? 'invalid' : ''}`}>
                    <label htmlFor="Name">
                        <span className="label-input-name">Destination URL *</span>
                        <input type="text" tabIndex={0} className="label-input" value={hostSpecific.destinationUrl} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setHostSpecific(prevState => ({ ...prevState, destinationUrl: e.target.value }))} />
                        {!isValidUrl(hostSpecific.destinationUrl) && <span className="error-line">Is not valid URL</span>}
                    </label>
                </div>

                <div className="multiple-label-input">
                    <div className="label-input-wrapper">
                        <label htmlFor="page">
                            <span className="label-input-name">CPA type</span>
                            <Select
                                suffixIcon={<span className="icon icon-arrow-down"></span>}
                                className="label-select"
                                value={hostSpecific.cpa.valueType}
                                onChange={(valueType: string) => {
                                    setHostSpecific(prevState => ({ ...prevState, cpa: { ...prevState.cpa, valueType } }))
                                }}
                                options={typeList}
                            >
                            </Select>
                        </label>

                    </div>
                    <div className="label-input-wrapper">
                        <label htmlFor="Name">
                            <span className="label-input-name">Value</span>
                            <input type="number" tabIndex={0} className="label-input" step="0.01" value={hostSpecific.cpa.value} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setHostSpecific(prevState => ({ ...prevState, cpa: { ...prevState.cpa, value: e.target.value } }))} />
                        </label>

                    </div>
                </div>

                <div className="widget-button mbot10">
                    <Button type="primary" disabled={!isHostValid(hostSpecific)} className={isEdit ? 'button-active' : 'button-success'} onClick={handleAddHost}>
                        {isEdit ? 'Edit' : 'Add'}
                    </Button>
                </div>

            </div>
        </Modal>


    </>
}

export default CreateOfferHost