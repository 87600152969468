import { Tabs } from "antd";
import { useState } from "react";
import useExpress from "../../../hooks/useExpress.hook";
import { EXPRESS_ONBOARDING } from "../types/express.enum";
import ExpressDetails from "./ExpressDetails/ExpressDetails";
import successIcon from "../../../assets/images/icons/onboarding-success-icon.svg";
import logoIcon from "../../../assets/images/icons/logo-express.svg";
import ExpressOffers from "./ExpressOffers/ExpressOffers";
import ExpressFinish from "./ExpressFinish/ExpressFinish";
import ExpressAppearance from "./ExpressAppearance/ExpressAppearance.component";

const ExpressOnboarding = () => {
  const { onboardingStatus } = useExpress();
  const [currentStep, setCurrentStep] = useState<EXPRESS_ONBOARDING>(
    onboardingStatus?.next ? onboardingStatus?.next : EXPRESS_ONBOARDING.details
  );

  const checkCompletedTab = (
    tab: EXPRESS_ONBOARDING,
    step: EXPRESS_ONBOARDING
  ): boolean => {
    var isCompleted: boolean = false;
    switch (tab) {
      case EXPRESS_ONBOARDING.details:
        isCompleted = step !== EXPRESS_ONBOARDING.details;
        break;
      case EXPRESS_ONBOARDING.offers:
        isCompleted =
          step !== EXPRESS_ONBOARDING.details &&
          step !== EXPRESS_ONBOARDING.offers;
        break;
      case EXPRESS_ONBOARDING.appearance:
        isCompleted = step === EXPRESS_ONBOARDING.finish;
        break;

      default:
        break;
    }

    return isCompleted;
  };

  return (
    <>
      <div className="express-onboarding">
        <div className="express-onboarding-stesp">
          <Tabs
            className={`express-tabs express-tab-${currentStep}`}
            centered
            moreIcon={false}
            animated={false}
            activeKey={currentStep}
            defaultActiveKey={EXPRESS_ONBOARDING.details}
            items={[
              {
                label: (
                  <img
                    className="express-logo"
                    src={logoIcon}
                    alt="Brandswap logo"
                  />
                ),
                key: "logo",
                children: <></>,
              },
              {
                label: (
                  <span
                    className={`${
                      checkCompletedTab(EXPRESS_ONBOARDING.details, currentStep)
                        ? "express-completed-tab"
                        : ""
                    }`}
                  >
                    {checkCompletedTab(
                      EXPRESS_ONBOARDING.details,
                      currentStep
                    ) && (
                      <img
                        className="mright10"
                        src={successIcon}
                        alt="success"
                      />
                    )}
                    1. Your Details
                  </span>
                ),
                key: EXPRESS_ONBOARDING.details,
                children: <ExpressDetails setCurrentStep={setCurrentStep} />,
              },
              {
                label: (
                  <span
                    className={`${
                      checkCompletedTab(EXPRESS_ONBOARDING.offers, currentStep)
                        ? "express-completed-tab"
                        : ""
                    }`}
                  >
                    {checkCompletedTab(
                      EXPRESS_ONBOARDING.offers,
                      currentStep
                    ) && (
                      <img
                        className="mright10"
                        src={successIcon}
                        alt="success"
                      />
                    )}
                    2. Offers
                  </span>
                ),
                key: EXPRESS_ONBOARDING.offers,
                children: <ExpressOffers setCurrentStep={setCurrentStep} />,
              },
              {
                label: (
                  <span
                    className={`${
                      checkCompletedTab(
                        EXPRESS_ONBOARDING.appearance,
                        currentStep
                      )
                        ? "express-completed-tab"
                        : ""
                    }`}
                  >
                    {checkCompletedTab(
                      EXPRESS_ONBOARDING.appearance,
                      currentStep
                    ) && (
                      <img
                        className="mright10"
                        src={successIcon}
                        alt="success"
                      />
                    )}
                    3. Appearance
                  </span>
                ),
                key: EXPRESS_ONBOARDING.appearance,
                children: <ExpressAppearance setCurrentStep={setCurrentStep} />,
              },
              {
                label: (
                  <span
                    className={`${
                      checkCompletedTab(EXPRESS_ONBOARDING.finish, currentStep)
                        ? "express-completed-tab"
                        : ""
                    }`}
                  >
                    {checkCompletedTab(
                      EXPRESS_ONBOARDING.finish,
                      currentStep
                    ) && (
                      <img
                        className="mright10"
                        src={successIcon}
                        alt="success"
                      />
                    )}
                    4. Finish
                  </span>
                ),
                key: EXPRESS_ONBOARDING.finish,
                children: <ExpressFinish setCurrentStep={setCurrentStep} />,
              },
            ]}
          ></Tabs>
        </div>
      </div>
    </>
  );
};

export default ExpressOnboarding;
