/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import usePage from "../../hooks/usePage.hook";
import Footer from "../footer/footer.component";
import "./css/settings.scss";
import { SettingsType } from "../../types/templates.interfaces";
import { Button, message } from "antd";
import CompanySettings from "./company/CompanySettings.component";
import EmailTemplatesSettings from "./email-templates/EmailTemplatesSettings.component";
import DevelopmentToolsSettings from "./development-tools/DevelopmentToolsSettings.component";
import AffiliateAndOffersSettings from "./affiliate-and-offers/AffiliateAndOffersSettings.component";
import {
  CompanySettingsInterface,
  emptyCompany,
} from "../../types/company.interfaces";
import useAxios from "../../hooks/useAxios.hook";
import { URL_REGEX } from "../../utility/regexp";
import { LoadingOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router-dom";
import useAuth from "../../hooks/useAuth.hook";
import { ACCESS_TYPES } from "../../utility/enums/user.enums";
import FinancialSettings from "./financial/Financial.components";

const Settings = () => {
  const navigate = useNavigate();
  const { companyId } = useParams();
  const { myAxios } = useAxios();
  const { auth, company: companyAccess } = useAuth();
  const { setPage } = usePage();
  const [loading, setLoading] = useState<boolean>(false);
  const [settingsType, setSettingsType] = useState<
    SettingsType | SettingsType.COMPANY
  >(SettingsType.COMPANY);
  const [company, setCompany] =
    useState<CompanySettingsInterface>(emptyCompany);

  useEffect(() => {
    setPage({
      title: <>Settings</>,
    });
  }, []);

  useEffect(() => {
    if (companyId) {
      getCompany();
    }
  }, []);

  const getCompany = async () => {
    const { response } = await myAxios({
      method: "GET",
      url: `company/${companyId}`,
    });

    if (response?.status) {
      setCompany(response?.data);
    } else {
      message.error("Failed to get company", 1);
    }
  };

  const handleEditCompany = async () => {
    setLoading(true);
    const { response } = await myAxios({
      method: "PUT",
      url: `/company/${company.id}`,
      data: company,
    });

    if (response?.status === 200) {
      message.success("Company updated!", 1);
    } else {
      message.error("Failed to update company!");
    }
    setLoading(false);
  };

  const handleCreateCompany = async () => {
    setLoading(true);
    const { response } = await myAxios({
      method: "POST",
      url: `/company`,
      data: company,
    });

    if (response?.status === 200) {
      message.success("Company created!", 1);
      response?.data?.result && setCompany(response?.data?.result);
      navigate(`/company/edit/${response?.data?.result?.id}`);
      setSettingsType(SettingsType.COMPANY);
    } else {
      message.error("Failed to create company!");
    }
    setLoading(false);
  };

  const handleValidation = useMemo((): boolean => {
    let isValid =
      !company.name ||
      !(company.name.length >= 2) ||
      !company.companyType ||
      !company.timezone ||
      !company.registrationCountry ||
      !company?.url ||
      !URL_REGEX.test(company?.url || "") ||
      isNaN(company.commissionAmount.amount) ||
      company.commissionAmount.amount < 0 ||
      company.commissionAmount.amount > 100;

    return isValid;
  }, [company]);

  const getCurrentComponent = useMemo((): JSX.Element => {
    let jsxElm = <></>;
    switch (settingsType) {
      case SettingsType.COMPANY:
        jsxElm = (
          <CompanySettings
            company={company}
            setCompany={setCompany}
            isSuperAdmin={auth?.isSuperAdmin}
          />
        );
        break;
      case SettingsType.AFFILIATE_AND_OFFERS:
        jsxElm = (
          <AffiliateAndOffersSettings
            company={company}
            setCompany={setCompany}
            isSuperAdmin={auth?.isSuperAdmin}
          />
        );

        break;
      case SettingsType.EMAIL_TEMPLATES:
        jsxElm = (
          <EmailTemplatesSettings company={company} setCompany={setCompany} />
        );

        break;

      case SettingsType.DEVELOPEMENT_TOOLS:
        jsxElm = <DevelopmentToolsSettings authToken={company.authToken} />;

        break;

      case SettingsType.FINANCIAL:
        jsxElm = (
          <FinancialSettings
            company={company}
            setCompany={setCompany}
            isSuperAdmin={auth?.isSuperAdmin}
          />
        );

        break;

      default:
        break;
    }
    return jsxElm;
  }, [settingsType, company]);

  return (
    <div className="content">
      <div className="content-header">
        <ul className="content-list">
          <li
            className={settingsType === SettingsType.COMPANY ? "active" : ""}
            onClick={() => setSettingsType(SettingsType.COMPANY)}
          >
            {SettingsType.COMPANY}
          </li>
          {companyAccess?.userAccess?.access.includes(
            ACCESS_TYPES.companynetworkswrite
          ) && (
            <li
              className={
                settingsType === SettingsType.AFFILIATE_AND_OFFERS
                  ? "active"
                  : ""
              }
              onClick={() => setSettingsType(SettingsType.AFFILIATE_AND_OFFERS)}
            >
              {SettingsType.AFFILIATE_AND_OFFERS}
            </li>
          )}

          {auth?.isSuperAdmin && (
            // companyAccess?.userAccess?.access.includes(
            //   ACCESS_TYPES.companyemailtemplateswrite
            // ) &&
            <li
              className={
                settingsType === SettingsType.EMAIL_TEMPLATES ? "active" : ""
              }
              onClick={() => setSettingsType(SettingsType.EMAIL_TEMPLATES)}
            >
              {SettingsType.EMAIL_TEMPLATES}
            </li>
          )}
          {companyId && (
            // companyAccess?.userAccess?.access.includes(
            //   ACCESS_TYPES.companydevtoolswrite
            // ) &&
            <li
              className={
                settingsType === SettingsType.DEVELOPEMENT_TOOLS ? "active" : ""
              }
              onClick={() => setSettingsType(SettingsType.DEVELOPEMENT_TOOLS)}
            >
              {SettingsType.DEVELOPEMENT_TOOLS}
            </li>
          )}
          {(auth?.isSuperAdmin ||
            companyAccess?.userAccess?.access.includes(
              ACCESS_TYPES.financewrite
            )) && (
            <li
              className={
                settingsType === SettingsType.FINANCIAL ? "active" : ""
              }
              onClick={() => setSettingsType(SettingsType.FINANCIAL)}
            >
              {SettingsType.FINANCIAL}
            </li>
          )}
        </ul>
        {companyAccess?.userAccess?.access.includes(
          ACCESS_TYPES.companywrite
        ) && (
          <Button
            type="primary"
            onClick={() =>
              companyId ? handleEditCompany() : handleCreateCompany()
            }
            disabled={handleValidation || loading}
          >
            Save {loading && <LoadingOutlined />}
          </Button>
        )}
      </div>
      {loading ? (
        <div className="content-layout">
          <div className="card flex-center">
            <LoadingOutlined />
          </div>
        </div>
      ) : (
        <div className="content-layout">{getCurrentComponent}</div>
      )}
      <Footer />
    </div>
  );
};

export default Settings;
