import { BrowserRouter } from "react-router-dom";
import "./assets/less/antd.less";
import "./assets/css/style.scss";
import AppRoutes from "./routes/AppRoutes.component";
import { PageProvider } from "./context/pageProvider.context";

const App = () => {
  return (
    <BrowserRouter>
      <PageProvider>
        <AppRoutes></AppRoutes>
      </PageProvider>
    </BrowserRouter>
  );
};

export default App;
