import { Alert, Button, Checkbox, message, Modal } from "antd/lib";
import React, { useState } from "react";
import { EXPRESS_ONBOARDING } from "../../types/express.enum";
import useExpress from "../../../../hooks/useExpress.hook";
import GreenEye from "../../../../assets/images/icons/express-green-eye.svg";
import Rocket from "../../../../assets/images/icons/express-rocket.svg";
import { ExpressCampaign } from "../../types/express.types";
import useAxios from "../../../../hooks/useAxios.hook";

interface Props {
  setCurrentStep: React.Dispatch<React.SetStateAction<EXPRESS_ONBOARDING>>;
}

const ExpressFinish = ({ setCurrentStep }: Props) => {
  const { myAxios } = useAxios();
  const {
    campaign,
    setCampaign,
    company,
    loading: expressLoading,
    getUserById,
  } = useExpress();
  const [loading, setLoading] = useState<boolean>(expressLoading);
  const [dropDown, setDropDown] = useState<boolean>(false);
  const [goLiveNowModal, setGoLiveNowModal] = useState<boolean>(false);
  const [goLiveLaterModal, setGoLiveLaterModal] = useState<boolean>(false);
  const [confirmation, setConfirmation] = useState<boolean>(false);

  const handleBack = () => {
    setCurrentStep(EXPRESS_ONBOARDING.appearance);
  };

  const goLiveNow = () => {
    setGoLiveNowModal(false);
    handleStart();
    postCampaignExpress(true);
  };

  const goLiveLater = () => {
    setGoLiveLaterModal(false);
    postCampaignExpress(false);
  };

  const postCampaignExpress = async (goLiveNow: boolean) => {
    setLoading(true);
    const { response } = await myAxios({
      method: "POST",
      url: `onboarding/express/campaign`,
      data: {
        company,
        campaign: {
          ...campaign,
          isActive: goLiveNow,
        },
        onboardingStatus: {
          completed: true,
          next: "admin",
        },
      },
    });
    if (response?.data?.status) {
      message.success({
        content: "Finnished setting up your campaign",
        className: "express-message",
        duration: 1,
      });
      setCurrentStep(EXPRESS_ONBOARDING.finish);
      getUserById(company?.id || "");
    } else {
      message.error("Failed to continue", 1);
    }
    setLoading(false);
  };

  const handleStart = () => {
    handleStartCampaign(campaign?.id);
    setCampaign(
      (prevState: ExpressCampaign | undefined) =>
        prevState && { ...prevState, isActive: true }
    );
  };

  const handleStartCampaign = async (id: string | undefined) => {
    if (id) {
      setLoading(true);
      const { response, error } = await myAxios({
        method: "post",
        url: `/campaign/active/${id}/true`,
      });

      if (response?.status) {
        message.success({
          content: "Campaign is started",
          className: "express-message",
          duration: 1,
        });
      } else {
        message.error("Campaign failed to start", 1);
        console.log(error);
      }
    }
    setLoading(false);
  };

  const getHomepageUrl = (url: string): string => {
    try {
      const parsedUrl = new URL(url);
      return `${parsedUrl.protocol}//${parsedUrl.hostname}`;
    } catch (error) {
      console.error("Invalid URL", error);
      return url; // or handle the error as needed
    }
  };

  const handlePreview = () => {
    const page = campaign?.placementSettings?.[0]?.page;
    const placementId = campaign?.placementSettings?.[0]?.id;
    const homepageUrl = page ? getHomepageUrl(page) : null;
    homepageUrl &&
      window.open(
        `${homepageUrl}?bsw_plid=${placementId}&bsw_preview=1`,
        "_blank"
      );
  };

  return (
    <>
      <div className="express-onboarding-description">
        <Alert
          description={
            <>
              You are now ready to go live! <br />
              Before you press “Go live”, please preview your campaign here for
              a final check.
            </>
          }
          type="info"
        />
      </div>
      <div className="preview-button flex-center" onClick={handlePreview}>
        <img src={GreenEye} alt="preview" className="mright5" />
        <div className="green-text">Preview campaign</div>
      </div>

      <div className="rocket flex-center">
        <img src={Rocket} alt="preview" />
      </div>

      <div className="express-button flex-center">
        <Button
          className="express-button-back mright10"
          onClick={handleBack}
          type="default"
        >
          Back
        </Button>
        <div className="golive-btn-arrow">
          <Button
            className="express-button-success golive-button"
            loading={loading}
            type="default"
            onClick={() => setGoLiveNowModal(true)}
          >
            Go live!
          </Button>
          <div
            className="golive-button-arrow"
            onClick={() => setDropDown(!dropDown)}
          >
            <svg
              className="golive-button-icon"
              width="8"
              height="4"
              viewBox="0 0 8 4"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M4 4L0.535898 0.25L7.4641 0.25L4 4Z" fill="white" />
            </svg>
          </div>
          {dropDown && (
            <div className="golive-dropdown">
              <div onClick={() => setGoLiveNowModal(true)}>Go live now</div>
              <div onClick={() => setGoLiveLaterModal(true)}>Go live later</div>
            </div>
          )}
        </div>
      </div>
      <Modal
        className="modal-express"
        title={
          <div className="golive-modal-title">You are about to go live</div>
        }
        footer={<></>}
        maskClosable={true}
        open={goLiveNowModal}
        width={520}
        closable={true}
        afterClose={() => setGoLiveNowModal(false)}
        onCancel={() => setGoLiveNowModal(false)}
        closeIcon={<></>}
      >
        <div className="table-wrapper table-wrapper--modal table-scrollable-wrapper golive-modal">
          <div className="golive-modal-desc">
            Great, you are all set! Please confirm that you have made sure
            everything is okay with your campaign.
          </div>

          <Checkbox
            className="express-checkbox golive-modal-confirm"
            onChange={() => setConfirmation((prev) => !prev)}
          >
            I confirm that I have checked my campaign’s configuration and it’s
            ready to go live
          </Checkbox>

          <div className="express-button-modal">
            <Button
              className="express-button-back mright10"
              onClick={() => setGoLiveNowModal(false)}
              type="default"
            >
              Cancel
            </Button>
            <Button
              className="express-button-success"
              type="default"
              onClick={goLiveNow}
              disabled={!confirmation}
            >
              Go live!
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        className="modal-express"
        title={<div className="golive-modal-title">You won’t go live yet</div>}
        footer={<></>}
        maskClosable={true}
        open={goLiveLaterModal}
        width={520}
        closable={true}
        afterClose={() => setGoLiveLaterModal(false)}
        onCancel={() => setGoLiveLaterModal(false)}
        closeIcon={<></>}
      >
        <div className="table-wrapper table-wrapper--modal table-scrollable-wrapper golive-modal">
          <div className="golive-modal-desc">
            Are you sure you don't want to start generating revenue right now?
            If not, you will go to your dashboard and you can publish your
            campaign at any time.
          </div>
          <div className="express-button-modal">
            <Button
              className="express-button-back mright10"
              onClick={() => setGoLiveLaterModal(false)}
              type="default"
            >
              Cancel
            </Button>
            <Button
              className="express-button-success"
              type="default"
              onClick={goLiveLater}
            >
              Go to dashboard
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};
export default ExpressFinish;
