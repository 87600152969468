import { Dropdown, Slider, Typography } from "antd";
import { uniqueKey } from "highcharts";
import { useState, useEffect } from "react";
import useDebounce from "../../../../../../hooks/useDebounce.hook";
import { DropdownMenuProps } from "../../../../../../types/antd.interfaces";
import {
  DealLayoutSettings,
  MainLayoutSettings,
} from "../../../../../../types/campaign.interfaces";
import {
  compactTemplates,
  Template,
} from "../../../../../../types/templates.interfaces";
import {
  itemsFontFamily,
  itemsFontWeight,
  getNumberFromFontWeight,
  itemsFontSizeEmbedded,
  itemsFontSize,
} from "../../../../../campaigns/const/layout-options.enum";
import "./TemplateSettings.scss";

interface Props {
  updateDealLayoutSettings: (value: DealLayoutSettings) => void;
  updateMainLayoutSettings: (value: MainLayoutSettings) => void;
  mainLayout: MainLayoutSettings | undefined;
  dealLayout: DealLayoutSettings | undefined;
  template: Template | undefined;
}

const TemplateSettings = ({
  updateDealLayoutSettings,
  updateMainLayoutSettings,
  template,
  mainLayout,
  dealLayout,
}: Props) => {
  const [headerText, setHeaderText] = useState<string>(
    mainLayout?.headerText || ""
  );
  const debounceHeaderValue = useDebounce(headerText, 1000);

  const [subHeaderText, setSubHeaderText] = useState<string>(
    mainLayout?.subHeaderText || ""
  );
  const debounceSubHeaderValue = useDebounce(subHeaderText, 1000);

  const [countText, setCountText] = useState<string>(
    mainLayout?.countText || ""
  );

  const debounceCountText = useDebounce(countText, 1000);

  const [selectText, setSelectText] = useState<string>(
    mainLayout?.selectText || ""
  );

  const debounceSelectText = useDebounce(selectText, 1000);

  useEffect(() => {
    setHeaderText(mainLayout?.headerText || "");
  }, [mainLayout?.headerText]);

  useEffect(() => {
    setSubHeaderText(mainLayout?.subHeaderText || "");
  }, [mainLayout?.subHeaderText]);

  useEffect(() => {
    setSelectText(mainLayout?.selectText || "");
  }, [mainLayout?.selectText]);

  useEffect(() => {
    setCountText(mainLayout?.countText || "");
  }, [mainLayout?.countText]);

  const setNewSettings = (
    key: keyof MainLayoutSettings,
    value: string | number
  ) => {
    if (mainLayout) {
      const newSettings: MainLayoutSettings = {
        ...mainLayout,
        [key]: value,
      };

      updateMainLayoutSettings(newSettings);
    }
  };

  const handleBgChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const color = event.target.value;
    setNewSettings("backgroundColor", color);
  };

  const handleChangeFontFamily = (props: DropdownMenuProps) => {
    setNewSettings("fontFamily", props.key);
  };

  const handleChangeHeaderFontSize = (props: DropdownMenuProps) => {
    setNewSettings("headerFontSize", props.key);
  };

  const handleChangeSubHeaderFontSize = (props: DropdownMenuProps) => {
    setNewSettings("subHeaderFontSize", props.key);
  };

  const handleChangeHeaderFontWeight = (props: DropdownMenuProps) => {
    setNewSettings("headerFontWeight", props.key);
  };

  const handleChangeSubHeaderFontWeight = (props: DropdownMenuProps) => {
    setNewSettings("subHeaderFontWeight", props.key);
  };

  const handleChangeHeaderTextColor = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const color = event.target.value;
    setNewSettings("headerTextColor", color);
  };

  const handleChangeSubHeaderTextColor = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const color = event.target.value;
    setNewSettings("subHeaderTextColor", color);
  };

  const handleHeaderText = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setHeaderText(value);
  };

  useEffect(() => {
    if (typeof debounceHeaderValue !== "undefined") {
      setNewSettings("headerText", debounceHeaderValue);
    }
    // eslint-disable-next-line
  }, [debounceHeaderValue]);

  const handleSubHeaderText = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSubHeaderText(value);
  };

  useEffect(() => {
    if (typeof debounceSubHeaderValue !== "undefined") {
      setNewSettings("subHeaderText", debounceSubHeaderValue);
    }
    // eslint-disable-next-line
  }, [debounceSubHeaderValue]);

  useEffect(() => {
    if (typeof debounceCountText !== "undefined") {
      setNewSettings("countText", debounceCountText);
    }
    // eslint-disable-next-line
  }, [debounceCountText]);

  useEffect(() => {
    if (typeof debounceSelectText !== "undefined") {
      setNewSettings("selectText", debounceSelectText);
    }
    // eslint-disable-next-line
  }, [debounceSelectText]);

  // DEAL LAYOUT

  const setDealNewSettings = (
    key: keyof DealLayoutSettings,
    value: string | number | boolean
  ) => {
    if (dealLayout) {
      const newSettings: DealLayoutSettings = {
        ...dealLayout,
        [key]: value,
      };

      updateDealLayoutSettings(newSettings);
    }
  };

  const handleDealBgChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const color = event.target.value;
    setDealNewSettings("backgroundColor", color);
  };

  const handleButtonBgChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const color = event.target.value;
    setDealNewSettings("buttonBackgroundColor", color);
  };

  const handleButtonTextColor = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const color = event.target.value;
    setDealNewSettings("buttonTextColor", color);
  };

  const handleChangeRounding = (value: number) => {
    setDealNewSettings("buttonRounding", value);
  };

  return (
    <>
      <div className="option-box">
        <div className="option-content">
          <div className="template-settings flex-center-space-between mbot20">
            <div className="option-col flex-third">
              <input
                type="color"
                className="color-box"
                id={`mainLayoutBg${uniqueKey}`}
                value={mainLayout?.backgroundColor || "#000000"}
                onChange={handleBgChange}
              />
              <label htmlFor={`mainLayoutBg${uniqueKey}`}>
                Main background: {mainLayout?.backgroundColor || "#000000"}
              </label>
            </div>
            <div className="option-col flex-third">
              <input
                type="color"
                className="color-box"
                id={`dealLayoutBg${uniqueKey}`}
                value={dealLayout?.backgroundColor || "#000000"}
                onChange={handleDealBgChange}
              />
              <label htmlFor={`dealLayoutBg${uniqueKey}`}>
                Deal background: {dealLayout?.backgroundColor || "#000000"}
              </label>
            </div>
            <div className="option-col flex-third">
              <Dropdown
                menu={{
                  items: itemsFontFamily,
                  selectable: true,
                  defaultSelectedKeys: [mainLayout?.fontFamily || "Montserrat"],
                  onClick: handleChangeFontFamily,
                }}
                trigger={["click"]}
              >
                <Typography.Link>
                  {mainLayout?.fontFamily || "Montserrat"}
                </Typography.Link>
              </Dropdown>
            </div>
          </div>

          <div className="template-settings flex-space-between">
            {/* Header */}
            <div className="flex-third">
              <div className="option-label">Header</div>

              <div className="option-sigle-col mbot10">
                <div className="option-col">
                  <input
                    type="text"
                    className="default-input"
                    value={headerText || ""}
                    placeholder={""}
                    onChange={handleHeaderText}
                  />
                </div>
              </div>
              <div className="flex-center-space-between">
                <div className="option-col">
                  <input
                    type="color"
                    className="color-box"
                    id={`headerColor${uniqueKey}`}
                    value={mainLayout?.headerTextColor || "#aaaaaa"}
                    onChange={handleChangeHeaderTextColor}
                  />
                  <label htmlFor={`headerColor${uniqueKey}`}>
                    Text: {mainLayout?.headerTextColor || "#aaaaaa"}
                  </label>
                </div>
                <div className="option-col">
                  <Dropdown
                    menu={{
                      items: itemsFontWeight,
                      selectable: true,
                      defaultSelectedKeys: [
                        mainLayout?.headerFontWeight?.toString() || "0",
                      ],
                      onClick: handleChangeHeaderFontWeight,
                    }}
                    trigger={["click"]}
                  >
                    <Typography.Link>
                      {mainLayout?.headerFontWeight
                        ? getNumberFromFontWeight(
                            mainLayout.headerFontWeight.toString()
                          )
                        : "Regular"}
                    </Typography.Link>
                  </Dropdown>
                </div>
                <div className="option-col">
                  <Dropdown
                    menu={{
                      items:
                        template &&
                        compactTemplates.includes(template?.identifier)
                          ? itemsFontSizeEmbedded
                          : itemsFontSize,
                      selectable: true,
                      defaultSelectedKeys: [mainLayout?.headerFontSize || "14"],
                      onClick: handleChangeHeaderFontSize,
                    }}
                    trigger={["click"]}
                  >
                    <Typography.Link>
                      {mainLayout?.headerFontSize || "14"}
                    </Typography.Link>
                  </Dropdown>
                </div>
              </div>
            </div>

            {/* Subheader */}
            <div className="flex-third">
              <div className="option-label">Sub header</div>

              <div className="option-sigle-col mbot10">
                <div className="option-col ">
                  <input
                    type="text"
                    className="default-input"
                    value={subHeaderText || ""}
                    placeholder={"Get 3 free gifts if you purchase today"}
                    onChange={handleSubHeaderText}
                  />
                </div>
              </div>
              <div className="flex-center-space-between">
                <div className="option-col">
                  <input
                    type="color"
                    className="color-box"
                    id={`subHeaderColor${uniqueKey}`}
                    value={mainLayout?.subHeaderTextColor || "#aaaaaa"}
                    onChange={handleChangeSubHeaderTextColor}
                  />
                  <label htmlFor={`subHeaderColor${uniqueKey}`}>
                    Text: {mainLayout?.subHeaderTextColor || "#aaaaaa"}
                  </label>
                </div>

                <div className="option-col">
                  <Dropdown
                    menu={{
                      items: itemsFontWeight,
                      selectable: true,
                      defaultSelectedKeys: [
                        mainLayout?.subHeaderFontWeight?.toString() || "0",
                      ],
                      onClick: handleChangeSubHeaderFontWeight,
                    }}
                    trigger={["click"]}
                  >
                    <Typography.Link>
                      {mainLayout?.subHeaderFontWeight
                        ? getNumberFromFontWeight(
                            mainLayout.subHeaderFontWeight.toString()
                          )
                        : "Regular"}
                    </Typography.Link>
                  </Dropdown>
                </div>
                <div className="option-col">
                  <Dropdown
                    menu={{
                      items:
                        template &&
                        compactTemplates.includes(template?.identifier)
                          ? itemsFontSizeEmbedded
                          : itemsFontSize,
                      selectable: true,
                      defaultSelectedKeys: [
                        mainLayout?.subHeaderFontSize || "14",
                      ],
                      onClick: handleChangeSubHeaderFontSize,
                    }}
                    trigger={["click"]}
                  >
                    <Typography.Link>
                      {mainLayout?.subHeaderFontSize || "14"}
                    </Typography.Link>
                  </Dropdown>
                </div>
              </div>
            </div>

            {/* Button */}
            <div className="flex-third">
              <div className="option-label">Button</div>

              <div className="option-col mbot10">
                <input
                  type="color"
                  className="color-box"
                  id={`dealBg${uniqueKey}`}
                  value={dealLayout?.buttonBackgroundColor || "#ffffff"}
                  onChange={handleButtonBgChange}
                />
                <label htmlFor={`dealBg${uniqueKey}`}>
                  Background: {dealLayout?.buttonBackgroundColor}
                </label>
              </div>
              <div className="option-col mbot10">
                <input
                  type="color"
                  className="color-box"
                  id={`dealButtonText${uniqueKey}`}
                  value={dealLayout?.buttonTextColor || "#aaaaaa"}
                  onChange={handleButtonTextColor}
                />
                <label htmlFor={`dealButtonText${uniqueKey}`}>
                  Text: {dealLayout?.buttonTextColor}
                </label>
              </div>
              <div className="option-col">
                <Dropdown
                  trigger={["click"]}
                  dropdownRender={() => (
                    <div className="dropdown min150">
                      <div className="dropdown-title option-label">
                        Set Rounding
                      </div>
                      <div className="flex-center-space-between">
                        <Slider
                          min={0}
                          max={100}
                          className="min100"
                          onChange={handleChangeRounding}
                          value={dealLayout?.buttonRounding}
                        />
                      </div>
                    </div>
                  )}
                >
                  <Typography.Link>
                    {dealLayout?.buttonRounding}px (roundness)
                  </Typography.Link>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TemplateSettings;
