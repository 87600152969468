import { LoadingOutlined } from "@ant-design/icons";
import Table, { ColumnsType } from "antd/lib/table";
import { InsightsCampaignsSummaryInterface } from "../../../../types/events.interfaces";
import { stripAndReturnNumber } from "../../../../utility/numbers/stringToNumber";
import CommissionTooltip from "../Tooltips/CommissionTooltip";
import SalesTooltip from "../Tooltips/SalesTooltip";
import {
  formatFixedNumber,
  formatNumber,
} from "../../../../utility/numbers/numbers";

interface Props {
  loading: boolean;
  campaignsInsights: InsightsCampaignsSummaryInterface[];
}

const CampaignDailySummary = ({ loading, campaignsInsights }: Props) => {
  const impressionsSum = (): number => {
    let impressions = 0;
    campaignsInsights.forEach((value) => {
      impressions +=
        value.distinctSeen > 0 ? value.distinctSeen : value.distinctOpen;
    });
    return impressions;
  };

  const loadsSum = (): number => {
    let loads = 0;
    campaignsInsights?.forEach((value) => {
      loads += value.distinctOpen;
    });
    return loads;
  };

  const seenSum = (): number => {
    let seen = 0;
    campaignsInsights?.forEach((value) => {
      seen +=
        value.distinctSeen > 0
          ? (value.distinctSeen / value.distinctOpen) * 100
          : 100;
    });
    return seen > 0 ? seen / campaignsInsights.length : 0;
  };

  const salesSum = (): number => {
    let sales = 0;
    campaignsInsights.forEach((value) => {
      sales += value.totalSalesCount;
    });
    return sales;
  };

  const commisionSum = (): number => {
    let commision = 0;
    campaignsInsights.forEach((value) => {
      commision += value.totalCommission;
    });
    return commision;
  };

  const commisionAvg = (): number => {
    let commision = 0;
    campaignsInsights.forEach((value) => {
      commision += value.totalCommission;
    });
    return commision > 0 ? commision / campaignsInsights.length : commision;
  };

  const checkForZero = (param1: number, param2?: number): boolean => {
    if (!param2) {
      return param1 > 0;
    }
    return param1 > 0 && param2 > 0;
  };

  const ecpmAvg = (): number => {
    let ecpm = 0;
    campaignsInsights?.forEach((value) => {
      ecpm +=
        value.distinctSeen > 0
          ? checkForZero(value.distinctSeen, value.totalCommission)
            ? (value.totalCommission / value.distinctSeen) * 1000
            : 0
          : checkForZero(value.distinctOpen, value.totalCommission)
          ? (value.totalCommission / value.distinctOpen) * 1000
          : 0;
    });
    return ecpm > 0 ? ecpm / campaignsInsights.length : ecpm;
  };

  interface DataType {
    key: React.Key;
    name: string;
    loads: number;
    impressions: number;
    seen: string;
    sales: number;
    commision: string;
    ecpm: string;
    transactionsWebPendingCount: number;
    transactionsWebApprovedCount: number;
    transactionsAutoEmailPendingCount: number;
    transactionsAutoEmailApprovedCount: number;
    transactionsManualEmailPendingCount: number;
    transactionsManualEmailApprovedCount: number;
    transactionsSharePendingCount: number;
    transactionsShareApprovedCount: number;
    totalWebPendingAmount: number;
    totalWebApprovedAmount: number;
    totalAutoEmailApprovedAmount: number;
    totalAutoEmailPendingAmount: number;
    totalManualEmailPendingAmount: number;
    totalManualEmailApprovedAmount: number;
    totalSharePendingAmount: number;
    totalShareApprovedAmount: number;
  }

  const columns: ColumnsType<DataType> = [
    {
      title: "Campaign",
      dataIndex: "name",
      sorter: (a: DataType, b: DataType) => a.name.localeCompare(b.name),
      fixed: "left",
      width: 170,
    },
    {
      title: "Loads",
      dataIndex: "loads",
      sorter: (a: DataType, b: DataType) => a.loads - b.loads,
      align: "center",
      width: 150,
      render: (loads: number) => formatFixedNumber(loads),
    },
    {
      title: "Impressions",
      dataIndex: "impressions",
      sorter: (a: DataType, b: DataType) => a.impressions - b.impressions,
      align: "center",
      width: 150,
      render: (impressions: number) => formatFixedNumber(impressions),
    },
    {
      title: "Seen %",
      dataIndex: "seen",
      sorter: (a: DataType, b: DataType) =>
        stripAndReturnNumber(a.seen) - stripAndReturnNumber(b.seen),
      align: "center",
      width: 150,
      render: (seen: number) => formatFixedNumber(seen),
    },
    {
      title: "Sales",
      dataIndex: "sales",
      sorter: (a: DataType, b: DataType) => a.sales - b.sales,
      align: "center",
      width: 120,
      render: (sales: number, record: DataType) => (
        <span>
          {formatFixedNumber(sales)}
          {sales > 0 && <SalesTooltip record={record} />}
        </span>
      ),
    },
    {
      title: "Commission",
      dataIndex: "commision",
      sorter: (a: DataType, b: DataType) =>
        stripAndReturnNumber(a.commision) - stripAndReturnNumber(b.commision),
      align: "center",
      width: 150,
      render: (commision: string, record: DataType) => (
        <span>
          {formatNumber(+commision.slice(1))}
          <span>
            {commision !== "£0.00" && <CommissionTooltip record={record} />}
          </span>
        </span>
      ),
    },
    {
      title: "ECPM",
      dataIndex: "ecpm",
      sorter: (a: DataType, b: DataType) =>
        stripAndReturnNumber(a.ecpm) - stripAndReturnNumber(b.ecpm),
      align: "center",
      width: 120,
      render: (ecpm: string) => <span>{formatNumber(+ecpm.slice(1))}</span>,
    },
  ];

  const data = campaignsInsights?.map(
    (data: InsightsCampaignsSummaryInterface, index: number) => {
      return {
        key: index.toString(),
        name: data.campaign?.oname,
        loads: data.distinctOpen,
        impressions:
          data.distinctSeen > 0 ? data.distinctSeen : data.distinctOpen,
        seen:
          data.distinctSeen > 0
            ? ((data.distinctSeen / data.distinctOpen) * 100).toFixed(2) + "%"
            : "100%",
        sales: data.totalSalesCount,
        commision: `${
          data?.campaign?.currencySymbol
        }${data.totalCommission.toFixed(2)}`,
        ecpm: `${data?.campaign?.currencySymbol}${
          checkForZero(data.distinctOpen, data.totalCommission)
            ? (
                (data.totalCommission /
                  (data.distinctSeen === 0
                    ? data.distinctOpen
                    : data.distinctSeen)) *
                1000
              ).toFixed(2)
            : "0.00"
        }`,
        transactionsWebPendingCount: data.transactionsWebPendingCount,
        transactionsWebApprovedCount: data.transactionsWebApprovedCount,
        transactionsAutoEmailPendingCount:
          data.transactionsAutoEmailPendingCount,
        transactionsAutoEmailApprovedCount:
          data.transactionsAutoEmailApprovedCount,
        transactionsManualEmailPendingCount:
          data.transactionsManualEmailPendingCount,
        transactionsManualEmailApprovedCount:
          data.transactionsManualEmailApprovedCount,
        transactionsSharePendingCount: data.transactionsSharePendingCount,
        transactionsShareApprovedCount: data.transactionsShareApprovedCount,
        totalWebPendingAmount: data.totalWebPendingAmount,
        totalWebApprovedAmount: data.totalWebApprovedAmount,
        totalAutoEmailApprovedAmount: data.totalAutoEmailApprovedAmount,
        totalAutoEmailPendingAmount: data.totalAutoEmailPendingAmount,
        totalManualEmailPendingAmount: data.totalManualEmailPendingAmount,
        totalManualEmailApprovedAmount: data.totalManualEmailApprovedAmount,
        totalSharePendingAmount: data.totalSharePendingAmount,
        totalShareApprovedAmount: data.totalShareApprovedAmount,
      };
    }
  );

  return (
    <>
      <div className="widget-description"></div>

      <div className="widget-table">
        <div className="mbot50">
          <Table
            columns={columns}
            dataSource={data}
            pagination={false}
            tableLayout="fixed"
            scroll={{ x: undefined, y: 380 }}
            loading={{
              indicator: <LoadingOutlined></LoadingOutlined>,
              spinning: loading,
            }}
            summary={() => (
              <Table.Summary fixed="bottom">
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>
                    <span className="bold">Total</span>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1} align="center">
                    <span className="bold">
                      {formatFixedNumber(loadsSum())}
                    </span>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2} align="center">
                    <span className="bold">
                      {formatFixedNumber(impressionsSum())}
                    </span>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={3} align="center">
                    <span className="bold">
                      {((impressionsSum() / loadsSum()) * 100).toFixed(2)}%
                    </span>

                    <div className="font-xsmall">
                      {formatFixedNumber(seenSum())}% avg
                    </div>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={2} align="center">
                    <span className="bold">
                      {formatFixedNumber(salesSum())}
                    </span>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={3} align="center">
                    <div className="bold">
                      {formatNumber(
                        commisionSum(),
                        true,
                        campaignsInsights?.[0].campaign?.currencySymbol
                      )}
                    </div>
                    <div className="font-xsmall">
                      {formatNumber(
                        commisionAvg(),
                        true,
                        campaignsInsights?.[0].campaign?.currencySymbol
                      )}{" "}
                      avg
                    </div>
                  </Table.Summary.Cell>

                  <Table.Summary.Cell index={4} align="center">
                    {/* ECPM */}
                    <div className="bold">
                      {formatNumber(
                        +(checkForZero(commisionSum(), impressionsSum())
                          ? (
                              (commisionSum() / impressionsSum()) *
                              1000
                            ).toFixed(2)
                          : "0.00"),
                        true,
                        campaignsInsights?.[0].campaign?.currencySymbol
                      )}
                    </div>
                    <div className="font-xsmall">
                      {formatNumber(
                        ecpmAvg(),
                        true,
                        campaignsInsights?.[0].campaign?.currencySymbol
                      )}{" "}
                      avg
                    </div>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            )}
          />
        </div>
      </div>

      <div className="widget-footer"></div>
    </>
  );
};

export default CampaignDailySummary;
