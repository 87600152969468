import { Empty, message } from "antd";
import { useState, useEffect } from "react";
import useAxios from "../../../../../hooks/useAxios.hook";
import { PerformanceByDeviceType } from "../../../../../types/graphs.interfaces";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { LoadingOutlined } from "@ant-design/icons";
import { formatFixedNumber } from "../../../../../utility/numbers/numbers";

interface Props {
  companyId?: string;
  campaignId?: string;
  offerId?: string;
  filters?: {
    dateFilterType?: string;
    fromDate?: string;
    endDate?: string;
    placementIds?: string[];
  };
  salesNumber: number;
  filterTrigger?: number;
}

const PerformanceByDeviceTypeDonut = ({
  companyId,
  campaignId,
  offerId,
  filters,
  salesNumber,
  filterTrigger,
}: Props) => {
  const { myAxios } = useAxios();
  const [loading, setLoading] = useState<boolean>(false);
  const [performanceByDeviceType, setPerformanceByDeviceType] =
    useState<PerformanceByDeviceType[]>();

  useEffect(() => {
    if (!salesNumber) {
      return;
    }
    if (!campaignId) {
      fetchPerformanceByDeviceDataAdvanced();
      return;
    }
    fetchPerformanceByDeviceData();
    // eslint-disable-next-line
  }, [filterTrigger]);

  const fetchPerformanceByDeviceData = async () => {
    setLoading(true);
    const { response } = await myAxios({
      method: "post",
      url: `/insights/offer/devicetype/${companyId}/${campaignId}/${offerId}`,
      data: filters,
    });

    if (response?.data?.status) {
      setPerformanceByDeviceType(response.data.result.data);
    } else {
      message.error("Failed to get partners offers", 1);
    }
    setLoading(false);
  };

  const fetchPerformanceByDeviceDataAdvanced = async () => {
    setLoading(true);
    const { response } = await myAxios({
      method: "post",
      url: `/insights/transactions/devicetype/${companyId}`,
      data: {
        ...filters,
        offerId: offerId || "",
      },
    });

    if (response?.data?.status) {
      setPerformanceByDeviceType(response.data.result.data);
    } else {
      message.error("Failed to get partners offers", 1);
    }
    setLoading(false);
  };

  const constructData = () => {
    return performanceByDeviceType?.map((item: PerformanceByDeviceType) => {
      return {
        name: item.deviceType,
        y: item ? item.count : 0,
      };
    });
  };

  const calculateTotalCount = (): number => {
    if (!performanceByDeviceType) {
      return 0;
    }
    return performanceByDeviceType?.reduce(
      (total, item) => total + item.count,
      0
    );
  };

  return (
    <div className="flex-50">
      <div>
        <h1 className="title">Overall performance by device</h1>
      </div>
      {loading ? (
        <div className="loading-state loading-state--250">
          <LoadingOutlined />
        </div>
      ) : !salesNumber ? (
        <div className="no-orders">
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No data" />
        </div>
      ) : !performanceByDeviceType?.length ? (
        <div className="no-orders">
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No data" />
        </div>
      ) : (
        <div className="donut-chart mtop10">
          <HighchartsReact
            highcharts={Highcharts}
            options={{
              chart: {
                height: 250,
              },
              title: {
                text: "",
                align: "center",
              },
              subtitle: {
                useHTML: true,
                text: ``,
                floating: true,
                verticalAlign: "middle",
              },
              legend: {
                enabled: true,
                align: "right",
                layout: "vertical",
                margin: 0,
                verticalAlign: "middle",
                itemMarginBottom: 20,
              },
              plotOptions: {
                series: {
                  borderWidth: 0,
                  colorByPoint: true,
                  type: "pie",
                  size: "100%",
                  innerSize: "85%",
                  dataLabels: {
                    enabled: true,
                    crop: false,
                    distance: "-5%",
                    format: "",
                    style: {
                      fontWeight: "bold",
                      fontSize: "16px",
                    },
                    connectorWidth: 0,
                  },
                  showInLegend: true,
                },
              },
              colors: [
                "#A0E7D0",
                "#3AB78F",
                "#161616",
                "#93DFC4",
                "#86D8B9",
                "#79D0AD",
                "#6CC9A2",
                "#2F956D",
                "#25634B",
                "#1B322A",
              ],
              series: [
                {
                  type: "pie",
                  name: "",
                  data: constructData(),
                },
              ],
            }}
          />
          <div className="total-number">
            <div className="total-content">
              <h1 className="number">
                {formatFixedNumber(calculateTotalCount())}
              </h1>
              <p className="text">Orders</p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PerformanceByDeviceTypeDonut;
