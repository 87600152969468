import { LoadingOutlined } from "@ant-design/icons";
import { Empty } from "antd";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { DailySalesChartSummaryInterface } from "../../../../types/events.interfaces";

interface Props {
  loading: boolean;
  campaignsDailySales: DailySalesChartSummaryInterface[];
}

const DailySalesChartSummary = ({ loading, campaignsDailySales }: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [options, setOptions] = useState<Highcharts.Options>();

  const getChartData = (
    campaignsDailySales: DailySalesChartSummaryInterface[]
  ) => {
    if (campaignsDailySales && campaignsDailySales.length > 0) {
      const _dates: string[] = campaignsDailySales.map((value) =>
        moment(value.date.oname).format("DD-MM-YYYY")
      );

      const _sales: number[] = campaignsDailySales.map(
        (value) => value.totalSalesCount
      );

      const _commission: number[] = campaignsDailySales.map(
        (value) => value.totalCommission
      );

      const _currency: string = campaignsDailySales?.[0].date.currencySymbol;

      const _chartOptions: Highcharts.Options = {
        title: {
          text: "",
        },
        subtitle: {
          text: "",
        },
        xAxis: [
          {
            categories: _dates,
            crosshair: true,
          },
        ],
        chart: {
          height: containerRef?.current?.clientHeight,
          width: containerRef?.current?.clientWidth,
        },
        yAxis: [
          {
            // Primary yAxis
            title: {
              text: "Sales",
              style: {
                color: "#3ab78f",
              },
            },
            labels: {
              format: "{value}",
              style: {
                color: "#3ab78f",
              },
            },
            opposite: false,
          },
          {
            // Secondary yAxis
            title: {
              text: "Commission",
              style: {
                color: "#7cb5ec",
              },
            },
            labels: {
              format: "{value}",
              style: {
                color: "#7cb5ec",
              },
            },
            alignTicks: true,
            opposite: true,
          },
        ],
        tooltip: {
          shared: true,
        },
        legend: {
          align: "center",
          verticalAlign: "top",
          backgroundColor: "rgba(255,255,255,0.25)",
        },
        series: [
          {
            name: "Sales",
            type: "column",
            data: _sales,
            color: "#3ab78f",
          },
          {
            name: "Commission",
            type: "spline",
            data: _commission,
            yAxis: 1,
            color: "#7cb5ec",
            tooltip: {
              valuePrefix: ` ${_currency}`,
            },
          },
        ],
        credits: {
          enabled: false,
        },
      };

      setOptions(_chartOptions);
    }
  };

  useEffect(() => {
    campaignsDailySales && getChartData(campaignsDailySales);
  }, [campaignsDailySales]);

  return (
    <>
      <div className="widget-description"></div>
      {campaignsDailySales && campaignsDailySales.length > 0 ? (
        <div ref={containerRef} className="widget-content">
          {options && (
            <div
              id="chart-container"
              className={`chart-container ${
                loading ? "chart-container-loading" : ""
              }`}
            >
              <HighchartsReact highcharts={Highcharts} options={options} />

              {loading && <LoadingOutlined />}
            </div>
          )}
        </div>
      ) : (
        <>
          {loading ? <LoadingOutlined /> : <Empty className="mtop20 mbot20" />}
        </>
      )}
      <div className="widget-footer"></div>
    </>
  );
};

export default DailySalesChartSummary;
