import { Drawer } from "antd";
import "./OfferAdvancedInsights.scss";
import OrdersWeekHeatmap from "./graphs/OrdersWeekHeatmap";
import { PlacementStageInsight } from "../../../../types/templates.interfaces";
import SalesFunnelData from "./graphs/SalesFunnelData";
import PerformanceByDeviceTypeDonut from "./graphs/PerformanceByDeviceTypeDonut";
import TopCategoriesBarRace from "./graphs/TopCategoriesBarRace";

interface Props {
  open: boolean;
  onClose: () => void;
  companyId?: string;
  campaignId?: string;
  offerId: string;
  filters?: {
    dateFilterType?: string;
    fromDate?: string;
    endDate?: string;
    placementIds?: string[];
  };
  placementType?: PlacementStageInsight;
  salesNumber: number;
  CTR: number;
  CR: number;
}

const OfferAdvancedInsights = ({
  open,
  onClose,
  companyId,
  campaignId,
  offerId,
  filters,
  placementType,
  salesNumber,
  CTR,
  CR,
}: Props) => {
  return (
    <Drawer
      title="Advanced insights"
      placement="right"
      onClose={onClose}
      open={open}
      width="70vw"
    >
      <div className="insights-drawer">
        <div className="insights-drawer-item">
          <SalesFunnelData
            companyId={companyId}
            campaignId={campaignId}
            offerId={offerId}
            filters={filters}
            placementType={placementType}
            salesNumber={salesNumber}
            CTR={CTR}
            CR={CR}
          />
        </div>
        <div className="insights-drawer-item">
          <OrdersWeekHeatmap
            companyId={companyId}
            campaignId={campaignId}
            offerId={offerId}
            filters={filters}
            salesNumber={salesNumber}
          />
        </div>
        <div className="insights-drawer-item flex-space-between">
          <PerformanceByDeviceTypeDonut
            companyId={companyId}
            campaignId={campaignId}
            offerId={offerId}
            filters={filters}
            salesNumber={salesNumber}
          />
          <TopCategoriesBarRace
            companyId={companyId}
            campaignId={campaignId}
            offerId={offerId}
            filters={filters}
            salesNumber={salesNumber}
          />
        </div>
      </div>
    </Drawer>
  );
};

export default OfferAdvancedInsights;
