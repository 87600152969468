import "../css/express.scss";
import { LoadingOutlined } from "@ant-design/icons";
import useAuth from "../../../hooks/useAuth.hook";
import useExpress from "../../../hooks/useExpress.hook";
import ExpressOnboarding from "../onboarding/ExpressOnboarding";
import { Navigate } from "react-router-dom";

const ExpressUnAuth = () => {
  const { auth } = useAuth();
  const { campaign, company, onboardingStatus, loading } = useExpress();

  return (
    <>
      {auth && auth.onboardingStatus.completed ? (
        <>
          <Navigate to="/" />
        </>
      ) : (
        <>
          <div className="express">
            {loading ? (
              <>
                <div className="flex-center">
                  <LoadingOutlined />
                </div>
              </>
            ) : (
              <>
                {campaign && company && onboardingStatus ? (
                  <>
                    <ExpressOnboarding />
                  </>
                ) : (
                  <div className="flex-center"></div>
                )}
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default ExpressUnAuth;
