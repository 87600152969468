// Utility function to format number
export const formatNumber = (
  num: number,
  isCurrency = true,
  currencySymbol = "£"
) => {
  // Ensure the number always has 2 decimal places and use comma as thousand separator
  const formattedNumber = num.toLocaleString("en-GB", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  // If it's currency, prepend the currency symbol
  return isCurrency ? `${currencySymbol}${formattedNumber}` : formattedNumber;
};

export const formatFixedNumber = (num: number) => {
  // Ensure the number always has 2 decimal places and use comma as thousand separator
  const formattedNumber = num.toLocaleString("en-GB", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  });

  // If it's currency, prepend the currency symbol
  return formattedNumber;
};
