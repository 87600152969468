import { useState, useEffect, useMemo } from "react";
import usePage from "../../hooks/usePage.hook";
// import Footer from "../footer/footer.component";
import { PaymentsType } from "../../types/payments.interfaces";
// import Invoices from "./Invoices/Invoices";
import PaymentsDetails from "./PaymentsDetails/PaymentsDetails";

const Payments = () => {
  const { setPage } = usePage();
  const [customizationsType, setCustomizationsType] = useState<
    PaymentsType | PaymentsType.PAYMENTS
  >(PaymentsType.PAYMENTS);

  useEffect(() => {
    setPage({
      title: <>Payments</>,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getCurrentComponent = useMemo((): JSX.Element => {
    let jsxElm = <></>;
    switch (customizationsType) {
      case PaymentsType.PAYMENTS:
        jsxElm = <PaymentsDetails />;
        break;
      // case PaymentsType.INVOICES:
      //   jsxElm = (
      //     <div className="content-layout">
      //       <Invoices />
      //     </div>
      //   );
      //   break;
      default:
        break;
    }
    return jsxElm;
  }, [customizationsType]);

  return (
    <div className="content">
      <div className="content-header">
        <ul className="content-list">
          <li
            className={
              customizationsType === PaymentsType.PAYMENTS ? "active" : ""
            }
            onClick={() => setCustomizationsType(PaymentsType.PAYMENTS)}
          >
            Monthly
          </li>
          {/* <li
            className={
              customizationsType === PaymentsType.INVOICES ? "active" : ""
            }
            onClick={() => setCustomizationsType(PaymentsType.INVOICES)}
          >
            {PaymentsType.INVOICES}
          </li> */}
        </ul>
      </div>
      {getCurrentComponent}
    </div>
  );
};

export default Payments;
