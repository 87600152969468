import { OfferCreate } from "../../types/offer.interfaces";

export enum VoucherType {
  none = "",
  single = "single",
  custom = "custom"
}

export enum RedeemBy {
  webemail = "webemail",
  email = "email",
}

export enum DelayType {
  none = "none",
  minutes = "minutes",
  hours = "hours",
  days = "days",
  months = "months",
}

export enum UseFrom {
  none = "none",
  campaign = "campaign",
  offer = "offer",
}

export enum MediaType {
  main = "main",
  email = "email",
  featured = "featured"
}

export const emptyOffer: OfferCreate = {
  title: "",
  description: "",
  terms: "",
  imageUrl: "",
  startDate: null,
  endDate: null,
  destinationUrl: "",
  longDescription: "",
  faqs: "",
  offerCTAText: "GET OFFERS NOW",
  exclusiveTo: {
    categories: [],
    regions: [],
    retailers: [],
  },
  categories: [],
  voucher: {
    code: '',
    voucherType: VoucherType.none
  },
  media: {
    featured: {
      featuredVideoURL: "",
      images: [],
      title: "",
      description: "",
    },
    mainImage: [],
    videoURL: "",
    resolution: []
  },
  advertiser: {
    company_Id: "",
    affNetwork_Id: "",
    emailTemplateId: "",
  },
  isExternal: false,
  externalNetwork: {
    promotionId: 0,
  },
  brandswopExclusive: false,
  imagesArrayUrl: [],
  offerHostDetails: [],
  cpa: {
    value: "0",
    valueType: "percentage",
  },
  offerSettings: {
    allowShare: false,
    allowStacked: false,
    redeemBy: RedeemBy.webemail,
    delaySetting: {
      delayType: DelayType.none,
      delayValue: "0",
      emailTemplateId: "",
    },
    isB2C: true,
  },
  offerBadge: null,
};

export enum CONDITION_OPERATORS_TEXT {
  in = "In",
  notIn = "Not In",
  equal = "Equal",
  notEqual = "Not Equal",
  contains = "Contains",
  notContains = "Not Contains",
}

export enum CONDITION_OPERATORS_AMOUNT {
  equal = "Equal",
  notEqual = "Not Equal",
  greaterThan = "Greater Than",
  greaterThanorEqual = "Greater Than Or Equal",
  lessThan = "Less Than",
  lessThanorEqual = "Less Than Or Equal",
}

export enum CONDITION_SEARCH_TYPE {
  text = "Text",
  amount = "Amount",
}

export enum CONDITION_SEARCH_LOCATION {
  none = "None",
  pageTitle = "Page Title",
  other = "Other",
}

export enum CONDITION_LOGICAL_OPERATORS {
  and = "and",
  or = "or",
}

export enum OFFER_TYPES {
  all = "all",
  host = "host",
  advertiser = "advertiser",
  bundle = "bundle",
}

export const conditionOperatorsAmountToLabel = (
  condition: string
): CONDITION_OPERATORS_AMOUNT => {
  let result = CONDITION_OPERATORS_AMOUNT.equal;
  Object.entries(CONDITION_OPERATORS_AMOUNT).forEach(([key, value]) => {
    if (condition.toLowerCase() === key.toLowerCase()) {
      result = value;
    }
  });
  return result;
};

export const conditionOperatorsTextToLabel = (
  condition: string
): CONDITION_OPERATORS_TEXT => {
  let result = CONDITION_OPERATORS_TEXT.equal;
  Object.entries(CONDITION_OPERATORS_TEXT).forEach(([key, value]) => {
    if (condition.toLowerCase() === key.toLowerCase()) {
      result = value;
    }
  });
  return result;
};

export const conditionLogicalOperatorToLabel = (
  condition: string
): CONDITION_LOGICAL_OPERATORS => {
  let result = CONDITION_LOGICAL_OPERATORS.and;
  Object.entries(CONDITION_LOGICAL_OPERATORS).forEach(([key, value]) => {
    if (condition.toLowerCase() === key.toLowerCase()) {
      result = value;
    }
  });
  return result;
};

export const conditionSearchTypeToLabel = (
  condition: string
): CONDITION_SEARCH_TYPE => {
  let result = CONDITION_SEARCH_TYPE.text;
  Object.entries(CONDITION_SEARCH_TYPE).forEach(([key, value]) => {
    if (condition.toLowerCase() === key.toLowerCase()) {
      result = value;
    }
  });
  return result;
};

export const conditionSearchLocationToLabel = (condition: string) => {
  let result = CONDITION_SEARCH_LOCATION.none;
  Object.entries(CONDITION_SEARCH_LOCATION).forEach(([key, value]) => {
    if (condition.toLowerCase() === key.toLowerCase()) {
      result = value;
    }
  });
  return result;
};

// export const itemsOfferOperators: BaseOptionType[] = [
//     {
//         label: <span>{CONDITION_OPERATORS.in}</span>,
//         value: CONDITION_OPERATORS.in
//     },
//     {
//         label: <span>{CONDITION_OPERATORS.notIn}</span>,
//         value: CONDITION_OPERATORS.notIn
//     },
//     {
//         label: <span>{CONDITION_OPERATORS.equal}</span>,
//         value: CONDITION_OPERATORS.equal
//     },
//     {
//         label: <span>{CONDITION_OPERATORS.notEqual}</span>,
//         value: CONDITION_OPERATORS.notEqual
//     },
//     {
//         label: <span>{CONDITION_OPERATORS.contains}</span>,
//         value: CONDITION_OPERATORS.contains
//     },
//     {
//         label: <span>{CONDITION_OPERATORS.notContains}</span>,
//         value: CONDITION_OPERATORS.notContains
//     },
//     {
//         label: <span>{CONDITION_OPERATORS.greaterThan}</span>,
//         value: CONDITION_OPERATORS.greaterThan
//     },
//     {
//         label: <span>{CONDITION_OPERATORS.greaterThanorEqual}</span>,
//         value: CONDITION_OPERATORS.greaterThanorEqual
//     },

//     {
//         label: <span>{CONDITION_OPERATORS.lessThan}</span>,
//         value: CONDITION_OPERATORS.lessThan
//     },
//     {
//         label: <span>{CONDITION_OPERATORS.lessThanorEqual}</span>,
//         value: CONDITION_OPERATORS.lessThanorEqual
//     },
// ];

// export const itemsOfferSearchType: BaseOptionType[] = [
//     {
//         label: <span>{CONDITION_SEARCH_TYPE.amount}</span>,
//         value: CONDITION_SEARCH_TYPE.amount
//     },
//     {
//         label: <span>{CONDITION_SEARCH_TYPE.text}</span>,
//         value: CONDITION_SEARCH_TYPE.text
//     },
// ];

// export const itemsOfferSearchLocation: BaseOptionType[] = [
//     {
//         label: <span>{CONDITION_SEARCH_LOCATION.none}</span>,
//         value: CONDITION_SEARCH_LOCATION.none
//     },
//     {
//         label: <span>{CONDITION_SEARCH_LOCATION.pageTitle}</span>,
//         value: CONDITION_SEARCH_LOCATION.pageTitle
//     },
//     {
//         label: <span>{CONDITION_SEARCH_LOCATION.other}</span>,
//         value: CONDITION_SEARCH_LOCATION.other
//     },
// ];

// export const itemsOfferLogicalOperators: BaseOptionType[] = [
//     {
//         label: <span>{CONDITION_LOGICAL_OPERATORS.and}</span>,
//         value: CONDITION_LOGICAL_OPERATORS.and
//     },
//     {
//         label: <span>{CONDITION_LOGICAL_OPERATORS.or}</span>,
//         value: CONDITION_LOGICAL_OPERATORS.or
//     },
// ];
