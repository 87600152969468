import { Navigate, Outlet, useLocation } from "react-router-dom";
import { PLATFORM_VIEW_MODE } from "../components/express/types/express.enum";
import useAuth from "../hooks/useAuth.hook";

const AuthGuard = () => {
  const { auth, company } = useAuth();
  const location = useLocation();

  return (
    <>
      {auth ? (
        auth?.onboardingStatus?.completed ? (
          // <>
          //   {company?.platformViewMode === PLATFORM_VIEW_MODE.express &&
          //   !auth.isSuperAdmin ? (
          //     <>
          //       {/* <Navigate to={`/express/dashboard`} replace /> */}
          //       <Navigate to={`/`} replace />
          //     </>
          //   ) : (
          //     <>
          //       <Outlet />
          //     </>
          //   )}
          // </>
          <>
            <Outlet />
          </>
        ) : (
          <>
            <Navigate
              to={`${
                company?.platformViewMode !== PLATFORM_VIEW_MODE.express
                  ? "/register"
                  : "/login"
              }`}
              replace
            />
          </>
        )
      ) : (
        <Navigate to="/login" state={{ from: location }} replace />
      )}
    </>
  );
};

export default AuthGuard;
