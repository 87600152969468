import { Dispatch, SetStateAction } from "react";
import {
  CampaignGeneralOptions,
  DealLayoutSettings,
  FiltersSettings,
  LayoutSettings,
  MainLayoutSettings,
} from "../../../types/campaign.interfaces";
import { Country } from "../../../types/global.interfaces";
import { Offer } from "../../../types/offer.interfaces";
import { COMPANY_TYPES } from "../../../utility/enums/user.enums";
import { CAMPAIGNSTEPS, PLACEMENT_IDENTIFIER } from "../const/campaigns.enum";
import { ExpressPage, LayoutDevices } from "../const/views";
import {
  BANK_ACCUNT_STATUS,
  EXPRESS_ONBOARDING,
  PLATFORM_VIEW_MODE,
} from "./express.enum";
import {
  ButtonShapes,
  ImageRepresentation,
  LocationPosition,
  ElementSettings,
  TextTransform,
  TextAlign,
  ImageSizes,
  DisplayBy,
} from "../../templates-previews/types/params.interfaces";
import { LayoutClass } from "../../campaigns/const/views";
import {
  ImageSize,
  PlacementStage,
  LayoutType,
} from "../../../types/templates.interfaces";

export interface ExpressCampaign {
  autoOffers: boolean;
  completionStatus: { status: boolean; next: CAMPAIGNSTEPS };
  description: string;
  id: string;
  name: string;
  offers: Offer[];
  isActive: boolean;
  placementSettings: ExpressPlacementSettings[];
  generalOptions: CampaignGeneralOptions;
}

export interface ExpressCompany {
  categories: ExpressCategoryItem[];
  countries: Country[];
  email: string;
  excludedCategories: ExpressCategoryItem[];
  exclusiveAdvertisers: ExpressCompanyItem[];
  exclusiveCategories: ExpressCategoryItem[];
  id: string;
  name: string;
  url: string;
  pagePlacements: ExpressPlacement[];
  timezoneId: string;
  platform: string;
  registrationCountry: {
    currencySymbol: string;
    id: string;
    isoCode: string;
    name: string;
  };
  payments: {
    accountId: string;
    completionStatus: BANK_ACCUNT_STATUS;
    origin: string;
    refreshURL: string;
  };
}

export interface ExpressCategoryItem {
  categoryId: string;
  name: string;
}

export interface ExpressCompanyItem {
  companyId: string;
  name: string;
}

export interface ExpressPlacement {
  id: string;
  name: string;
  url: string;
  identifier: PLACEMENT_IDENTIFIER;
  pageType_Id: string;
}

export interface ExpressOnboardingStatus {
  completed: boolean;
  next: EXPRESS_ONBOARDING;
}

export interface ExpressCompanyType {
  id: string;
  name: string;
  companyType: COMPANY_TYPES;
}

export interface ExpressPlacementSettings {
  templateSettings: LayoutSettings;
  id: string;
  hasExitView: boolean;
  displayOnly: boolean;
  page?: string;
  promptMessage?: string;
  name: string;
  isEnabled: boolean;
}

export interface ExpressOnboardingContextType {
  campaign?: ExpressCampaign;
  setCampaign: Dispatch<SetStateAction<ExpressCampaign | undefined>>;
  company?: ExpressCompany;
  setCompany: Dispatch<SetStateAction<ExpressCompany | undefined>>;
  password?: string;
  setPassword: Dispatch<SetStateAction<string | undefined>>;
  onboardingStatus?: ExpressOnboardingStatus;
  setOnboardingStatus: Dispatch<
    SetStateAction<ExpressOnboardingStatus | undefined>
  >;
  updateOnboardingExpress: () => void;
  updateExpress: () => void;
  expressPage: ExpressPage;
  setExpressPage: Dispatch<SetStateAction<ExpressPage>>;
  refreshState: () => void;
  userEmail: string;
  loading: boolean;
  getUserById: (id: string) => void;
}

export interface ExpressOnboardingRequest {
  campaign: ExpressCampaign;
  company: ExpressCompany;
  onboardingStatus: ExpressOnboardingStatus;
  password?: string;
}

export interface ExpressBankAccountResponse {
  accountUrl: string | null;
  refreshUrl: string;
  returnUrl: string | null;
  status: BANK_ACCUNT_STATUS;
}

export interface ExpressCommisionResponse {
  _id: string;
  totalAmountPending: number;
  totalAmountApproved: number;
  transactionsPendingCount: number;
  transactionsApprovedCount: number;
  advertiser: string;
  currency: string;
}

export interface Advertiser {
  companyType: COMPANY_TYPES;
  id: string;
  logoUrl: string;
  name: string;
  platformViewMode: PLATFORM_VIEW_MODE;
}

export const initTemplateSettings: LayoutSettings = {
  desktop: {
    template: {
      isActive: true,
      name: "3 items",
      imageURL:
        "https://brswstorage.blob.core.windows.net/assets/images/template-images/template-post-n3.png",
      identifier: "template-dlp3post",
      deviceType: "desktop" as LayoutDevices,
      imageSize: "large" as ImageSize,
      stage: "post" as PlacementStage,
      layoutType: "popup" as LayoutType,
      offerCount: "3",
    },
    type: "action" as LayoutClass,
    button: {
      location: {
        location: "left" as LocationPosition,
        x: 0,
        y: 0,
      },
      text: "GET OFFERS",
      sizes: {
        type: "fixed",
        w: 150,
        h: 40,
        circle: 100,
      },
      shape: "roundingAll" as ButtonShapes,
      style: {
        backgroundColor: "#ffffff",
        borderTopLeftRadius: 12,
        borderTopRightRadius: 12,
        borderBottomLeftRadius: 12,
        borderBottomRightRadius: 12,
        circleSize: 100,
        textColor: "#000000",
        fontFamily: "Poppins",
        fontWeight: 0,
        fontSize: "14",
        textTransform: "none" as TextTransform,
      },
      number: {
        display: false,
        backgroundColor: "#ff0000",
        textColor: "#ffffff",
      },
    },
    popup: {
      minimized: false,
      horizontal: "Vertical",
      general: {
        backgroundColor: "#f2f2f2",
        offers: "3",
        align: "left" as TextAlign,
      },
      location: {
        location: "left" as LocationPosition,
        transition: 0,
      },
      header: {
        textColor: "#000000",
        fontFamily: "Poppins",
        fontWeight: 0,
        fontSize: "14",
        textTransform: "none" as TextTransform,
      },
      text: {
        textColor: "#000000",
        fontFamily: "Poppins",
        fontWeight: 0,
        fontSize: "14",
        textTransform: "none" as TextTransform,
      },
      image: {
        size: "medium" as ImageSizes,
        rounding: 5,
      },
      getButton: {
        backgroundColor: "#d1d1d1",
        textColor: "#000000",
        fontFamily: "Poppins",
        fontSize: "14",
        fontWeight: 0,
        rounding: 12,
      },
      imageRepresentation: {
        position: "left" as ImageRepresentation,
      },
    },
    inline: {
      minimized: false,
      element: {
        attribute: "",
        existingElement: true,
        elementType: "id",
        fullWidth: true,
        width: 300,
        height: 300,
        marginType: "%",
        margin: 0,
      } as ElementSettings,
      general: {
        backgroundColor: "#f2f2f2",
        offers: "3",
        align: "left" as TextAlign,
      },
      header: {
        textColor: "#000000",
        fontFamily: "Poppins",
        fontWeight: 0,
        fontSize: "14",
        textTransform: "none" as TextTransform,
      },
      text: {
        textColor: "#000000",
        fontFamily: "Poppins",
        fontWeight: 0,
        fontSize: "14",
        textTransform: "none" as TextTransform,
      },
      image: {
        size: "medium" as ImageSizes,
        rounding: 5,
      },
      getButton: {
        backgroundColor: "#d1d1d1",
        textColor: "#000000",
        fontFamily: "Poppins",
        fontSize: "14",
        fontWeight: 0,
        rounding: 12,
      },
      imageRepresentation: {
        position: "left" as ImageRepresentation,
      },
    },
    action: {
      minimized: false,
      horizontal: "Vertical",
      general: {
        backgroundColor: "#f2f2f2",
        offers: "3",
        align: "left" as TextAlign,
      },
      location: {
        location: "left" as LocationPosition,
        transition: 0,
      },
      behaviour: {
        displayBy: "time" as DisplayBy,
        afterTime: 0,
      },
      header: {
        textColor: "#000000",
        fontFamily: "Poppins",
        fontWeight: 0,
        fontSize: "14",
        textTransform: "none" as TextTransform,
      },
      text: {
        textColor: "#000000",
        fontFamily: "Poppins",
        fontWeight: 0,
        fontSize: "14",
        textTransform: "none" as TextTransform,
      },
      image: {
        size: "medium" as ImageSizes,
        rounding: 5,
      },
      getButton: {
        backgroundColor: "#d1d1d1",
        textColor: "#000000",
        fontFamily: "Poppins",
        fontSize: "14",
        fontWeight: 0,
        rounding: 12,
      },
      imageRepresentation: {
        position: "left" as ImageRepresentation,
      },
    },
    orderExtractor: {
      allowExtraction: false,
      productExtractorKeys: {
        htmlElement: "",
        productsSelector: "",
        productNameSelector: "",
        productPriceSelector: "",
        totalPriceSelector: "",
      },
    },
    settings: {
      location: {
        location: "center" as LocationPosition,
        transition: 0,
        x: 0,
        y: 0,
      },
      mainLayout: {
        fontFamily: "Poppins",
        backgroundColor: "#ffffff",
        textColor: "#000000",
        borderColor: "#f9f9f9",
        align: "right" as TextAlign,
        highlightColor: "#3ab78f",
        headerText: "Please find your rewards below",
        headerFontSize: "18",
        headerFontWeight: 0,
        headerTextColor: "#000000",
        subHeaderText: "Select now, receive after purchase",
        subHeaderFontSize: "16",
        subHeaderFontWeight: 0,
        subHeaderTextColor: "#666666",
      } as MainLayoutSettings,
      dealLayout: {
        backgroundColor: "#ffffff",
        textColor: "#000000",
        fontSize: "14",
        align: "center" as TextAlign,
        buttonBackgroundColor: "#aaaaaa",
        buttonBorderColor: "#d8d8d8",
        buttonTextColor: "#ffffff",
        buttonFontSize: "16",
        buttonFontWeight: 0,
        buttonRounding: 6,
        emailOpen: false,
        emailIconColor: "#000000",
        emailTextColor: "#000000",
        emailButtonBackgroundColor: "#000000",
        emailButtonTextColor: "#ffffff",
      } as DealLayoutSettings,
      delay: {
        displayBy: "time" as DisplayBy,
        afterTime: 0,
      },
      showMore: {
        backgroundColor: "#383838",
        textColor: "#ffffff",
        borderColor: "#383838",
        rounding: 6,
        fontWeight: 400,
        input: '',
      },
      embeddedElement: {
        attribute: "home-hero",
        existingElement: true,
        elementType: "class",
        fullWidth: true,
        width: 1540,
        height: 1000,
        marginType: "%",
        margin: 5,
      } as ElementSettings,
      filters: {
        backgroundColor: "#e5eef5",
        textColor: "#939393",
        borderColor: "#939393",
        activeColor: "#0053a0",
      } as FiltersSettings,
    },
  },
  mobile: {
    template: {
      isActive: true,
      name: "Compact view 3 items",
      imageURL:
        "https://brswstorage.blob.core.windows.net/assets/images/template-images/template-compact-post.png",
      identifier: "template-msp3post",
      deviceType: "mobile" as LayoutDevices,
      imageSize: "small" as ImageSize,
      stage: "post" as PlacementStage,
      layoutType: "popup" as LayoutType,
      offerCount: "3",
    },
    type: "action" as LayoutClass,
    button: {
      location: {
        location: "left" as LocationPosition,
        x: 0,
        y: 0,
      },
      text: "GET OFFERS",
      sizes: {
        type: "fixed",
        w: 150,
        h: 40,
        circle: 100,
      },
      shape: "roundingAll" as ButtonShapes,
      style: {
        backgroundColor: "#ffffff",
        borderTopLeftRadius: 12,
        borderTopRightRadius: 12,
        borderBottomLeftRadius: 12,
        borderBottomRightRadius: 12,
        circleSize: 100,
        textColor: "#000000",
        fontFamily: "Poppins",
        fontWeight: 0,
        fontSize: "14",
        textTransform: "none" as TextTransform,
      },
      number: {
        display: false,
        backgroundColor: "#ff0000",
        textColor: "#ffffff",
      },
    },
    popup: {
      minimized: true,
      horizontal: "Vertical",
      general: {
        backgroundColor: "#f2f2f2",
        offers: "3",
        align: "left" as TextAlign,
      },
      location: {
        location: "left" as LocationPosition,
        transition: 0,
      },
      header: {
        textColor: "#000000",
        fontFamily: "Poppins",
        fontWeight: 0,
        fontSize: "14",
        textTransform: "none" as TextTransform,
      },
      text: {
        textColor: "#000000",
        fontFamily: "Poppins",
        fontWeight: 0,
        fontSize: "14",
        textTransform: "none" as TextTransform,
      },
      image: {
        size: "xsmall" as ImageSizes,
        rounding: 5,
      },
      getButton: {
        backgroundColor: "#d1d1d1",
        textColor: "#000000",
        fontFamily: "Poppins",
        fontSize: "14",
        fontWeight: 0,
        rounding: 12,
      },
      imageRepresentation: {
        position: "left" as ImageRepresentation,
      },
    },
    inline: {
      minimized: false,
      element: {
        attribute: "",
        existingElement: true,
        elementType: "id",
        fullWidth: true,
        width: 300,
        height: 300,
        marginType: "%",
        margin: 0,
      } as ElementSettings,
      general: {
        backgroundColor: "#f2f2f2",
        offers: "3",
        align: "left" as TextAlign,
      },
      header: {
        textColor: "#000000",
        fontFamily: "Poppins",
        fontWeight: 0,
        fontSize: "14",
        textTransform: "none" as TextTransform,
      },
      text: {
        textColor: "#000000",
        fontFamily: "Poppins",
        fontWeight: 0,
        fontSize: "14",
        textTransform: "none" as TextTransform,
      },
      image: {
        size: "medium" as ImageSizes,
        rounding: 5,
      },
      getButton: {
        backgroundColor: "#d1d1d1",
        textColor: "#000000",
        fontFamily: "Poppins",
        fontSize: "14",
        fontWeight: 0,
        rounding: 12,
      },
      imageRepresentation: {
        position: "left" as ImageRepresentation,
      },
    },
    action: {
      minimized: true,
      horizontal: "Vertical",
      general: {
        backgroundColor: "#f2f2f2",
        offers: "3",
        align: "left" as TextAlign,
      },
      location: {
        location: "left" as LocationPosition,
        transition: 0,
      },
      behaviour: {
        displayBy: "time" as DisplayBy,
        afterTime: 0,
      },
      header: {
        textColor: "#000000",
        fontFamily: "Poppins",
        fontWeight: 0,
        fontSize: "14",
        textTransform: "none" as TextTransform,
      },
      text: {
        textColor: "#000000",
        fontFamily: "Poppins",
        fontWeight: 0,
        fontSize: "14",
        textTransform: "none" as TextTransform,
      },
      image: {
        size: "xsmall" as ImageSizes,
        rounding: 5,
      },
      getButton: {
        backgroundColor: "#d1d1d1",
        textColor: "#000000",
        fontFamily: "Poppins",
        fontSize: "14",
        fontWeight: 0,
        rounding: 12,
      },
      imageRepresentation: {
        position: "left" as ImageRepresentation,
      },
    },
    orderExtractor: {
      allowExtraction: false,
      productExtractorKeys: {
        htmlElement: "",
        productsSelector: "",
        productNameSelector: "",
        productPriceSelector: "",
        totalPriceSelector: "",
      },
    },
    settings: {
      location: {
        location: "center" as LocationPosition,
        transition: 0,
        x: 0,
        y: 0,
      },
      mainLayout: {
        fontFamily: "Poppins",
        backgroundColor: "#f9f9f9",
        textColor: "#000000",
        borderColor: "#f9f9f9",
        align: "left" as TextAlign,
        highlightColor: "#3ab78f",
        headerText: "Unlocked when you buy",
        headerFontSize: "16",
        headerFontWeight: 0,
        headerTextColor: "#000000",
        subHeaderText: "Select now, receive after purchase",
        subHeaderFontSize: "12",
        subHeaderFontWeight: 0,
        subHeaderTextColor: "#666666",
      } as MainLayoutSettings,
      dealLayout: {
        backgroundColor: "#ffffff",
        textColor: "#000000",
        fontSize: "14",
        align: "center" as TextAlign,
        buttonBackgroundColor: "#aaaaaa",
        buttonBorderColor: "#d8d8d8",
        buttonTextColor: "#f2f2f2",
        buttonFontSize: "14",
        buttonFontWeight: 0,
        buttonRounding: 6,
        emailOpen: false,
        emailIconColor: "#000000",
        emailTextColor: "#000000",
        emailButtonBackgroundColor: "#000000",
        emailButtonTextColor: "#ffffff",
      } as DealLayoutSettings,
      delay: {
        displayBy: "time" as DisplayBy,
        afterTime: 0,
      },
      showMore: {
        backgroundColor: "#383838",
        textColor: "#ffffff",
        borderColor: "#383838",
        rounding: 6,
        fontWeight: 400,
        input: '',
      },
      embeddedElement: {
        attribute: "home-hero",
        existingElement: true,
        elementType: "class",
        fullWidth: true,
        width: 300,
        height: 300,
        marginType: "%",
        margin: 0,
      } as ElementSettings,
      filters: {
        backgroundColor: "#e5eef5",
        textColor: "#939393",
        borderColor: "#939393",
        activeColor: "#0053a0",
      } as FiltersSettings,
    },
  },
};
