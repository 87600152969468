import { SelectOption } from "./global.interfaces";

export enum PaymentsType {
  PAYMENTS = "Payments",
  INVOICES = "Invoices",
}

export enum TransactionStatus {
  ALL = "All",
  PENDING = "Pending",
  APPROVED = "Approved",
  PENDING_AND_APPROVED = "Pending & Approved",
}

export enum PaymentStatus {
  ALL = "All",
  CLEARED = "Cleared",
  UNCLEARED = "Uncleared",
}

export interface FinancialRecord {
  monthNumber: number;
  monthName: string;
  year: number;
  amount: number;
  currencySymbol: string;
}

export interface MonthlyFinancialRecord {
  transactionId: string;
  computedCommission: number;
  advertiser: string;
  offer: string;
  transactionDate: string; // If this is an ISO date, you can use string, or Date if you'll convert it
  network: string;
  currencySymbol: string;
}

export const transactionStatusArray: SelectOption[] = [
  { label: "All", value: TransactionStatus.ALL },
  { label: "Pending", value: TransactionStatus.PENDING },
  { label: "Approved", value: TransactionStatus.APPROVED },
  {
    label: "Pending & Approved",
    value: TransactionStatus.PENDING_AND_APPROVED,
  },
];

export const paymentStatusArray: SelectOption[] = [
  { label: "All", value: PaymentStatus.ALL },
  { label: "Cleared", value: PaymentStatus.CLEARED },
  { label: "Uncleared", value: PaymentStatus.UNCLEARED },
];

export const startYear = 2023;
