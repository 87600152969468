import { DealLayoutSettings } from "../../../../../../../../types/campaign.interfaces";
import { Offer } from "../../../../../../../../types/offer.interfaces";
import { RedeemBy } from "../../../../../../../../utility/enums/offer.enums";
import { ExpressCampaign } from "../../../../../../types/express.types";
import offerImageSrc from "./../../../../../../../../assets/images/offer-listening.png";

interface Props {
  offers: Offer[];
  settings: DealLayoutSettings | undefined;
  displayOnly: boolean;
  campaign: ExpressCampaign;
}

const OfferItem = ({ offers, settings, displayOnly, campaign }: Props) => {
  return (
    <>
      <div className={`swiper-item ${displayOnly ? "display" : "getbutton"}`}>
        {offers.map((offer: Offer) => {
          return (
            <div
              key={`compact-${offer.id}`}
              className={`swiper-compact-item ${
                displayOnly ? "display" : "getbutton"
              }`}
            >
              <input
                className="input"
                id={offer.id}
                type="checkbox"
                disabled={!displayOnly}
              />
              <label
                htmlFor={offer.id}
                className="compact-item-layout"
                style={{
                  backgroundColor: settings?.backgroundColor,
                  fontSize: settings?.fontSize,
                  color: settings?.textColor,
                  border: offer.offerBadge?.useBorder
                    ? `1px solid ${offer.offerBadge?.backgroundColor}`
                    : "1px solid transparent",
                  position: "relative",
                }}
              >
                <span
                  style={{
                    position: "absolute",
                    top: "-8.5px",
                    left: "10px",
                    fontSize: "10px",
                    textTransform: "uppercase",
                    color: offer.offerBadge?.fontColor,
                    backgroundColor: offer.offerBadge?.backgroundColor,
                    padding: "0 6px",
                    borderRadius: "3px",
                    fontWeight: "600",
                  }}
                  className="badge-settings"
                >
                  {offer.offerBadge?.name}
                </span>
                <div className="compact-item-image">
                  <img
                    src={offer.imageUrl ? offer.imageUrl : offerImageSrc}
                    alt={offer.title}
                  />
                </div>

                <div className={`compact-item-content`}>
                  <div className="compact-item-title">
                    <span
                      className={`compact-item-title-text max-2lines `}
                      style={{
                        color: settings?.textColor,
                        fontSize: `${Number(settings?.fontSize) + 1}px`,
                      }}
                    >
                      {offer.title}
                    </span>

                    <div className="compact-item-icons">
                      <div
                        className="compact-item-info-icon"
                        onClick={(e) => e.preventDefault()}
                      >
                        <svg
                          width="15"
                          height="15"
                          viewBox="0 0 15 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.5 0C11.6421 0 15 3.35786 15 7.5C15 11.6421 11.6421 15 7.5 15C3.35786 15 0 11.6421 0 7.5C0 3.35786 3.35786 0 7.5 0ZM7.5 0.882353C3.84517 0.882353 0.882353 3.84517 0.882353 7.5C0.882353 11.1548 3.84517 14.1176 7.5 14.1176C11.1548 14.1176 14.1176 11.1548 14.1176 7.5C14.1176 3.84517 11.1548 0.882353 7.5 0.882353ZM8.02941 5.73529V11.9118H7.04118V5.73529H8.02941ZM7.53529 3.11647C7.94294 3.11647 8.28882 3.45 8.28882 3.85765C8.28882 4.26529 7.94294 4.61118 7.53529 4.61118C7.12765 4.61118 6.79412 4.26529 6.79412 3.85765C6.79412 3.45 7.12765 3.11647 7.53529 3.11647Z"
                            fill={settings?.textColor}
                          />
                        </svg>
                      </div>

                      {/* {!displayOnly &&
                        campaign.sharingOptions?.allowSharing &&
                        campaign.sharingOptions.sharingPlatforms?.platforms
                          ?.length > 0 &&
                        offer?.offerSettings?.allowShare && (
                          <div className="compact-item-share-icon">
                            <svg
                              viewBox="64 64 896 896"
                              focusable="false"
                              data-icon="share-alt"
                              width="1em"
                              height="1em"
                              fill={settings?.textColor}
                              aria-hidden="true"
                            >
                              <path d="M752 664c-28.5 0-54.8 10-75.4 26.7L469.4 540.8a160.68 160.68 0 000-57.6l207.2-149.9C697.2 350 723.5 360 752 360c66.2 0 120-53.8 120-120s-53.8-120-120-120-120 53.8-120 120c0 11.6 1.6 22.7 4.7 33.3L439.9 415.8C410.7 377.1 364.3 352 312 352c-88.4 0-160 71.6-160 160s71.6 160 160 160c52.3 0 98.7-25.1 127.9-63.8l196.8 142.5c-3.1 10.6-4.7 21.8-4.7 33.3 0 66.2 53.8 120 120 120s120-53.8 120-120-53.8-120-120-120zm0-476c28.7 0 52 23.3 52 52s-23.3 52-52 52-52-23.3-52-52 23.3-52 52-52zM312 600c-48.5 0-88-39.5-88-88s39.5-88 88-88 88 39.5 88 88-39.5 88-88 88zm440 236c-28.7 0-52-23.3-52-52s23.3-52 52-52 52 23.3 52 52-23.3 52-52 52z"></path>
                            </svg>
                          </div>
                        )} */}
                    </div>
                  </div>

                  <div className="compact-item-get">
                    <div className="compact-item-get-center">
                      {displayOnly ? (
                        <div className="switch-wrapper">
                          <label htmlFor={offer.id}>
                            <span className="switch-toggle">
                              <span className="switch-toggle-before"></span>
                            </span>

                            <span
                              className="switch-text"
                              style={{
                                fontWeight: settings?.buttonFontWeight,
                                fontSize: `${settings?.buttonFontSize}px`,
                              }}
                            >
                              <span className="checked-text">Selected</span>
                              <span className="unchecked-text">Select</span>
                            </span>
                          </label>
                        </div>
                      ) : (
                        <>
                          {offer.offerSettings.redeemBy === RedeemBy.email ? (
                            <div
                              className="compact-item-action-get"
                              style={{
                                backgroundColor:
                                  settings?.emailButtonBackgroundColor,
                                color: settings?.emailButtonTextColor,
                                marginRight: "0",
                                fontSize: "14px",
                                border: `1px solid transparent`,
                                borderRadius: `${settings?.buttonRounding}px`,
                              }}
                            >
                              <svg
                                style={{
                                  marginRight: "5px",
                                  minWidth: "14px",
                                  verticalAlign: "middle",
                                }}
                                width="14"
                                height="11"
                                viewBox="0 0 14 11"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M13 2.125C13 1.50625 12.46 1 11.8 1H2.2C1.54 1 1 1.50625 1 2.125M13 2.125V8.875C13 9.49375 12.46 10 11.8 10H2.2C1.54 10 1 9.49375 1 8.875V2.125M13 2.125L7 6.0625L1 2.125"
                                  stroke={settings?.emailButtonTextColor}
                                  strokeWidth="1"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                              {offer.offerCTAText}
                            </div>
                          ) : (
                            <div
                              className="compact-item-action-get button max-2lines"
                              style={{
                                color: settings?.buttonTextColor,
                                fontSize: `${settings?.buttonFontSize}px`,
                                fontWeight: settings?.buttonFontWeight,
                                backgroundColor:
                                  settings?.buttonBackgroundColor,
                                borderRadius: `${settings?.buttonRounding}px`,
                                border: `1px solid ${
                                  settings?.buttonBorderColor ||
                                  settings?.buttonBackgroundColor
                                }`,
                              }}
                            >
                              {offer.offerCTAText || "Get offer now"}
                            </div>
                          )}
                        </>
                      )}

                      {!displayOnly &&
                        campaign.generalOptions?.saveOfferOptions
                          .allowSavingOffers &&
                        campaign.generalOptions?.saveOfferOptions.allowEmail &&
                        offer.offerSettings.redeemBy === RedeemBy.webemail && (
                          <span
                            className="compact-item-email-icon"
                            style={{
                              backgroundColor:
                                settings?.emailButtonBackgroundColor ||
                                "#aaaaaa",
                              color: settings?.emailButtonTextColor,
                              border: `1px solid transparent`,
                              borderRadius: `${settings?.buttonRounding}px`,
                            }}
                          >
                            <svg
                              width="14"
                              height="11"
                              viewBox="0 0 14 11"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M13 2.125C13 1.50625 12.46 1 11.8 1H2.2C1.54 1 1 1.50625 1 2.125M13 2.125V8.875C13 9.49375 12.46 10 11.8 10H2.2C1.54 10 1 9.49375 1 8.875V2.125M13 2.125L7 6.0625L1 2.125"
                                stroke={
                                  settings?.emailButtonTextColor || "#f2f2f2"
                                }
                                strokeWidth="1"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </span>
                        )}
                    </div>
                  </div>
                </div>
              </label>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default OfferItem;
