import "./OfferAdvancedInsights.scss";
import OrdersWeekHeatmap from "./graphs/OrdersWeekHeatmap";
import { PlacementStageInsight } from "../../../../types/templates.interfaces";
import SalesFunnelData from "./graphs/SalesFunnelData";
import PerformanceByDeviceTypeDonut from "./graphs/PerformanceByDeviceTypeDonut";
import TopCategoriesBarRace from "./graphs/TopCategoriesBarRace";
import useAuth from "../../../../hooks/useAuth.hook";
import { Filters } from "../../Insight";
interface Props {
  companyId?: string;
  campaignId?: string;
  offerId?: string;
  placementType?: PlacementStageInsight;
  salesNumber: number;
  CTR?: number;
  CR?: number;
  insightFilters?: Filters;
  filterTrigger?: number;
}

const OfferAdvancedInsightsPage = ({
  campaignId,
  offerId,
  placementType,
  salesNumber,
  insightFilters,
  filterTrigger,
}: Props) => {
  const { company } = useAuth();

  return (
    <div className="insights-drawer">
      <div className="insights-drawer-item">
        <SalesFunnelData
          companyId={company?.company_Id}
          campaignId={campaignId}
          offerId={offerId}
          filters={insightFilters}
          placementType={placementType}
          salesNumber={salesNumber}
          filterTrigger={filterTrigger}
        />
      </div>
      <div
        className={`insights-drawer-item ${
          !campaignId && !offerId ? "insights-drawer-item--page" : ""
        }`}
      >
        <OrdersWeekHeatmap
          companyId={company?.company_Id}
          campaignId={campaignId}
          offerId={offerId}
          filters={insightFilters}
          salesNumber={salesNumber}
          filterTrigger={filterTrigger}
        />
      </div>
      <div
        className={`insights-drawer-item flex-space-between ${
          !campaignId && !offerId ? "insights-drawer-item--page" : ""
        }`}
      >
        <PerformanceByDeviceTypeDonut
          companyId={company?.company_Id}
          campaignId={campaignId}
          offerId={offerId}
          filters={insightFilters}
          salesNumber={salesNumber}
          filterTrigger={filterTrigger}
        />
        <TopCategoriesBarRace
          companyId={company?.company_Id}
          campaignId={campaignId}
          offerId={offerId}
          filters={insightFilters}
          salesNumber={salesNumber}
          filterTrigger={filterTrigger}
        />
      </div>
    </div>
  );
};

export default OfferAdvancedInsightsPage;
