import { InfoCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

interface Props {
  record: DataType;
}

interface DataType {
  key?: React.Key;
  brand?: string;
  offer?: string;
  impressions?: number;
  clicks?: number;
  shareOfClicks?: string;
  ctr?: string;
  sales?: number;
  cr?: string;
  cpa?: string;
  commision: string;
  epc?: string;
  ecpm?: string;
  transactionsWebPendingCount: number;
  transactionsWebApprovedCount: number;
  transactionsAutoEmailPendingCount: number;
  transactionsAutoEmailApprovedCount: number;
  transactionsManualEmailPendingCount: number;
  transactionsManualEmailApprovedCount: number;
  transactionsSharePendingCount: number;
  transactionsShareApprovedCount: number;
  totalWebPendingAmount: number;
  totalWebApprovedAmount: number;
  totalAutoEmailApprovedAmount: number;
  totalAutoEmailPendingAmount: number;
  totalManualEmailPendingAmount: number;
  totalManualEmailApprovedAmount: number;
  totalSharePendingAmount: number;
  totalShareApprovedAmount: number;
}

const CommisionTooltip = ({ record }: Props) => {
  const currencySymbol = record.commision.charAt(0);

  return (
    <Tooltip
      overlayInnerStyle={{
        backgroundColor: "white",
        color: "black",
        boxShadow: "0 2px 8px rgba(0, 0, 0, 0.15)",
        width: "250px",
      }}
      className="sales-tooltip"
      title={
        <div className="sales-tooltip-content">
          {(record.totalWebPendingAmount > 0 ||
            record.totalWebApprovedAmount > 0) && (
            <div className="sales-tooltip-content-item">
              <h1 className="sales-tooltip-content-item-title">Web</h1>
              {record.totalWebPendingAmount > 0 && (
                <p className="sales-tooltip-content-item-count">
                  Pending: {currencySymbol}
                  {record.totalWebPendingAmount.toFixed(2)}
                </p>
              )}
              {record.totalWebApprovedAmount > 0 && (
                <p className="sales-tooltip-content-item-count">
                  Approved: {currencySymbol}
                  {record.totalWebApprovedAmount.toFixed(2)}
                </p>
              )}
            </div>
          )}

          {(record.totalAutoEmailApprovedAmount > 0 ||
            record.totalAutoEmailPendingAmount > 0 ||
            record.totalManualEmailPendingAmount > 0 ||
            record.totalManualEmailApprovedAmount > 0) && (
            <>
              <h1 className="sales-tooltip-content-item-title">Email</h1>

              {(record.totalAutoEmailApprovedAmount > 0 ||
                record.totalAutoEmailPendingAmount > 0) && (
                <div className="sales-tooltip-content-item">
                  <h1 className="sales-tooltip-content-item-subtitle mleft-10">
                    Auto
                  </h1>
                  {record.totalAutoEmailApprovedAmount > 0 && (
                    <p className="sales-tooltip-content-item-count">
                      Pending: {currencySymbol}
                      {record.totalAutoEmailApprovedAmount.toFixed(2)}
                    </p>
                  )}
                  {record.totalAutoEmailPendingAmount > 0 && (
                    <p className="sales-tooltip-content-item-count">
                      Approved: {currencySymbol}
                      {record.totalAutoEmailPendingAmount.toFixed(2)}
                    </p>
                  )}
                </div>
              )}

              {(record.totalManualEmailPendingAmount > 0 ||
                record.totalManualEmailApprovedAmount > 0) && (
                <div className="sales-tooltip-content-item">
                  <h1 className="sales-tooltip-content-item-subtitle mleft-10">
                    Manual
                  </h1>
                  {record.totalManualEmailPendingAmount > 0 && (
                    <p className="sales-tooltip-content-item-count">
                      Pending: {currencySymbol}
                      {record.totalManualEmailPendingAmount.toFixed(2)}
                    </p>
                  )}
                  {record.totalManualEmailApprovedAmount > 0 && (
                    <p className="sales-tooltip-content-item-count">
                      Approved: {currencySymbol}
                      {record.totalManualEmailApprovedAmount.toFixed(2)}
                    </p>
                  )}
                </div>
              )}
            </>
          )}

          {(record.totalSharePendingAmount > 0 ||
            record.totalShareApprovedAmount > 0) && (
            <div className="sales-tooltip-content-item">
              <h1 className="sales-tooltip-content-item-title">Share</h1>
              {record.totalSharePendingAmount > 0 && (
                <p className="sales-tooltip-content-item-count">
                  Pending: {currencySymbol}
                  {record.totalSharePendingAmount.toFixed(2)}
                </p>
              )}
              {record.totalShareApprovedAmount > 0 && (
                <p className="sales-tooltip-content-item-count">
                  Approved: {currencySymbol}
                  {record.totalShareApprovedAmount.toFixed(2)}
                </p>
              )}
            </div>
          )}
        </div>
      }
    >
      <InfoCircleOutlined style={{ marginLeft: 8, cursor: "pointer" }} />
    </Tooltip>
  );
};

export default CommisionTooltip;
