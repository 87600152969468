import { useEffect, useState } from "react";
import {
  MainLayoutSettings,
  DealLayoutSettings,
} from "../../../../../types/campaign.interfaces";
import { LayoutDevices } from "../../../const/views";
import TemplateSettings from "./TemplateSettings/TemplateSettings";
import useExpress from "../../../../../hooks/useExpress.hook";
import { ExpressPlacementSettings } from "../../../types/express.types";
import "./Template.scss";
import PreviewTemplateDesktop from "./PreviewTemplateDesktop/preview/PreviewTemplateDesktop";
import PreviewTemplateMobile from "./PreviewTemplateMobile/preview/PreviewTemplateMobile";

interface Props {
  placement: ExpressPlacementSettings;
}

const Template = ({ placement }: Props) => {
  const { campaign, setCampaign } = useExpress();

  const [currentPlacement, setCurrentPlacement] =
    useState<ExpressPlacementSettings>(placement);
  const [layoutView, setLayoutView] = useState<LayoutDevices>(
    LayoutDevices.desktop
  );

  useEffect(() => {
    if (campaign) {
      setCampaign({
        ...campaign,
        placementSettings: [currentPlacement] || [],
      });
    }

    // eslint-disable-next-line
  }, [currentPlacement]);

  const setMainLayoutSettings = (mainLayout: MainLayoutSettings): void => {
    if (currentPlacement) {
      var newPlacement = {
        ...currentPlacement,
        templateSettings: {
          ...currentPlacement.templateSettings,
          [layoutView]: {
            ...currentPlacement.templateSettings[layoutView],
            settings: {
              ...currentPlacement.templateSettings[layoutView].settings,
              mainLayout,
            },
          },
        },
      };
      setCurrentPlacement(newPlacement);
    }
  };

  const setDealLayoutSettings = (dealLayout: DealLayoutSettings): void => {
    if (currentPlacement) {
      var newPlacement = {
        ...currentPlacement,
        templateSettings: {
          ...currentPlacement.templateSettings,
          [layoutView]: {
            ...currentPlacement.templateSettings[layoutView],
            settings: {
              ...currentPlacement.templateSettings[layoutView].settings,
              dealLayout,
            },
          },
        },
      };
      setCurrentPlacement(newPlacement);
    }
  };

  const handleChangeDevicetype = (type: LayoutDevices) => {
    setLayoutView(type);
  };

  return (
    <>
      <div className="widget widget-express widget-shopify">
        <TemplateSettings
          updateMainLayoutSettings={setMainLayoutSettings}
          updateDealLayoutSettings={setDealLayoutSettings}
          mainLayout={
            currentPlacement.templateSettings[layoutView].settings?.mainLayout
          }
          dealLayout={
            currentPlacement.templateSettings[layoutView].settings?.dealLayout
          }
          template={currentPlacement.templateSettings[layoutView].template}
        />
      </div>

      <div className="widget widget-express widget-shopify template-preview">
        <div className="device-switch">
          <div
            className={`icon-holder ${
              layoutView === "desktop" ? "active" : ""
            }`}
            onClick={() => handleChangeDevicetype(LayoutDevices.desktop)}
          >
            <svg
              width="17"
              height="14"
              viewBox="0 0 17 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14.5693 1H3.12229C2.33203 1 1.69141 1.59695 1.69141 2.33333V9C1.69141 9.73638 2.33203 10.3333 3.12229 10.3333H14.5693C15.3596 10.3333 16.0002 9.73638 16.0002 9V2.33333C16.0002 1.59695 15.3596 1 14.5693 1Z"
                stroke={layoutView === "desktop" ? "#fff" : "#007A5C"}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.98242 13H11.7059"
                stroke={layoutView === "desktop" ? "#fff" : "#007A5C"}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8.8457 10.3335V13.0002"
                stroke={layoutView === "desktop" ? "#fff" : "#007A5C"}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div
            className={`icon-holder ${layoutView === "mobile" ? "active" : ""}`}
            onClick={() => handleChangeDevicetype(LayoutDevices.mobile)}
          >
            <svg
              width="11"
              height="15"
              viewBox="0 0 11 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.74163 1.4375H2.45954C1.76564 1.4375 1.20312 2.00002 1.20312 2.69392V12.7453C1.20312 13.4392 1.76564 14.0017 2.45954 14.0017H8.74163C9.43553 14.0017 9.99805 13.4392 9.99805 12.7453V2.69392C9.99805 2.00002 9.43553 1.4375 8.74163 1.4375Z"
                stroke={layoutView === "mobile" ? "#fff" : "#007A5C"}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5.59961 11.4883H5.60611"
                stroke={layoutView === "mobile" ? "#fff" : "#007A5C"}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
        {campaign &&
          (layoutView === "desktop" ? (
            <PreviewTemplateDesktop
              description={"Desktop"}
              campaign={campaign}
              offersCount={"3"}
              displayOnly={false}
              settings={currentPlacement?.templateSettings[layoutView].settings}
              buttonShape={
                currentPlacement?.templateSettings[layoutView]?.button.shape
              }
            />
          ) : (
            <PreviewTemplateMobile
              description={"Desktop"}
              campaign={campaign}
              offersCount={"3"}
              displayOnly={false}
              settings={currentPlacement?.templateSettings[layoutView].settings}
              buttonShape={
                currentPlacement?.templateSettings[layoutView]?.button.shape
              }
            />
          ))}
      </div>
    </>
  );
};

export default Template;
