import { Switch, Tabs } from "antd";
import { useState } from "react";
import {
  LayoutChildClass,
  LayoutClass,
  LayoutDevices,
} from "../../../../const/views";
import {
  CampaignType,
  DealLayoutSettings,
  LayoutSettings,
  MainLayoutSettings,
  OtherSettingsInterface,
  PlacementSettings,
} from "../../../../../../types/campaign.interfaces";
import { Template } from "../../../../../../types/templates.interfaces";
import ButtonLayout from "../../../../../templates-previews/layout-views/ButtonLayout.component";
import PreviewTemplate from "../../../../../templates-previews/PreviewTemplate.component";
import ButtonView from "../../views/ButtonView.component";
import ViewPopupN13 from "./settings/SettingsN13.component";
import PreviewPopupN13 from "./preview/PreviewPopupN13.component";
import {
  BehaviourSettings,
  LocationSettings,
} from "../../../../../templates-previews/types/params.interfaces";

interface Props {
  currentPlacement: PlacementSettings;
  setCurrentPlacement: React.Dispatch<
    React.SetStateAction<PlacementSettings | undefined>
  >;
  campaign: CampaignType;
  layoutView: LayoutDevices;
  displayOnly: boolean;
  offersCount: string | undefined;
  setCampaign: React.Dispatch<React.SetStateAction<CampaignType>>;
  setTemplate: React.Dispatch<React.SetStateAction<Template | undefined>>;
}

const TemplateN13 = ({
  campaign,
  layoutView,
  setCampaign,
  currentPlacement,
  setCurrentPlacement,
  offersCount,
  displayOnly,
}: Props) => {
  const [optionsView, setOptionsView] = useState<LayoutChildClass>(
    LayoutChildClass.popup
  );

  const getPreviewTitle = () => {
    return currentPlacement?.promoMessage &&
      currentPlacement.promoMessage !== " "
      ? currentPlacement.promoMessage
      : campaign.description;
  };

  const setMainLayoutSettings = (mainLayout: MainLayoutSettings): void => {
    if (currentPlacement) {
      var newPlacement = {
        ...currentPlacement,
        templateSettings: {
          ...currentPlacement.templateSettings,
          [layoutView]: {
            ...currentPlacement.templateSettings[layoutView],
            settings: {
              ...currentPlacement.templateSettings[layoutView].settings,
              mainLayout,
            },
          },
        },
      };
      setCurrentPlacement(newPlacement);
    }
  };

  const setDealLayoutSettings = (dealLayout: DealLayoutSettings): void => {
    if (currentPlacement) {
      var newPlacement = {
        ...currentPlacement,
        templateSettings: {
          ...currentPlacement.templateSettings,
          [layoutView]: {
            ...currentPlacement.templateSettings[layoutView],
            settings: {
              ...currentPlacement.templateSettings[layoutView].settings,
              dealLayout,
            },
          },
        },
      };
      setCurrentPlacement(newPlacement);
    }
  };

  const setLocationSettings = (location: LocationSettings): void => {
    if (currentPlacement) {
      var newPlacement = {
        ...currentPlacement,
        templateSettings: {
          ...currentPlacement.templateSettings,
          [layoutView]: {
            ...currentPlacement.templateSettings[layoutView],
            settings: {
              ...currentPlacement.templateSettings[layoutView].settings,
              location,
            },
          },
        },
      };
      setCurrentPlacement(newPlacement);
    }
  };

  const setDelaySettings = (delay: BehaviourSettings): void => {
    if (currentPlacement) {
      var newPlacement = {
        ...currentPlacement,
        templateSettings: {
          ...currentPlacement.templateSettings,
          [layoutView]: {
            ...currentPlacement.templateSettings[layoutView],
            settings: {
              ...currentPlacement.templateSettings[layoutView].settings,
              delay,
            },
          },
        },
      };
      setCurrentPlacement(newPlacement);
    }
  };

  const setOtherSettings = (otherSettings: OtherSettingsInterface): void => {
    if (currentPlacement) {
      var newPlacement = {
        ...currentPlacement,
        templateSettings: {
          ...currentPlacement.templateSettings,
          [layoutView]: {
            ...currentPlacement.templateSettings[layoutView],
            settings: {
              ...currentPlacement.templateSettings[layoutView].settings,
              otherSettings,
            },
          },
        },
      };
      setCurrentPlacement(newPlacement);
    }
  };

  const setLayoutSettings = (
    settings: LayoutSettings,
    promoMessage?: string
  ) => {
    if (currentPlacement) {
      var newPlacement = { ...currentPlacement, templateSettings: settings };
      (promoMessage || promoMessage === "") &&
        (newPlacement.promoMessage = promoMessage);
      setCurrentPlacement(newPlacement);
    }
  };

  const handleStartAsExpand = (value: boolean) => {
    if (currentPlacement) {
      var newPlacement = {
        ...currentPlacement,
        templateSettings: {
          ...currentPlacement.templateSettings,
          [layoutView]: {
            ...currentPlacement.templateSettings[layoutView],
            type: value ? LayoutClass.action : LayoutClass.button,
          },
        },
      };
      setCurrentPlacement(newPlacement);
    }
  };

  const tabItems = [
    {
      label: <>Pop up</>,
      key: LayoutChildClass.popup,
      children: (
        <ViewPopupN13
          currentPlacement={currentPlacement}
          defaultTitle={campaign.description}
          updateLocationSettings={setLocationSettings}
          updateMainLayoutSettings={setMainLayoutSettings}
          updateDealLayout={setDealLayoutSettings}
          updateDelaySettings={setDelaySettings}
          updateOtherSettings={setOtherSettings}
          layoutDevice={layoutView}
          showSelectSettings={campaign.generalOptions.showAddToOrder}
        />
      ),
    },
    {
      label: <>Button</>,
      key: LayoutChildClass.button,
      children: (
        <ButtonView
          settings={currentPlacement.templateSettings}
          updateSettings={setLayoutSettings}
          layoutDevice={layoutView}
          displayOnly={currentPlacement?.displayOnly}
        />
      ),
    },
  ];

  return (
    <>
      <div className="widget-layout">
        <div className="interaction-pages">
          <div className="interaction-pages-overlay">
            <div className="mbot20">
              <Switch
                id="minimizedAction"
                size="small"
                defaultChecked={
                  currentPlacement.templateSettings[layoutView].type ===
                  LayoutClass.action
                }
                onChange={handleStartAsExpand}
              />
              <label htmlFor="minimizedAction" className="font-hover mleft5">
                Start as popup
              </label>
            </div>

            <div className="interaction-section">
              <>
                <Tabs
                  centered
                  className="layout-tabs"
                  onChange={(key: string) =>
                    setOptionsView(key as LayoutChildClass)
                  }
                  activeKey={optionsView}
                  items={
                    currentPlacement.templateSettings[layoutView].type ===
                    LayoutClass.action
                      ? tabItems
                      : tabItems.reverse()
                  }
                />
              </>
            </div>
          </div>
        </div>

        <div className="interaction-view">
          <div
            className={`view-content ${
              layoutView === "desktop" ? "desktop" : "mobile"
            }`}
          >
            <div className="template-layout template-blur">
              {/* <iframe title="advertisers-template" src={`${process.env.PUBLIC_URL}/templates`}></iframe> */}

              <PreviewTemplate isInline={false} layoutView={layoutView} />
            </div>

            <>
              {optionsView === LayoutChildClass.popup && (
                <>
                  <PreviewPopupN13
                    description={getPreviewTitle()}
                    campaign={campaign}
                    offersCount={offersCount}
                    displayOnly={displayOnly}
                    settings={
                      currentPlacement?.templateSettings[layoutView].settings
                    }
                    buttonShape={
                      currentPlacement?.templateSettings[layoutView]?.button
                        .shape
                    }
                  />
                </>
              )}
              {optionsView === LayoutChildClass.button && (
                <ButtonLayout
                  settings={
                    currentPlacement?.templateSettings[layoutView]?.button
                  }
                  displayOnly={currentPlacement.displayOnly}
                  count={campaign.offers.length}
                />
              )}
            </>
            {/* {optionsView === LayoutChildClass.button && <></>} */}
            {/* <iframe title="offer-page-layout" src={`${process.env.PUBLIC_URL}/offer-page-layout?position=${position}`} ></iframe> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default TemplateN13;
