import { Button, Empty, message, Tooltip } from "antd";
import { Pagination } from "../../types/global.interfaces";
import "./css/campaigns.scss";
import { Link, useNavigate } from "react-router-dom";
import usePage from "../../hooks/usePage.hook";
import React, { useEffect, useState } from "react";
import useAxios from "../../hooks/useAxios.hook";
import useAuth from "../../hooks/useAuth.hook";
import { FileUnknownOutlined, LoadingOutlined } from "@ant-design/icons";
import { CampaignType } from "../../types/campaign.interfaces";
import plusIcon from "../../assets/images/icons/plus-circle-icon.svg";
import minusIcon from "../../assets/images/icons/minus-circle-icon.svg";
import { Offer } from "../../types/offer.interfaces";
import { stringDateToMoment } from "../../utility/date";
import Footer from "../footer/footer.component";
import { ACCESS_TYPES } from "../../utility/enums/user.enums";

const Campaigns = () => {
  const { response, axiosFetch, loading: responseLoading } = useAxios();
  const { myAxios } = useAxios();
  const [campaigns, setCampaigns] = useState<CampaignType[]>([]);
  const [toggleCampaigns, setToggleCampaigns] = useState<boolean[]>();
  const [totalPages, setTotalPages] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const [pagination, setPagination] = useState<Pagination>({
    page: 1,
    pageSize: 10,
  });

  const { auth, company } = useAuth();
  const { setPage } = usePage();
  const navigation = useNavigate();

  const fetchCampaigns = async () => {
    if (company) {
      const _currentCompanyId = company.company_Id;
      await axiosFetch({
        method: "get",
        url: `campaign/company/${_currentCompanyId}?page=${pagination.page}&pageSize=${pagination.pageSize}`,
      });
    }
  };

  // Set campaigns and offers toggle array
  useEffect(() => {
    let campaignsLength = 0;
    if (response?.status) {
      setCampaigns(response.result.data);
      campaignsLength = response.result.data.length;
      if (campaignsLength !== toggleCampaigns?.length) {
        const campaignsToggleArray = Array.from(
          { length: response.result.data.length },
          (_, i) => false
        );
        setTotalPages(response.result.totalPages);
        setToggleCampaigns(campaignsToggleArray);
      }
      setLoading(false);
    }

    setPage({
      title: <>Campaigns: {response?.result?.count || 0}</>,
    });
    // eslint-disable-next-line
  }, [response]);

  useEffect(() => {
    fetchCampaigns();
    // eslint-disable-next-line
  }, [auth, pagination]);

  const toggleCampaign = (index: number) => {
    const campaignsToggleArray = toggleCampaigns?.map(
      (value: boolean, i: number) => {
        return index === i ? !value : value;
      }
    );

    setToggleCampaigns(campaignsToggleArray);
  };

  const handleStartCampaign = async (id: string | undefined) => {
    if (id) {
      setLoading(true);
      const { response } = await myAxios({
        method: "post",
        url: `/campaign/active/${id}/true`,
      });

      if (response?.status) {
        message.success("Campaign is started", 1);
      } else {
        message.error("Campaign failed to start", 1);
      }
      fetchCampaigns();
    }
  };

  const handleStopCampaign = async (id: string | undefined) => {
    if (id) {
      setLoading(true);
      const { response } = await myAxios({
        method: "post",
        url: `/campaign/active/${id}/false`,
      });
      if (response?.status) {
        message.success("Campaign is stopped", 1);
      } else {
        message.error("Campaign failed to stop", 1);
      }
      fetchCampaigns();
    }
  };

  return (
    <>
      <div className="content">
        <div className="content-layout">
          <div className="layout-options">
            <div className="filters">
              <div className="filter-dropdown">
                {/* <Dropdown placement="bottom" trigger={['click']} dropdownRender={(menu) => {
                return <div className="dropdown">
                  <div className="dropdown-title">TOP 5 categories</div>
                  <ul>
                    <li>
                      <Checkbox className="checkbox-active">Clothing<span className="checkbox-value">6</span></Checkbox>
                    </li>
                    <li>
                      <Checkbox defaultChecked={true} className="checkbox-active">Food<span className="checkbox-value">12</span></Checkbox>
                    </li>
                    <li>
                      <Checkbox className="checkbox-active">Artificial intelligence<span className="checkbox-value">7</span></Checkbox>
                    </li>
                  </ul>
                </div>
              }}>
                <span className="dropdown-label" onClick={(e) => e.preventDefault()}>
                  Cateogries:
                  <span className="label-count">
                    2
                  </span>
                </span>
              </Dropdown>

              <Dropdown placement="bottom" trigger={['click']} dropdownRender={(menu) => {
                return <div className="dropdown">
                  <div className="dropdown-title">Offer type</div>
                  <ul>
                    <li>
                      <Checkbox defaultChecked={true} className="checkbox-active">Promotions<span className="checkbox-value">20</span></Checkbox>
                    </li>
                    <li>
                      <Checkbox className="checkbox-active">Vouchers<span className="checkbox-value">12</span></Checkbox>
                    </li>
                  </ul>
                </div>
              }}>
                <span className="dropdown-label" onClick={(e) => e.preventDefault()}>
                  Types
                </span>
              </Dropdown> */}
              </div>
            </div>
            <div className="layout-actions">
              {company?.userAccess?.access.includes(
                ACCESS_TYPES.campaignadd
              ) && (
                <Button
                  type="primary"
                  onClick={() => navigation("/campaigns/single")}
                >
                  Add campaign
                </Button>
              )}
              {/* <Dropdown menu={{ items: byRelevant }} trigger={['click']} >
              <div className="button-dropdown button-icon">
                By relevance <span className="icon small icon-arrow-down"></span>
              </div>
            </Dropdown> */}
            </div>
          </div>

          <div className="table-wrapper">
            <table>
              <thead>
                <tr>
                  <th>
                    <span>Name</span>
                  </th>
                  <th>
                    <span>Start date</span>
                  </th>
                  <th>
                    <span>End date</span>
                  </th>
                  <th>
                    <span>Status</span>
                  </th>
                </tr>
              </thead>

              <tbody>
                {loading || responseLoading ? (
                  <tr
                    className={
                      campaigns && campaigns.length > 0 ? "loader-absolute" : ""
                    }
                  >
                    <td className="text-center" colSpan={5}>
                      <LoadingOutlined />
                    </td>
                  </tr>
                ) : (
                  <>
                    {campaigns?.length === 0 && (
                      <tr>
                        <td className="text-center" colSpan={5}>
                          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                        </td>
                      </tr>
                    )}
                  </>
                )}

                <>
                  {campaigns &&
                    campaigns?.length > 0 &&
                    campaigns?.map((campaign: CampaignType, index: number) => {
                      return (
                        <React.Fragment key={campaign.name + index}>
                          <tr
                            className={`table-row-parent ${
                              campaign.isOpen ? "table-row-open" : ""
                            }`}
                          >
                            <td>
                              {campaign.offers.length > 0 && (
                                <img
                                  className="table-toggle table-link"
                                  onClick={() => toggleCampaign(index)}
                                  src={
                                    toggleCampaigns && !toggleCampaigns[index]
                                      ? plusIcon
                                      : minusIcon
                                  }
                                  alt="expand"
                                />
                              )}
                              <Link
                                to={`/campaigns/single/${campaign.id}`}
                                className={`${
                                  toggleCampaigns && !toggleCampaigns[index]
                                    ? "table-link"
                                    : "table-hover"
                                }`}
                              >
                                {" "}
                                {campaign.name}
                              </Link>
                            </td>
                            <td>
                              {stringDateToMoment(campaign.startDate).format(
                                "HH:mm DD-MM-YYYY"
                              )}
                            </td>
                            <td>
                              {stringDateToMoment(campaign.endDate).format(
                                "HH:mm DD-MM-YYYY"
                              )}
                            </td>
                            <td>
                              {campaign.completionStatus.status ? (
                                <span className="table-actions">
                                  {company?.userAccess?.access.includes(
                                    ACCESS_TYPES.campaignwrite
                                  ) && (
                                    <>
                                      {campaign.isActive ? (
                                        <>
                                          <span className="label label-success label-fixed">
                                            Active
                                          </span>
                                          <span
                                            className="table-icon icon-stop mleft20"
                                            onClick={() =>
                                              handleStopCampaign(campaign.id)
                                            }
                                          ></span>
                                        </>
                                      ) : (
                                        <>
                                          <span className="label label-fail label-fixed">
                                            Stopped
                                          </span>
                                          <span
                                            className="table-icon icon-start mleft20"
                                            onClick={() =>
                                              handleStartCampaign(campaign.id)
                                            }
                                          ></span>
                                        </>
                                      )}
                                    </>
                                  )}
                                </span>
                              ) : (
                                <>
                                  <Tooltip placement="bottom" title="Draft">
                                    <FileUnknownOutlined />
                                  </Tooltip>
                                </>
                              )}
                            </td>
                          </tr>
                          {toggleCampaigns && toggleCampaigns[index] && (
                            <tr>
                              <td className="table-child-wrap" colSpan={5}>
                                <table className="table-child">
                                  <thead>
                                    <tr>
                                      <th>Offer name</th>
                                      <th>Start date</th>
                                      <th>End date</th>
                                      <th>Status</th>
                                    </tr>
                                  </thead>

                                  <tbody>
                                    {campaign.offers.length ? (
                                      campaign.offers?.map(
                                        (offer: Offer, index: number) => {
                                          return (
                                            <tr key={`${offer.title}${index}`}>
                                              <td>{offer.title}</td>
                                              <td>
                                                {stringDateToMoment(
                                                  offer.startDate
                                                ).format("DD-MM-YYYY")}
                                              </td>

                                              <td>
                                                {stringDateToMoment(
                                                  offer.endDate
                                                ).format("DD-MM-YYYY")}
                                              </td>

                                              <td>
                                                <span
                                                  className={`label ${
                                                    offer.displayStatus ===
                                                    "Active"
                                                      ? "font-success"
                                                      : "font-error"
                                                  }`}
                                                >
                                                  {offer.displayStatus}
                                                </span>
                                              </td>
                                            </tr>
                                          );
                                        }
                                      )
                                    ) : (
                                      <tr>
                                        <td className="text-center" colSpan={5}>
                                          <Empty
                                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                                            description="No offers"
                                          />
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </td>
                            </tr>
                          )}
                        </React.Fragment>
                      );
                    })}
                </>
              </tbody>
            </table>
          </div>

          <div className="pagination">
            <div
              className="pagination-prev"
              onClick={() =>
                pagination.page > 1 &&
                setPagination((prevState) => ({
                  ...prevState,
                  page: prevState.page - 1,
                }))
              }
            >
              {pagination.page > 1 && (
                <>
                  <span className="pagination-box"></span>
                  <span className="pagination-label">Preview</span>
                </>
              )}
            </div>

            <ul>
              <>
                {Array.from({ length: totalPages }, (_, i) => i + 1).map(
                  (page: number) => {
                    return (
                      <li
                        key={page}
                        onClick={() =>
                          page !== pagination.page &&
                          setPagination((prevState) => ({ ...prevState, page }))
                        }
                        className={page === pagination.page ? "active" : ""}
                      >
                        <span className="pagination-box">{page}</span>
                      </li>
                    );
                  }
                )}
              </>
            </ul>

            <div
              className="pagination-next"
              onClick={() =>
                pagination.page < totalPages &&
                setPagination((prevState) => ({
                  ...prevState,
                  page: prevState.page + 1,
                }))
              }
            >
              {pagination.page < totalPages && (
                <>
                  <span className="pagination-label">Next</span>
                  <span className="pagination-box"></span>
                </>
              )}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default Campaigns;
