import {
  DeleteOutlined,
  EyeInvisibleOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import { Button, Empty, message, Modal, Select, Switch, Tooltip } from "antd";
import { BaseOptionType } from "antd/lib/select";
import { useEffect, useMemo, useState } from "react";
import useAuth from "../../../../hooks/useAuth.hook";
import useAxios from "../../../../hooks/useAxios.hook";
import {
  CampaignType,
  PlacementSettings,
} from "../../../../types/campaign.interfaces";
import {
  PlacementPage,
  PlacementPageType,
} from "../../../../types/global.interfaces";
import { templateSettings } from "../../const/campaigns.enum";
import "./css/templates.scss";
import LayoutDialog from "./dialog/layoutDialog.component";
import { ACCESS_TYPES } from "../../../../utility/enums/user.enums";
import { PLATFORM_VIEW_MODE } from "../../../express/types/express.enum";

interface Props {
  campaign: CampaignType;
  handlePut: (campaign: CampaignType, move: boolean) => void;
  setCampaign: React.Dispatch<React.SetStateAction<CampaignType>>;
  setSavedCampaign: React.Dispatch<React.SetStateAction<CampaignType>>;
  setDisabled: React.Dispatch<React.SetStateAction<boolean>>;
}

const CampaigLayout = ({
  setCampaign,
  campaign,
  setDisabled,
  handlePut,
  setSavedCampaign,
}: Props) => {
  const { company } = useAuth();
  const [isPagesOpen, setPagesOpen] = useState<boolean>(false);
  const [isAddPagesOpen, setAddPagesOpen] = useState<boolean>(false);
  const [isEditPageOpen, setEditPageOpen] = useState<boolean>(false);
  const [companyPlacements, getCompanyPlacements] = useState<PlacementPage[]>(
    []
  );
  const [pagesList, setPages] = useState<PlacementPageType[]>([]);
  const [currentPlacement, setCurrentPlacement] = useState<
    PlacementSettings | undefined
  >();
  const { myAxios } = useAxios();

  const emptyPage: PlacementPage = {
    id: "",
    name: "",
    pageType_Id: "",
    url: "",
  };

  const [currentPage, setCurrentPage] = useState<PlacementPage>(emptyPage);
  const emptyPlacement: PlacementSettings = {
    id: "",
    isRedeemable: false,
    hasExitView: false,
    isEnabled: true,
    displayOnly: true,
    isVisible: true,
    promoMessage: "",
    page: "",
    isEdit: false,
    templateSettings: templateSettings,
    allocation: 100,
  };

  const fetchPages = async () => {
    const { response } = await myAxios({ method: "get", url: `pagetype` });
    if (response?.data?.status) {
      setPages(response.data.result);
    }
  };

  const fetchCompanyPlacements = async (id: string) => {
    const { response } = await myAxios({
      method: "get",
      url: `company/pageplacements/${id}`,
    });
    if (response?.data?.status) {
      getCompanyPlacements(response.data?.result);
    }
  };

  useEffect(() => {
    fetchPages();
    company?.company_Id && fetchCompanyPlacements(company?.company_Id);
    // eslint-disable-next-line
  }, [company]);

  const openConfirmRemovePlacementByIndex = (pid: string) => {
    Modal.confirm({
      title: "Do you want to remove this placement?",
      content: 'You can add it again by the "Manage placement” button',
      okText: "Remove",
      icon: <></>,
      maskClosable: true,
      width: 520,
      closable: true,
      closeIcon: (
        <span className="ant-modal-close-icon">
          <span className="icon icon-remove"></span>
        </span>
      ),
      cancelText: "Cancel",
      cancelButtonProps: {
        className: "button-default",
      },
      okButtonProps: {
        className: "button-danger",
      },
      onOk: () =>
        setCampaign((prevState) => ({
          ...prevState,
          placementSettings: prevState.placementSettings.filter(
            (p: PlacementSettings) => pid !== p.id
          ),
        })),
    });
  };

  const handleChangePlacementVisibility = (
    visible: boolean,
    placementId: string
  ) => {
    setCampaign((prevState) => ({
      ...prevState,
      placementSettings: prevState.placementSettings.map(
        (p: PlacementSettings) =>
          placementId === p.id ? { ...p, isVisible: visible } : p
      ),
    }));
  };

  const openConfirmDeletePageModal = (page: PlacementPage) => {
    Modal.confirm({
      title: "Do you want to delete this page?",
      content: (
        <>
          <div className="modal-content-space">
            <div className="modal-confirm-label">
              <span className="font-color">Page name:</span>
              <span className="font-primary">{page.name}</span>
            </div>

            <div className="modal-confirm-label">
              <span className="font-color">Page type:</span>
              <span className="font-primary">no type</span>
            </div>

            <div className="modal-confirm-label">
              <span className="font-color">URL:</span>
              <span className="font-primary">{page.url}</span>
            </div>
          </div>
        </>
      ),
      okText: "Remove",
      icon: <></>,
      maskClosable: true,
      width: 520,
      closable: true,
      closeIcon: (
        <span className="ant-modal-close-icon">
          <span className="icon icon-remove"></span>
        </span>
      ),
      cancelText: "Cancel",
      cancelButtonProps: {
        className: "button-default",
      },
      okButtonProps: {
        className: "button-default fail",
      },
      onOk: () => handleDeletePage(page.id),
    });
  };

  // Generate items for dropdown of page Types
  const pageTypes: BaseOptionType[] = useMemo(() => {
    if (pagesList.length > 0) {
      const list: BaseOptionType[] = pagesList.map(
        (page: PlacementPageType) => {
          return { label: page.name, value: page.id };
        }
      );
      return list;
    }
    return [
      {
        label: "Company do not have page type",
        key: "no pages",
        disabled: true,
      },
    ];
  }, [pagesList]);

  // Open pages modal
  const onAddPages = () => {
    setPagesOpen(false);
    setAddPagesOpen(true);
  };

  const handlePreview = (page?: string) => {
    page && window.open(`${page}?bsw_preview=1`, "_blank");
  };

  const getHomepageUrl = (url: string): string => {
    try {
      const parsedUrl = new URL(url);
      return `${parsedUrl.protocol}//${parsedUrl.hostname}`;
    } catch (error) {
      console.error("Invalid URL", error);
      return url; // or handle the error as needed
    }
  };

  const handlePreviewExpress = (page?: string) => {
    const placementId = campaign?.placementSettings?.[0]?.id;
    const homepageUrl = page ? getHomepageUrl(page) : null;
    homepageUrl &&
      window.open(
        `${homepageUrl}?bsw_plid=${placementId}&bsw_preview=1`,
        "_blank"
      );
  };

  // Create company placement pages and refetch them
  const handleCreatePage = async () => {
    if (company && !isCurrentPageDisabled(currentPage)) {
      setAddPagesOpen(false);
      const { response } = await myAxios({
        method: "post",
        url: `/company/pageplacements/${company.company_Id}`,
        data: currentPage,
      });
      if (response?.data.status) {
        fetchCompanyPlacements(company.company_Id);
        const newPlacement: PlacementSettings = {
          ...emptyPlacement,
          id: response?.data.result.id,
          name: currentPage.name,
          page: currentPage.url,
          isEnabled: false,
        };
        setCampaign((prevState) => ({
          ...prevState,
          placementSettings: [...prevState.placementSettings, newPlacement],
        }));
        setSavedCampaign((prevState) => ({
          ...prevState,
          placementSettings: [...prevState.placementSettings, newPlacement],
        }));
        message.success(`Successfully created page: ${currentPage.name}`, 1);
        fetchPages();
      } else {
        message.error(`${response?.data.result[0].message}`, 1);
      }
    }
  };

  // Edit company placement pages and refetch them
  const handleEditPage = async () => {
    if (company && !isCurrentPageDisabled(currentPage)) {
      setEditPageOpen(false);
      const { response } = await myAxios({
        method: "put",
        url: `/company/pageplacements/${company.company_Id}`,
        data: currentPage,
      });
      if (response?.data.status) {
        fetchCompanyPlacements(company.company_Id);
        const newPlacements: PlacementSettings[] =
          campaign.placementSettings.map((value: PlacementSettings) => {
            return value.id === currentPage.id
              ? { ...value, page: currentPage.url, name: currentPage.name }
              : value;
          });
        setCampaign((prevState) => ({
          ...prevState,
          placementSettings: newPlacements,
        }));
        setSavedCampaign((prevState) => ({
          ...prevState,
          placementSettings: newPlacements,
        }));
        message.success(`Successfully update page: ${currentPage.name}`, 1);
        fetchPages();
      } else {
        message.error(`${response?.data.result[0].message}`, 1);
      }
    }
  };

  // Delete single company placement pages and refetch them
  const handleDeletePage = async (id: string) => {
    if (company) {
      const { response } = await myAxios({
        method: "delete",
        url: `/company/pageplacements/${id}/${company.company_Id}`,
      });

      if (response?.data?.status) {
        message.success(`Page is deleted successfully`, 1);
        fetchCompanyPlacements(company.company_Id);
      } else {
        message.error(response?.data.result[0].message, 1);
      }
    }
  };

  const handleValidateDeletePage = async (page: PlacementPage) => {
    const { response } = await myAxios({
      method: "get",
      url: `/company/valpageplacementondelete/${page.id}`,
    });
    const _placementsPages: string[] = campaign.placementSettings.map(
      (p) => p.id
    );
    if (response?.data?.status && !_placementsPages.includes(page.id)) {
      setPagesOpen(false);
      openConfirmDeletePageModal(page);
    } else {
      message.error(
        `Company page is used on campaigns and cannot be deleted!`,
        1
      );
    }
  };

  // Get page type object by pageType_Id or return empty page type
  const getPageById = (pageId: string): PlacementPageType => {
    const _page: PlacementPageType = pagesList.filter(
      (f) => f.id === pageId
    )[0];
    return _page?.id ? _page : { name: "", id: "" };
  };

  // Get placement page object by id or return empty placement page
  const getPlacementPageById = (placementPageId: string): PlacementPage => {
    const _page: PlacementPage = companyPlacements.filter(
      (f) => f.id === placementPageId
    )[0];
    return _page?.id ? _page : emptyPage;
  };

  const getPagePlacementFromCompany = (id: string): PlacementPage => {
    return companyPlacements.find((f) => f.id === id) || companyPlacements[0];
  };

  const handleAddPlacementById = (page: PlacementPage) => {
    const _newPlacement: PlacementSettings = {
      ...emptyPlacement,
      id: page.id,
      name: page.name,
      page: page.url,
      isEnabled: false,
    };
    setCampaign((prevState) => ({
      ...prevState,
      placementSettings: [...prevState.placementSettings, _newPlacement],
    }));
  };

  const isCurrentPageDisabled = (page: PlacementPage): boolean => {
    return !page.name || !page.url || !page.pageType_Id;
  };

  const handleEditUrl = (placement: PlacementSettings) => {
    setCurrentPage(getPagePlacementFromCompany(placement.id));
    setEditPageOpen(true);
  };

  const handleChangeIsEnable = async (value: boolean, id: string) => {
    const newPlacements: PlacementSettings[] = campaign.placementSettings.map(
      (p: PlacementSettings) => {
        return p.id === id ? { ...p, isEnabled: value } : p;
      }
    );
    setCampaign((prevState) => ({
      ...prevState,
      placementSettings: newPlacements,
    }));
  };

  const hasSelectedTemplates = (placement: PlacementSettings): boolean => {
    return !!(
      placement.templateSettings.desktop?.template &&
      placement.templateSettings.mobile?.template
    );
  };

  return (
    <>
      <div className="content-layout">
        <div className="flex flex-space-between">
          <span>
            Identify the pages on your website which you want to promote and
            configure how the deals are presented
          </span>
          {company?.userAccess?.access.includes(
            ACCESS_TYPES.companyplacementwrite
          ) && (
            <span
              className="font-active font-hover"
              onClick={() => setPagesOpen(true)}
            >
              {" "}
              Add new deal page
            </span>
          )}
        </div>
        {campaign.placementSettings.map(
          (_placement: PlacementSettings, index: number) => {
            return (
              <div key={`placement-${index}`} className="item-placement">
                <div className="placement-info">
                  <div className="placement-title">{_placement.name}</div>
                  <div className={`placement-description`}>
                    {_placement?.page}
                  </div>
                </div>
                <div className="placement-acions">
                  {company?.userAccess?.access.includes(
                    ACCESS_TYPES.placementenable
                  ) && (
                    <Tooltip
                      title={
                        !hasSelectedTemplates(_placement)
                          ? "Please select layout before enabling"
                          : ""
                      }
                    >
                      <div
                        className="placement-toggle"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <label>
                          <span
                            className={`mright10 ${
                              hasSelectedTemplates(_placement)
                                ? "font-hover"
                                : "font-disabled"
                            }`}
                          >
                            {hasSelectedTemplates(_placement) &&
                            _placement.isEnabled
                              ? "Enabled"
                              : "Disabled"}
                          </span>
                          <Switch
                            onChange={(value: boolean) =>
                              handleChangeIsEnable(value, _placement.id)
                            }
                            defaultChecked={
                              hasSelectedTemplates(_placement) &&
                              _placement.isEnabled
                            }
                            disabled={!hasSelectedTemplates(_placement)}
                          />
                        </label>
                      </div>
                    </Tooltip>
                  )}

                  {company?.userAccess?.access.includes(
                    ACCESS_TYPES.companyplacementwrite
                  ) && (
                    <div
                      className="placement-button"
                      onClick={() => handleEditUrl(_placement)}
                    >
                      Edit URL
                    </div>
                  )}

                  <div
                    className="placement-button"
                    onClick={() =>
                      setCurrentPlacement({
                        ..._placement,
                        page: getPlacementPageById(_placement.id).url || "",
                      })
                    }
                  >
                    {hasSelectedTemplates(_placement)
                      ? "View layout"
                      : "Select layout"}
                  </div>

                  {company?.platformViewMode === PLATFORM_VIEW_MODE.express
                    ? getPlacementPageById(_placement.id)?.url &&
                      hasSelectedTemplates(_placement) && (
                        <div
                          className="placement-button"
                          onClick={(e) => {
                            e.stopPropagation();
                            handlePreviewExpress(
                              getPlacementPageById(_placement.id).url
                            );
                          }}
                        >
                          Preview
                        </div>
                      )
                    : getPlacementPageById(_placement.id)?.url &&
                      hasSelectedTemplates(_placement) && (
                        <div
                          className="placement-button"
                          onClick={(e) => {
                            e.stopPropagation();
                            handlePreview(
                              getPlacementPageById(_placement.id).url
                            );
                          }}
                        >
                          Preview
                        </div>
                      )}

                  <div className="font-hover font-color font-xsmall mleft10">
                    {company?.userAccess?.access.includes(
                      ACCESS_TYPES.placementhide
                    ) &&
                      (_placement.isVisible ? (
                        <EyeOutlined
                          onClick={() =>
                            handleChangePlacementVisibility(
                              false,
                              _placement.id
                            )
                          }
                        />
                      ) : (
                        <EyeInvisibleOutlined
                          onClick={() =>
                            handleChangePlacementVisibility(true, _placement.id)
                          }
                        />
                      ))}
                  </div>

                  {company?.userAccess?.access.includes(
                    ACCESS_TYPES.placementdelete
                  ) && (
                    <div className="font-hover font-color font-xsmall mleft10">
                      <DeleteOutlined
                        onClick={() =>
                          openConfirmRemovePlacementByIndex(_placement.id)
                        }
                      />
                    </div>
                  )}
                </div>
              </div>
            );
          }
        )}
      </div>

      {/* Create placement page */}
      <Modal
        className="modal-default"
        title={<></>}
        footer={<></>}
        maskClosable={true}
        open={isAddPagesOpen}
        width={580}
        closable={true}
        afterClose={() => {
          setCurrentPage(emptyPage);
        }}
        onCancel={() => setAddPagesOpen(false)}
        closeIcon={
          <span className="ant-modal-close-icon">
            <span className="icon icon-remove"></span>
          </span>
        }
      >
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="modal-title medium">Add a page</div>
          <div className="modal-content-space">
            <div className="label-input-wrapper">
              <label htmlFor="page">
                <span className="label-input-name">Page</span>
                <input
                  type="text"
                  tabIndex={0}
                  className="label-input"
                  value={currentPage?.name || ""}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setCurrentPage((prevState) => ({
                      ...prevState,
                      name: e?.target.value,
                    }))
                  }
                />
              </label>
            </div>

            <div className="label-input-wrapper">
              <label htmlFor="page">
                <span className="label-input-name">Page type</span>
                <Select
                  tabIndex={0}
                  value={currentPage.pageType_Id}
                  suffixIcon={<span className="icon icon-arrow-down"></span>}
                  className="label-select"
                  options={pageTypes}
                  onChange={(value: string) => {
                    setCurrentPage((prevState: PlacementPage) => ({
                      ...prevState,
                      pageType_Id: value,
                    }));
                  }}
                ></Select>
              </label>
            </div>

            <div className="label-input-wrapper">
              <label htmlFor="page">
                <span className="label-input-name">URL</span>
                <input
                  type="text"
                  value={currentPage.url}
                  className="label-input"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                    setCurrentPage((prevState) => ({
                      ...prevState,
                      url: e?.target.value,
                    }))
                  }
                />
              </label>
            </div>
          </div>

          <div className="modal-buttons flex-center">
            <Button
              type="primary"
              className="button-default button-wide"
              onClick={() => setAddPagesOpen(false)}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              disabled={isCurrentPageDisabled(currentPage)}
              className="button-success button-wide mleft20"
              onClick={handleCreatePage}
            >
              Add
            </Button>
          </div>
        </form>
      </Modal>

      {/* Edit placement page */}
      <Modal
        className="modal-default"
        title={<></>}
        footer={<></>}
        maskClosable={true}
        open={isEditPageOpen}
        width={580}
        closable={true}
        afterClose={() => setCurrentPage(emptyPage)}
        onCancel={() => setEditPageOpen(false)}
        closeIcon={
          <span className="ant-modal-close-icon">
            <span className="icon icon-remove"></span>
          </span>
        }
      >
        <div className="modal-title medium">Add placement</div>
        <div className="modal-content-space">
          <div className="label-input-wrapper">
            <label htmlFor="page">
              <span className="label-input-name">Page</span>
              <input
                tabIndex={0}
                type="text"
                className="label-input"
                value={currentPage?.name || ""}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setCurrentPage((prevState: PlacementPage) => ({
                    ...prevState,
                    name: e?.target.value,
                  }))
                }
              />
            </label>
          </div>
          <div className="label-input-wrapper">
            <label htmlFor="page">
              <span className="label-input-name">Page type</span>
              <Select
                tabIndex={0}
                value={currentPage.pageType_Id}
                suffixIcon={<span className="icon icon-arrow-down"></span>}
                className="label-select"
                options={pageTypes}
                onChange={(value: string) => {
                  setCurrentPage((prevState: PlacementPage) => ({
                    ...prevState,
                    pageType_Id: value,
                  }));
                }}
              ></Select>
            </label>
          </div>

          <div className="label-input-wrapper">
            <label htmlFor="page">
              <span className="label-input-name">URL</span>
              <input
                tabIndex={2}
                type="text"
                value={currentPage.url}
                className="label-input"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setCurrentPage((prevState) => ({
                    ...prevState,
                    url: e?.target.value,
                  }))
                }
              />
            </label>
          </div>
        </div>

        <div className="modal-buttons flex-center">
          <Button
            type="primary"
            className="button-default button-wide"
            onClick={() => setEditPageOpen(false)}
          >
            Cancel
          </Button>
          <Button
            type="primary"
            disabled={isCurrentPageDisabled(currentPage)}
            className="button-success button-wide mleft20"
            onClick={handleEditPage}
          >
            Edit
          </Button>
        </div>
      </Modal>

      {/* Show all placement pages */}
      <Modal
        title={<></>}
        closable={true}
        width="50vw"
        closeIcon={
          <span className="ant-modal-close-icon">
            <span className="icon icon-remove"></span>
          </span>
        }
        maskClosable={true}
        open={isPagesOpen}
        // onOk={onAddPages}
        onCancel={() => setPagesOpen(false)}
        footer={<></>}
      >
        <div className="modal-title">BrandSwap pages</div>
        <div className="modal-description">
          Please add and select pages where campaigns will be placed
        </div>

        <div className="modal-content">
          {companyPlacements?.map((page: PlacementPage, index: number) => {
            return (
              <div key={`cplacement${index}`} className="modal-line">
                <div className="modal-line-title">{page.name}</div>
                <div className="modal-line-description">
                  <div className="modal-line-description-main">
                    {page.url.slice(0, 40)} {page.url.length > 40 && "..."}
                  </div>
                  <div className="modal-line-description-extra">
                    Type: {getPageById(page.pageType_Id).name}
                  </div>
                </div>
                <div className="modal-line-action">
                  {campaign.placementSettings
                    .map((p) => p.id)
                    .includes(page.id) ? (
                    <span
                      className="label label-select active table-label"
                      onClick={() => {}}
                    >
                      Selected
                    </span>
                  ) : (
                    <span
                      className="label label-select table-label"
                      onClick={() => handleAddPlacementById(page)}
                    >
                      Select
                    </span>
                  )}

                  <span
                    className="modal-line-edit"
                    onClick={() => {
                      setCurrentPage(page);
                      setPagesOpen(false);
                      setEditPageOpen(true);
                    }}
                  >
                    Edit
                  </span>

                  <span
                    className="icon icon-remove-square mleft20"
                    onClick={() => {
                      handleValidateDeletePage(page);
                    }}
                  ></span>
                </div>
              </div>
            );
          })}

          {companyPlacements.length === 0 && (
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No pages"
            />
          )}
        </div>

        <div className="modal-line-button">
          <Button
            type="primary"
            className="button-semi-active button-small"
            onClick={() => onAddPages()}
          >
            Add page
          </Button>
        </div>
      </Modal>

      {/* Layout settings of placement page */}
      <Modal
        className="modal-layout"
        title={<></>}
        footer={<></>}
        maskClosable={false}
        destroyOnClose={true}
        open={currentPlacement ? true : false}
        closable={true}
        width={"calc(100vw - 32px)"}
        afterClose={() => {
          setCurrentPlacement(undefined);
        }}
        onCancel={() => setCurrentPlacement(undefined)}
        closeIcon={<span className="icon icon-remove"></span>}
      >
        {currentPlacement && (
          <LayoutDialog
            campaign={campaign}
            setCampaign={setCampaign}
            setSavedCampaign={setSavedCampaign}
            currentPlacement={currentPlacement}
            setCurrentPlacement={setCurrentPlacement}
            isSingle={false}
            handlePut={handlePut}
          />
        )}
      </Modal>
    </>
  );
};

export default CampaigLayout;
