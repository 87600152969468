import { LoadingOutlined } from "@ant-design/icons";
import { Empty } from "antd";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import moment from "moment";
import { useEffect, useRef, useState } from "react";
import { InsightsCampaignDaily } from "../../../../types/events.interfaces";
import { PlacementStageInsight } from "../../../../types/templates.interfaces";

interface Props {
  loading: boolean;
  campaignDaily: InsightsCampaignDaily;
  placementStage: PlacementStageInsight;
}

const DailyViewsChart = ({ loading, campaignDaily, placementStage }: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [options, setOptions] = useState<Highcharts.Options>();

  useEffect(() => {
    const checkForZero = (param1: number, param2?: number): boolean => {
      if (!param2) {
        return param1 > 0;
      }
      return param1 > 0 && param2 > 0;
    };

    const getChartData = (dailyEvents: InsightsCampaignDaily) => {
      if (dailyEvents?.dates) {
        const _dates: string[] = dailyEvents.dates.map((value) =>
          moment(value.date).format("DD-MM-YYYY")
        );
        const _views: number[] = dailyEvents.dates.map(
          (value) => value.distinctViews
        );
        const _ctr: number[] = dailyEvents.dates.map((value) =>
          placementStage === PlacementStageInsight.pre
            ? checkForZero(
                value.distinctEmailClicks,
                value.totalDistinctAutoEmailSends
              )
              ? Number(
                  (
                    (value.distinctEmailClicks /
                      value.totalDistinctAutoEmailSends) *
                    100
                  ).toFixed(2)
                )
              : 0
            : checkForZero(value.distinctViews, value.totalDistinctClicks)
            ? Number(
                (
                  (value.totalDistinctClicks / value.distinctViews) *
                  100
                ).toFixed(2)
              )
            : 0
        );

        const _cr: number[] = dailyEvents.dates.map((value) =>
          placementStage === PlacementStageInsight.pre
            ? checkForZero(
                value.totalAutoEmailSalesCount,
                value.distinctEmailClicks
              )
              ? Number(
                  (
                    (value.totalAutoEmailSalesCount /
                      value.distinctEmailClicks) *
                    100
                  ).toFixed(2)
                )
              : 0
            : checkForZero(value.totalDistinctClicks, value.totalSalesCount)
            ? Number(
                (
                  (value.totalSalesCount / value.totalDistinctClicks) *
                  100
                ).toFixed(2)
              )
            : 0
        );

        // default Highcharts color
        const colors = [
          "#2caffe",
          "#544fc5",
          "#00e272",
          "#fe6a35",
          "#6b8abc",
          "#d568fb",
          "#2ee0ca",
          "#fa4b42",
          "#feb56a",
          "#91e8e1",
        ];

        const _chartOptions: Highcharts.Options = {
          title: {
            text: "",
          },
          subtitle: {
            text: "",
          },
          xAxis: [
            {
              categories: _dates,
              crosshair: true,
            },
          ],
          chart: {
            height: containerRef?.current?.clientHeight,
            width: containerRef?.current?.clientWidth,
          },
          yAxis: [
            {
              // Primary yAxis
              title: {
                text: "Impressions",
                style: {
                  color: colors[0],
                },
              },
              labels: {
                format: "{value}",
                style: {
                  color: colors[0],
                },
              },
              opposite: false,
            },
            {
              // Secondary yAxis
              title: {
                text: "CTR & CR",
                style: {
                  color: "#292426",
                },
              },
              labels: {
                format: "{value}%",
                style: {
                  color: "#292426",
                },
              },
              alignTicks: true,
              opposite: true,
            },
          ],
          tooltip: {
            shared: true,
          },
          legend: {
            align: "center",
            verticalAlign: "top",
            backgroundColor: "rgba(255,255,255,0.25)",
          },
          series: [
            {
              name: "Impressions",
              type: "column",
              data: _views,
              color: colors[0],
            },
            {
              name: "CTR",
              type: "spline",
              data: _ctr,
              yAxis: 1,
              color: colors[1],
              tooltip: {
                valueSuffix: `%`,
              },
            },
            {
              name: "CR",
              type: "spline",
              data: _cr,
              yAxis: 1,
              color: colors[2],
              tooltip: {
                valueSuffix: `%`,
              },
            },
          ],
          credits: {
            enabled: false,
          },
        };

        setOptions(_chartOptions);
      }
    };

    campaignDaily && getChartData(campaignDaily);
  }, [campaignDaily, placementStage]);

  return (
    <>
      <div className="widget-description"></div>
      {campaignDaily?.dates && campaignDaily?.dates.length > 0 ? (
        <div ref={containerRef} className="widget-content">
          {options && (
            <div
              id="chart-container"
              className={`chart-container ${
                loading ? "chart-container-loading" : ""
              }`}
            >
              <HighchartsReact highcharts={Highcharts} options={options} />

              {loading && <LoadingOutlined />}
            </div>
          )}
        </div>
      ) : (
        <>
          {loading ? <LoadingOutlined /> : <Empty className="mtop20 mbot20" />}
        </>
      )}
      <div className="widget-footer"></div>
    </>
  );
};

export default DailyViewsChart;
