import { Alert, Button, message } from "antd/lib";
import React, { useState } from "react";

import { EXPRESS_ONBOARDING } from "../../types/express.enum";
import Template from "./Template/Template";
import useExpress from "../../../../hooks/useExpress.hook";
import { ExpressCampaign } from "../../types/express.types";
import useAxios from "../../../../hooks/useAxios.hook";

interface Props {
  setCurrentStep: React.Dispatch<React.SetStateAction<EXPRESS_ONBOARDING>>;
}

const ExpressAppearance = ({ setCurrentStep }: Props) => {
  const {
    campaign,
    setCampaign,
    company,
    loading: expressLoading,
  } = useExpress();
  const { myAxios } = useAxios();
  const [loading, setLoading] = useState<boolean>(expressLoading);

  const postCampaignExpress = async (campaign: ExpressCampaign, id: string) => {
    setLoading(true);
    const { response } = await myAxios({
      method: "POST",
      url: `onboarding/express/campaign`,
      data: {
        company,
        campaign,
        onboardingStatus: {
          completed: false,
          next: EXPRESS_ONBOARDING.finish,
        },
        password: "",
      },
    });
    if (response?.data?.status) {
      response?.data?.result?.campaign &&
        setCampaign(response?.data?.result?.campaign);
      message.success({
        content: "Your appearance are saved",
        className: "express-message",
        duration: 1,
      });
      setCurrentStep(EXPRESS_ONBOARDING.finish);
    } else {
      message.error("Failed to continue", 1);
    }
    setLoading(false);
  };

  const handleBack = () => {
    setCurrentStep(EXPRESS_ONBOARDING.offers);
  };

  const handleContinue = () => {
    if (campaign && company) {
      postCampaignExpress(campaign, company.id);
    }
  };

  return (
    <>
      <div className="express-onboarding-description">
        <Alert
          description={
            <>
              Here you can customize the appearance of the campaign according to
              your brand style. <br />
              You can see a preview both for desktop and mobile.
            </>
          }
          type="info"
        />
      </div>
      {campaign &&
        campaign.placementSettings &&
        campaign.placementSettings[0] && (
          <Template placement={campaign.placementSettings[0]} />
        )}
      <div className="express-button">
        <Button
          className="express-button-back mright10"
          onClick={handleBack}
          type="default"
        >
          Back
        </Button>
        <Button
          className="express-button-success"
          loading={loading}
          type="default"
          onClick={handleContinue}
        >
          Continue
        </Button>
      </div>
    </>
  );
};

export default ExpressAppearance;
