import React, { MouseEvent, useEffect, useRef, useState } from "react";
import { EXPRESS_ONBOARDING } from "../../types/express.enum";
import {
  Alert,
  Button,
  Dropdown,
  Empty,
  Input,
  message,
  Modal,
  Switch,
  Tabs,
  Typography,
} from "antd";
import {
  ExpressCampaign,
  ExpressCategoryItem,
  initTemplateSettings,
} from "../../types/express.types";
import useExpress from "../../../../hooks/useExpress.hook";
import { Offer } from "../../../../types/offer.interfaces";
import { Pagination } from "../../../../types/global.interfaces";
import useAxios from "../../../../hooks/useAxios.hook";
import defaultOfferImg from "../../../../assets/images/offer-image.jpg";
import {
  InfoCircleOutlined,
  LoadingOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import Checkbox, { CheckboxChangeEvent } from "antd/lib/checkbox/Checkbox";
import { CompanyCategory } from "../../../../types/company.interfaces";

interface Props {
  setCurrentStep: React.Dispatch<React.SetStateAction<EXPRESS_ONBOARDING>>;
}

interface CpaFilter {
  name: string;
  value: number;
}

const cpaFilters: CpaFilter[] = [
  { name: "Higher - Lower", value: 1 },
  { name: "Lower - Higher", value: 2 },
];

const ExpressOffers = ({ setCurrentStep }: Props) => {
  const { myAxios } = useAxios();
  const {
    campaign,
    company,
    setCampaign,
    setCompany,
    setOnboardingStatus,
    loading: expressLoading,
  } = useExpress();

  const [selectedOffers, setSelectedOffers] = useState<Offer[]>(
    campaign?.offers.sort((a, b) => a.offerCpa.value - b.offerCpa.value) || []
  );
  const [triggers, setTriggers] = useState<string[]>([]);
  const [selectedTriggers, setSelectedTriggers] = useState<string[]>([]);
  const [offers, setOffers] = useState<Offer[]>([]);
  const [loading, setLoading] = useState<boolean>(expressLoading);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [pagination, setPagination] = useState<Pagination>({
    page: 1,
    pageSize: 2000,
  });
  const [filters, setFilters] = useState({
    // TODO: Update filters to order by CPA
    advertiserIds: [],
    regionCodes: [],
    categories: [] as string[],
    excludedCategories: [],
    brandswopExclusive: "all",
    type: "all",
  });
  const [offersTab, setOffersTab] = useState<string>("available");
  const [open, setOpen] = useState<boolean>(false);
  const [CPAOrder, setCPAOrder] = useState<CpaFilter | null>(cpaFilters[0]);
  const [CPAOrderDefault, setCPAOrderDefault] = useState<boolean>(false);

  const { response, axiosFetch } = useAxios();
  // const { response: responseApproval, axiosFetch: axiosApproval } = useAxios();

  const fetchOffers = async (companyId: string) => {
    setLoading(true);
    await axiosFetch({
      method: "post",
      url: `offers/getactiveoffers/${companyId}?page=${pagination.page}&pageSize=${pagination.pageSize}`,
      data: { ...filters, networkIdentifiers: ["cj"] },
    });
  };

  // const fetchApprovalOffers = async (companyId: string) => {
  //   setLoading(true);
  //   await axiosApproval({
  //     method: "post",
  //     url: `offers/pendingapproval/${companyId}?page=${pagination.page}&pageSize=${pagination.pageSize}`,
  //     data: filters,
  //   });
  // };

  const [categories, setCategories] = useState<CompanyCategory[]>([]);
  const [filteredCategories, setFilteredCategories] = useState<
    CompanyCategory[]
  >([]);
  const [searchCategories, setSearchCategories] = useState<string>("");
  const [selectedCategories, setSelectedCategories] = useState<
    ExpressCategoryItem[]
  >(company?.excludedCategories || []);
  const categoriesRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setSelectedOffers(campaign?.offers || []);
  }, [campaign, campaign?.offers]);

  useEffect(() => {
    if (company) {
      switch (offersTab) {
        case "available":
          fetchOffers(company.id);
          break;
        // case "needapprove":
        //   fetchApprovalOffers(company.id);
        //   break;

        default:
          // fetchOffers(company.id);
          break;
      }
    }
    // eslint-disable-next-line
  }, [filters, pagination, offersTab]);

  useEffect(() => {
    setCampaign(
      (prevState) => prevState && { ...prevState, offers: selectedOffers }
    );
    setSelectedTriggersFromOffers(selectedOffers);
    // eslint-disable-next-line
  }, [selectedOffers]);

  useEffect(() => {
    if (CPAOrder?.value === 2) {
      setOffers((prevState) => {
        return [...prevState].sort(
          (a: Offer, b: Offer) => a.offerCpa.value - b.offerCpa.value
        );
      });
    } else if (CPAOrder?.value === 1) {
      setOffers((prevState) => {
        return [...prevState].sort(
          (a: Offer, b: Offer) => b.offerCpa.value - a.offerCpa.value
        );
      });
    }
  }, [CPAOrder]);

  useEffect(() => {
    if (response) {
      if (response.status) {
        const _offers = response.result?.data;
        if (CPAOrder?.value === 2) {
          // smallest to largest
          _offers.sort(
            (a: Offer, b: Offer) => a.offerCpa.value - b.offerCpa.value
          );
        } else if (CPAOrder?.value === 1) {
          _offers.sort(
            // highest to smallest
            (a: Offer, b: Offer) => b.offerCpa.value - a.offerCpa.value
          );
        }

        setTotalPages(response.result?.totalPages);
        setOffers(_offers);
        if (campaign?.offers.length === 0 && selectedOffers.length === 0) {
          if (CPAOrderDefault) {
            setSelectedOffers(
              _offers.sort(
                (a: Offer, b: Offer) => a.offerCpa.value - b.offerCpa.value
              ) || []
            );
          } else {
            setSelectedOffers(
              _offers.sort(
                (a: Offer, b: Offer) => b.offerCpa.value - a.offerCpa.value
              ) || []
            );
          }
        }
        setTriggersFromOffers(_offers);

        setLoading(false);
      } else {
        message.error(`Something went wrong!`);
      }
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [response]);

  // useEffect(() => {
  //   if (responseApproval) {
  //     if (responseApproval.status) {
  //       const _offers = responseApproval.result?.data;
  //       setTotalPages(responseApproval.result?.totalPages);
  //       setOffers(_offers);
  //       setTriggersFromOffers(_offers);
  //       setLoading(false);
  //     } else {
  //       message.error(`Something went wrong!`);
  //     }
  //     setLoading(false);
  //   }
  //   // eslint-disable-next-line
  // }, [responseApproval]);

  const handleChangeTabs = (activeKey: string) => {
    setOffersTab(activeKey);
  };

  // Functions to toggle offers description
  const setTriggersFromOffers = (_offer: Offer[]) => {
    _offer.forEach((value: Offer) => {
      !triggers.includes(value.id) &&
        setTriggers((prevState) => [...prevState, value.id]);
    });
  };

  const setSelectedTriggersFromOffers = (_offer: Offer[]) => {
    _offer.forEach((value: Offer) => {
      !selectedTriggers.includes(value.id) &&
        setSelectedTriggers((prevState) => [...prevState, value.id]);
    });
  };

  const handleSelect = (_offer: Offer) => {
    setSelectedOffers((prevState) => [...prevState, _offer]);
  };

  const handleRemove = (id: string) => {
    setSelectedOffers((prevState) =>
      prevState.filter((value: Offer) => value.id !== id)
    );
  };

  const getOfferCategoriesName = (
    cat?: ExpressCategoryItem[] | undefined
  ): string => {
    const catName: string[] = cat
      ? cat.map((value: ExpressCategoryItem) => value.name)
      : [];
    return catName.join(", ");
  };

  const handleCheckedCategories = (id: string): boolean => {
    const isChecked: boolean = !!selectedCategories?.find(
      (value: ExpressCategoryItem) => value.categoryId === id
    );
    return isChecked;
  };

  const handleRemoveCategoryExclude = (categoryId: string) => {
    const _selectedCategories: ExpressCategoryItem[] = structuredClone(
      selectedCategories
    ).filter((f: ExpressCategoryItem) => f.categoryId !== categoryId);
    setSelectedCategories(_selectedCategories);
  };

  const handleChangeCategoryExclude = (
    checked: boolean,
    category: CompanyCategory
  ) => {
    let _selectedCategories: ExpressCategoryItem[] = [];

    if (checked) {
      _selectedCategories = [
        ...selectedCategories,
        { categoryId: category.id, name: category.name },
      ];
    } else {
      selectedCategories?.forEach((value: ExpressCategoryItem) => {
        if (value.categoryId !== category.id) {
          _selectedCategories.push(value);
        }
      });
    }

    setSelectedCategories(_selectedCategories);
    if (company) {
      if (campaign?.autoOffers) {
        setCompany({ ...company, excludedCategories: _selectedCategories });
      } else {
        setFilters({
          advertiserIds: [],
          regionCodes: [],
          categories:
            _selectedCategories?.map(
              (i: ExpressCategoryItem) => i.categoryId
            ) || [],
          excludedCategories: [],
          brandswopExclusive: "all",
          type: "all",
        });
      }
    }
  };

  const openConfirmModal = (id: string) => {
    Modal.confirm({
      title: "Do you want to remove this offer from the selected offers?",
      content: "You can add it again from the available offers",
      okText: "Remove",
      icon: <></>,
      maskClosable: true,
      width: 520,
      closable: true,
      closeIcon: (
        <span className="ant-modal-close-icon">
          <span className="icon icon-remove"></span>
        </span>
      ),
      cancelText: "Cancel",
      cancelButtonProps: {
        className: "button-default",
      },
      okButtonProps: {
        className: "button-danger",
      },
      onOk: () => handleRemove(id),
    });
  };

  const handleClearCategories = (event: MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    setSelectedCategories([]);
    setCompany(
      (prevState) => prevState && { ...prevState, excludedCategories: [] }
    );
    categoriesRef?.current?.focus();
  };

  const checkOfferSelected = (id: string): boolean => {
    const promotionIds = selectedOffers.map((offer: Offer) => offer.id);
    return promotionIds.includes(id);
  };

  const handleBack = () => {
    setCurrentStep(EXPRESS_ONBOARDING.details);
  };

  const handleContinue = () => {
    if (campaign && company) {
      postCampaignExpress(campaign);
    }
  };

  const postCampaignExpress = async (campaign: ExpressCampaign) => {
    setLoading(true);
    const { response } = await myAxios({
      method: "POST",
      url: `onboarding/express/campaign`,
      data: {
        company,
        campaign: {
          ...campaign,
          placementSettings: [
            {
              ...campaign?.placementSettings?.[0],
              templateSettings:
                // campaign?.placementSettings?.[0].templateSettings ||
                initTemplateSettings,
            },
          ],
        },
        onboardingStatus: {
          completed: false,
          next: EXPRESS_ONBOARDING.appearance,
        },
        password: "",
      },
    });
    if (response?.data?.status) {
      response?.data?.result?.campaign &&
        setCampaign(response?.data?.result?.campaign);
      message.success({
        content: "Your offers are saved",
        className: "express-message",
        duration: 1,
      });
      setCurrentStep(EXPRESS_ONBOARDING.appearance);
    } else {
      message.error("Failed to continue", 1);
    }
    setLoading(false);
  };

  const getCompanyCategories = async () => {
    const { response } = await myAxios({
      method: "GET",
      url: "CompanyCategory",
    });
    if (response?.data?.status) {
      setCategories(response?.data?.result);
      setFilteredCategories(response?.data?.result);
    } else {
      message.error("Failed to get categories", 1);
    }
  };

  useEffect(() => {
    if (categories) {
      if (searchCategories) {
        const newCategories: CompanyCategory[] = structuredClone(categories);
        setFilteredCategories(
          newCategories.filter((f) =>
            f.name.toLowerCase().includes(searchCategories.toLowerCase())
          ) || []
        );
      } else {
        setFilteredCategories(categories);
      }
    }
    // eslint-disable-next-line
  }, [searchCategories]);

  useEffect(() => {
    getCompanyCategories();
    document.title = "BrandSwap Express - Onboarding partners";
    setOnboardingStatus(
      (prevState) =>
        prevState && { completed: false, next: EXPRESS_ONBOARDING.appearance }
    );
    // eslint-disable-next-line
  }, []);

  const handleSwitchAutoOffer = (value: boolean) => {
    setSelectedCategories([]);
    if (campaign && company) {
      const _newCampaign = { ...campaign, autoOffers: value };
      value && setCompany({ ...company, excludedCategories: [] });
      setCampaign(_newCampaign);
      // postCampaignExpress(_newCampaign);
    }
  };

  const truncateString = (str: string, maxLength = 75) => {
    if (str.length > maxLength) {
      return str.slice(0, maxLength) + "...";
    } else {
      return str;
    }
  };

  return (
    <>
      <div className="express-onboarding-description">
        <Alert
          description={
            <>
              Please select the offers you want in your campaign. <br />
              We have preselected some for you, but you can remove them and add
              your own.
            </>
          }
          type="info"
        />
      </div>
      <div className="express-layout">
        <div className="widget widget-express widget--small">
          <div className="partners-options">
            <div className="partners-content">
              <div className="express-onboard-offers">
                <div className="switch-wrap switch-wrap--express">
                  <Switch
                    id="autoOffers"
                    size="default"
                    checked={campaign?.autoOffers}
                    onChange={handleSwitchAutoOffer}
                  />
                  <label
                    className="mleft5 font-hover"
                    htmlFor="open-access"
                  ></label>
                </div>
                <div className="content-wrap">
                  <label
                    htmlFor="autoOffers"
                    className="eontitle eontitle--black"
                  >
                    {campaign?.autoOffers ? (
                      <>
                        Automatically selected <i>(Recommended)</i>
                      </>
                    ) : (
                      <>Manually selected</>
                    )}
                  </label>
                  <div className="eoncontent">
                    {campaign?.autoOffers ? (
                      <>
                        {" "}
                        Allow our AI to choose the Ad Partner Deals that will
                        maximise your revenue
                      </>
                    ) : (
                      <>
                        {" "}
                        You need to selected offers based on your own interests.
                      </>
                    )}
                    <Link
                      to="#"
                      id="guide-express-partners-link"
                      className="eonlink"
                    >
                      Learn more about it
                    </Link>
                  </div>
                </div>
              </div>
              {campaign?.autoOffers && (
                <div className="express-row mtop20">
                  <div className="express-label">
                    Categories you want to exclude: {selectedCategories.length}{" "}
                    <InfoCircleOutlined id="guide-deals-exclude" />
                  </div>
                  <div className="express-input express-dropdown">
                    <div className="label-input-dropdown">
                      <Dropdown
                        dropdownRender={(menu) => {
                          return (
                            <div className="dropdown">
                              <div className="dropdown-search">
                                <SearchOutlined className="search-icon" />
                                <input
                                  ref={categoriesRef}
                                  type="text"
                                  placeholder="Search"
                                  className="default-input"
                                  value={searchCategories}
                                  onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>
                                  ) => setSearchCategories(event.target.value)}
                                />
                              </div>
                              {selectedCategories.length > 0 && (
                                <>
                                  <div className="dropdown-title">
                                    Selected Categories:
                                  </div>
                                  <ul className="list-tags">
                                    {selectedCategories?.map(
                                      (category: ExpressCategoryItem) => {
                                        return (
                                          <li
                                            key={`selected-cat-${category.categoryId}`}
                                          >
                                            {category.name}
                                            <span
                                              className="icon icon-remove mleft10 font-hover"
                                              onClick={() =>
                                                handleRemoveCategoryExclude(
                                                  category.categoryId
                                                )
                                              }
                                            ></span>
                                          </li>
                                        );
                                      }
                                    )}
                                    <span
                                      className="font-xsmall font-hover clear-categories"
                                      onClick={handleClearCategories}
                                    >
                                      Clear all
                                    </span>
                                  </ul>
                                </>
                              )}
                              <div className="dropdown-title">
                                Categories found:
                              </div>
                              {filteredCategories.length > 0 ? (
                                <>
                                  <ul>
                                    {filteredCategories?.map(
                                      (category: CompanyCategory) => {
                                        return (
                                          <li
                                            key={`details-cat-${category.id}`}
                                          >
                                            <Checkbox
                                              checked={handleCheckedCategories(
                                                category.id
                                              )}
                                              onChange={(
                                                e: CheckboxChangeEvent
                                              ) =>
                                                handleChangeCategoryExclude(
                                                  e.target.checked,
                                                  category
                                                )
                                              }
                                              className="checkbox-active checkbox-express"
                                            >
                                              {category.name}
                                            </Checkbox>
                                          </li>
                                        );
                                      }
                                    )}
                                  </ul>
                                </>
                              ) : (
                                <>
                                  <span className="font-xsmall">
                                    No item found
                                  </span>
                                </>
                              )}
                            </div>
                          );
                        }}
                        trigger={["click"]}
                      >
                        <Typography.Link>
                          <div className="label-input-wrapper label-input-select">
                            <label htmlFor="categories">
                              <Input
                                disabled={true}
                                value={getOfferCategoriesName(
                                  selectedCategories
                                )}
                                className="express-disabled"
                                placeholder="Excluded categories"
                              />
                              <span className="express-dropdown-icon"></span>
                            </label>
                          </div>
                        </Typography.Link>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        {!campaign?.autoOffers && (
          <>
            <div className="table-wrapper table-scoll-wrapper">
              <div className="table-title table-title--express">
                Selected offers
              </div>
              <div className="table-scrollable table-offers">
                <table>
                  <thead>
                    <tr>
                      <th>Advertiser</th>
                      <th>Description</th>
                      <th
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setCPAOrderDefault((prevState) => !prevState);
                        }}
                      >
                        CPA
                        <svg
                          style={{ marginLeft: "5px" }}
                          fill="#63686a"
                          height="12px"
                          width="12px"
                          version="1.1"
                          id="Capa_1"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 490 490"
                        >
                          <g>
                            <polygon
                              points="85.877,154.014 85.877,428.309 131.706,428.309 131.706,154.014 180.497,221.213 217.584,194.27 108.792,44.46 
		0,194.27 37.087,221.213 	"
                            />
                            <polygon
                              points="404.13,335.988 404.13,61.691 358.301,61.691 358.301,335.99 309.503,268.787 272.416,295.73 381.216,445.54 
		490,295.715 452.913,268.802 	"
                            />
                          </g>
                        </svg>
                      </th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>

                  <tbody>
                    {selectedOffers
                      .sort((a: Offer, b: Offer) => {
                        if (CPAOrderDefault) {
                          return a.offerCpa.value - b.offerCpa.value;
                        } else {
                          return b.offerCpa.value - a.offerCpa.value;
                        }
                      })
                      .map((offer: Offer, index: number) => {
                        return (
                          <tr key={`selected-offers${index}`}>
                            <td className="td-250">
                              <span className="flex">
                                <img
                                  className="table-image table-image-big mright10"
                                  src={
                                    offer.imageUrl
                                      ? offer.imageUrl
                                      : defaultOfferImg
                                  }
                                  alt=""
                                />
                                <span className="break-word advertiser-name">
                                  {offer.advertiser.name}
                                </span>
                              </span>
                            </td>
                            <td className="break-word">
                              <span className="max-4lines">
                                {truncateString(offer.description)}
                              </span>
                            </td>
                            <td>{offer.offerCpa.display}</td>
                            <td
                              className={
                                offer?.displayStatus === "Active"
                                  ? "status-active"
                                  : ""
                              }
                            >
                              {offer?.displayStatus || ""}
                            </td>
                            <td>
                              <span className="table-actions">
                                <span
                                  className="label label-select label-select--danger table-label"
                                  onClick={() => openConfirmModal(offer.id)}
                                >
                                  Remove
                                </span>
                              </span>
                            </td>
                          </tr>
                        );
                      })}

                    {selectedOffers.length === 0 && (
                      <tr className="express-empty">
                        <td className="text-center" colSpan={6}>
                          <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description="No offers"
                          />
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              <div className="add-other-offers" onClick={() => setOpen(true)}>
                Add another offer
              </div>
            </div>
          </>
        )}
        <div className="express-button">
          <Button
            className="express-button-back mright10"
            onClick={handleBack}
            type="default"
          >
            Back
          </Button>
          <Button
            className="express-button-success"
            loading={loading}
            disabled={!campaign?.autoOffers && selectedOffers.length < 3}
            type="default"
            onClick={handleContinue}
          >
            Continue
          </Button>
        </div>
      </div>
      <Modal
        className="modal-express"
        title={<></>}
        footer={<></>}
        maskClosable={true}
        open={open}
        width={724}
        closable={true}
        afterClose={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        closeIcon={<span className="icon icon-remove"></span>}
        // closeIcon={<></>}
      >
        <div className="table-wrapper table-wrapper--modal table-scrollable-wrapper">
          <Tabs
            className="express-offers-tabs"
            moreIcon={false}
            animated={false}
            onChange={handleChangeTabs}
            activeKey={offersTab}
            items={[
              {
                label: <>Ready to use</>,
                key: "available",
                children: (
                  <>
                    <div className="layout-options">
                      <div className="filters">
                        <div className="filter-dropdown">
                          <Dropdown
                            dropdownRender={(menu) => {
                              return (
                                <div className="dropdown">
                                  <div className="dropdown-search">
                                    <SearchOutlined className="search-icon" />
                                    <input
                                      ref={categoriesRef}
                                      type="text"
                                      className="default-input"
                                      value={searchCategories}
                                      onChange={(
                                        event: React.ChangeEvent<HTMLInputElement>
                                      ) =>
                                        setSearchCategories(event.target.value)
                                      }
                                    />
                                    <span
                                      className="close-icon"
                                      onClick={handleClearCategories}
                                    >
                                      <span className="icon icon-remove"></span>
                                    </span>
                                  </div>

                                  {selectedCategories.length > 0 && (
                                    <>
                                      <div className="dropdown-title">
                                        Selected Categories:
                                      </div>
                                      <ul className="list-tags">
                                        {selectedCategories?.map(
                                          (category: ExpressCategoryItem) => {
                                            return (
                                              <li
                                                key={`selected-cat-${category.categoryId}`}
                                              >
                                                {category.name}
                                                <span
                                                  className="icon icon-remove mleft10 font-hover"
                                                  onClick={() =>
                                                    handleRemoveCategoryExclude(
                                                      category.categoryId
                                                    )
                                                  }
                                                ></span>
                                              </li>
                                            );
                                          }
                                        )}
                                      </ul>
                                    </>
                                  )}
                                  <div className="dropdown-title">
                                    Categories found:
                                  </div>
                                  {filteredCategories.length > 0 ? (
                                    <>
                                      <ul>
                                        {filteredCategories?.map(
                                          (category: CompanyCategory) => {
                                            return (
                                              <li
                                                key={`details-cat-${category.id}`}
                                              >
                                                <Checkbox
                                                  checked={handleCheckedCategories(
                                                    category.id
                                                  )}
                                                  onChange={(
                                                    e: CheckboxChangeEvent
                                                  ) =>
                                                    handleChangeCategoryExclude(
                                                      e.target.checked,
                                                      category
                                                    )
                                                  }
                                                  className="checkbox-active"
                                                >
                                                  {category.name}
                                                </Checkbox>
                                              </li>
                                            );
                                          }
                                        )}
                                      </ul>
                                    </>
                                  ) : (
                                    <>
                                      <span className="font-xsmall">
                                        No item found
                                      </span>
                                    </>
                                  )}
                                </div>
                              );
                            }}
                            trigger={["click"]}
                          >
                            <Typography.Link>
                              <span
                                className="dropdown-label dropdown-label--express"
                                onClick={(e) => e.preventDefault()}
                              >
                                Offer category:
                                <span className="label-count">
                                  {selectedCategories.length === 0
                                    ? "all"
                                    : selectedCategories.length}
                                </span>
                              </span>
                            </Typography.Link>
                          </Dropdown>
                        </div>
                        <div className="filter-dropdown">
                          <Dropdown
                            dropdownRender={(menu) => {
                              return (
                                <div className="dropdown dropdown-cpa">
                                  {cpaFilters.length > 0 ? (
                                    <>
                                      <ul>
                                        {cpaFilters?.map((item: CpaFilter) => {
                                          return (
                                            <li
                                              key={`details-cat-${item.value}`}
                                              className={
                                                item.value === CPAOrder?.value
                                                  ? "cpa-active"
                                                  : ""
                                              }
                                              onClick={() => {
                                                setCPAOrder(item);
                                              }}
                                            >
                                              {item.name}
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    </>
                                  ) : (
                                    <>
                                      <span className="font-xsmall">
                                        No items found
                                      </span>
                                    </>
                                  )}
                                </div>
                              );
                            }}
                            trigger={["click"]}
                          >
                            <Typography.Link>
                              <span
                                className="dropdown-label dropdown-label--express"
                                onClick={(e) => e.preventDefault()}
                              >
                                {!CPAOrder && "Order by CPA"}
                                {
                                  cpaFilters.find(
                                    (item) => item.value === CPAOrder?.value
                                  )?.name
                                }
                              </span>
                            </Typography.Link>
                          </Dropdown>
                        </div>
                      </div>
                    </div>
                    <div
                      className="table-scrollable mbot30"
                      style={{ minHeight: "340px" }}
                    >
                      <table>
                        <thead>
                          <tr>
                            <th>Advertiser</th>
                            <th>Description</th>
                            <th>CPA</th>
                            <th>Status</th>
                            <th></th>
                          </tr>
                        </thead>

                        <tbody>
                          {offers.map((offer: Offer, index: number) => {
                            return (
                              <tr key={`offer-${index}`}>
                                <td className="td-250">
                                  <span className="flex">
                                    <img
                                      className="table-image table-image-big mright10"
                                      src={
                                        offer.imageUrl
                                          ? offer.imageUrl
                                          : defaultOfferImg
                                      }
                                      alt=""
                                    />
                                    <span className="break-word advertiser-name">
                                      {offer.advertiser.name}
                                    </span>
                                  </span>
                                </td>
                                <td className="break-word">
                                  <span className="font-color max-4lines">
                                    {truncateString(offer.description)}
                                  </span>
                                </td>
                                <td>{offer.offerCpa.display}</td>
                                <td
                                  className={
                                    offer?.displayStatus === "Active"
                                      ? "status-active"
                                      : ""
                                  }
                                >
                                  {offer?.displayStatus || ""}
                                </td>
                                <td>
                                  <span className="table-actions">
                                    {checkOfferSelected(offer.id) ? (
                                      <span className="label label-select active table-label">
                                        Selected
                                      </span>
                                    ) : (
                                      <span
                                        className="label label-select table-label"
                                        onClick={() => handleSelect(offer)}
                                      >
                                        Select
                                      </span>
                                    )}
                                  </span>
                                </td>
                              </tr>
                            );
                          })}
                          {loading ? (
                            <tr
                              className={
                                offers.length > 0 ? "loader-absolute" : ""
                              }
                            >
                              <td className="text-center loader-td" colSpan={6}>
                                <LoadingOutlined />
                              </td>
                            </tr>
                          ) : (
                            <>
                              {offers.length === 0 && (
                                <tr className="express-empty">
                                  <td className="text-center" colSpan={6}>
                                    <Empty
                                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                                      description="No offers"
                                    />
                                  </td>
                                </tr>
                              )}
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                    {/* <div className="pagination">
                      <div
                        className="pagination-prev"
                        onClick={() =>
                          pagination.page > 1 &&
                          setPagination((prevState) => ({
                            ...prevState,
                            page: prevState.page - 1,
                          }))
                        }
                      >
                        <span className="pagination-box"></span>
                        <span className="pagination-label">Preview</span>
                      </div>

                      <ul>
                        <>
                          {Array.from(
                            { length: totalPages },
                            (_, i) => i + 1
                          ).map((page: number) => {
                            if (
                              (page < pagination.page + 3 &&
                                page > pagination.page - 3) ||
                              page === 1 ||
                              page === totalPages
                            ) {
                              return (
                                <React.Fragment key={page}>
                                  {" "}
                                  {page > 2 && page + 2 <= pagination.page && (
                                    <span className="icon icon-pagination"></span>
                                  )}
                                  <li
                                    onClick={() =>
                                      page !== pagination.page &&
                                      setPagination((prevState) => ({
                                        ...prevState,
                                        page,
                                      }))
                                    }
                                    className={
                                      page === pagination.page ? "active" : ""
                                    }
                                  >
                                    <span className="pagination-box">
                                      {page}
                                    </span>
                                  </li>
                                  {page + 1 < totalPages &&
                                    page - 2 >= pagination.page && (
                                      <span className="icon icon-pagination"></span>
                                    )}
                                </React.Fragment>
                              );
                            }
                            return (
                              <React.Fragment
                                key={`rf${page}`}
                              ></React.Fragment>
                            );
                          })}
                        </>
                      </ul>

                      <div
                        className="pagination-next"
                        onClick={() =>
                          pagination.page < totalPages &&
                          setPagination((prevState) => ({
                            ...prevState,
                            page: prevState.page + 1,
                          }))
                        }
                      >
                        <span className="pagination-label">Next</span>
                        <span className="pagination-box"></span>
                      </div>
                    </div> */}
                    {/* <div className="express-button-modal">
                      <Button
                        className="express-button-back mright10"
                        onClick={() => setOpen(false)}
                        type="default"
                      >
                        Cancel
                      </Button>
                      <Button
                        className="express-button-success"
                        type="default"
                        onClick={() => setOpen(false)}
                      >
                        Add Selected
                      </Button>
                    </div> */}
                  </>
                ),
              },
              // {
              //   label: <>Requiring advertiser’s approval</>,
              //   key: "needapprove",
              //   children: (
              //     <>
              //       <div className="layout-options">
              //         <div className="filters">
              //           <div className="filter-dropdown">
              //             <Dropdown
              //               dropdownRender={(menu) => {
              //                 return (
              //                   <div className="dropdown">
              //                     <div className="dropdown-search">
              //                       <SearchOutlined className="search-icon" />
              //                       <input
              //                         ref={categoriesRef}
              //                         type="text"
              //                         className="default-input"
              //                         value={searchCategories}
              //                         onChange={(
              //                           event: React.ChangeEvent<HTMLInputElement>
              //                         ) =>
              //                           setSearchCategories(event.target.value)
              //                         }
              //                       />
              //                       <span
              //                         className="close-icon"
              //                         onClick={handleClearCategories}
              //                       >
              //                         <span className="icon icon-remove"></span>
              //                       </span>
              //                     </div>

              //                     {selectedCategories.length > 0 && (
              //                       <>
              //                         <div className="dropdown-title">
              //                           Selected Categories:
              //                         </div>
              //                         <ul className="list-tags">
              //                           {selectedCategories?.map(
              //                             (category: ExpressCategoryItem) => {
              //                               return (
              //                                 <li
              //                                   key={`selected-cat-${category.categoryId}`}
              //                                 >
              //                                   {category.name}
              //                                   <span
              //                                     className="icon icon-remove mleft10 font-hover"
              //                                     onClick={() =>
              //                                       handleRemoveCategoryExclude(
              //                                         category.categoryId
              //                                       )
              //                                     }
              //                                   ></span>
              //                                 </li>
              //                               );
              //                             }
              //                           )}
              //                         </ul>
              //                       </>
              //                     )}
              //                     <div className="dropdown-title">
              //                       Categories found:
              //                     </div>
              //                     {filteredCategories.length > 0 ? (
              //                       <>
              //                         <ul>
              //                           {filteredCategories?.map(
              //                             (category: CompanyCategory) => {
              //                               return (
              //                                 <li
              //                                   key={`details-cat-${category.id}`}
              //                                 >
              //                                   <Checkbox
              //                                     checked={handleCheckedCategories(
              //                                       category.id
              //                                     )}
              //                                     onChange={(
              //                                       e: CheckboxChangeEvent
              //                                     ) =>
              //                                       handleChangeCategoryExclude(
              //                                         e.target.checked,
              //                                         category
              //                                       )
              //                                     }
              //                                     className="checkbox-active"
              //                                   >
              //                                     {category.name}
              //                                   </Checkbox>
              //                                 </li>
              //                               );
              //                             }
              //                           )}
              //                         </ul>
              //                       </>
              //                     ) : (
              //                       <>
              //                         <span className="font-xsmall">
              //                           No item found
              //                         </span>
              //                       </>
              //                     )}
              //                   </div>
              //                 );
              //               }}
              //               trigger={["click"]}
              //             >
              //               <Typography.Link>
              //                 <span
              //                   className="dropdown-label dropdown-label--express"
              //                   onClick={(e) => e.preventDefault()}
              //                 >
              //                   Offer category:
              //                   <span className="label-count">
              //                     {selectedCategories.length === 0
              //                       ? "all"
              //                       : selectedCategories.length}
              //                   </span>
              //                 </span>
              //               </Typography.Link>
              //             </Dropdown>
              //           </div>
              //           <div className="filter-dropdown">
              //             <Dropdown
              //               dropdownRender={(menu) => {
              //                 return (
              //                   <div className="dropdown dropdown-cpa">
              //                     {cpaFilters.length > 0 ? (
              //                       <>
              //                         <ul>
              //                           {cpaFilters?.map((item: CpaFilter) => {
              //                             return (
              //                               <li
              //                                 key={`details-cat-${item.value}`}
              //                                 className={
              //                                   item.value === CPAOrder?.value
              //                                     ? "cpa-active"
              //                                     : ""
              //                                 }
              //                                 onClick={() => {
              //                                   setCPAOrder(item);
              //                                 }}
              //                               >
              //                                 {item.name}
              //                               </li>
              //                             );
              //                           })}
              //                         </ul>
              //                       </>
              //                     ) : (
              //                       <>
              //                         <span className="font-xsmall">
              //                           No items found
              //                         </span>
              //                       </>
              //                     )}
              //                   </div>
              //                 );
              //               }}
              //               trigger={["click"]}
              //             >
              //               <Typography.Link>
              //                 <span
              //                   className="dropdown-label dropdown-label--express"
              //                   onClick={(e) => e.preventDefault()}
              //                 >
              //                   {!CPAOrder && "Order by CPA"}
              //                   {
              //                     cpaFilters.find(
              //                       (item) => item.value === CPAOrder?.value
              //                     )?.name
              //                   }
              //                 </span>
              //               </Typography.Link>
              //             </Dropdown>
              //           </div>
              //         </div>
              //       </div>
              //       <div
              //         className="table-scrollable"
              //         style={{ minHeight: "340px" }}
              //       >
              //         <table>
              //           <thead>
              //             <tr>
              //               <th>Advertiser</th>
              //               <th>Description</th>
              //               <th>CPA</th>
              //               <th>Status</th>
              //               <th></th>
              //             </tr>
              //           </thead>

              //           <tbody>
              //             {offers.map((offer: Offer, index: number) => {
              //               return (
              //                 <tr key={`offer-${index}`}>
              //                   <td className="td-250">
              //                     <span className="flex">
              //                       <img
              //                         className="table-image table-image-big mright10"
              //                         src={
              //                           offer.imageUrl
              //                             ? offer.imageUrl
              //                             : defaultOfferImg
              //                         }
              //                         alt=""
              //                       />
              //                       <span className="break-word advertiser-name">
              //                         {offer.advertiser.name}
              //                       </span>
              //                     </span>
              //                   </td>
              //                   <td className="break-word">
              //                     <span className="font-color max-4lines">
              //                       {truncateString(offer.description)}
              //                     </span>
              //                   </td>
              //                   <td>{offer.offerCpa.display}</td>
              //                   <td
              //                     className={
              //                       offer?.displayStatus === "Active"
              //                         ? "status-active"
              //                         : ""
              //                     }
              //                   >
              //                     {offer?.displayStatus || ""}
              //                   </td>
              //                   <td>
              //                     <span className="table-actions">
              //                       {checkOfferSelected(offer.id) ? (
              //                         <span className="label label-select active table-label">
              //                           Selected
              //                         </span>
              //                       ) : (
              //                         <span
              //                           className="label label-select table-label"
              //                           onClick={() => handleSelect(offer)}
              //                         >
              //                           Select
              //                         </span>
              //                       )}
              //                     </span>
              //                   </td>
              //                 </tr>
              //               );
              //             })}
              //             {loading ? (
              //               <tr
              //                 className={
              //                   offers.length > 0 ? "loader-absolute" : ""
              //                 }
              //               >
              //                 <td className="text-center loader-td" colSpan={6}>
              //                   <LoadingOutlined />
              //                 </td>
              //               </tr>
              //             ) : (
              //               <>
              //                 {offers.length === 0 && (
              //                   <tr className="express-empty">
              //                     <td className="text-center" colSpan={6}>
              //                       <Empty
              //                         image={Empty.PRESENTED_IMAGE_SIMPLE}
              //                         description="No offers"
              //                       />
              //                     </td>
              //                   </tr>
              //                 )}
              //               </>
              //             )}
              //           </tbody>
              //         </table>
              //       </div>
              //       <div className="pagination">
              //         <div
              //           className="pagination-prev"
              //           onClick={() =>
              //             pagination.page > 1 &&
              //             setPagination((prevState) => ({
              //               ...prevState,
              //               page: prevState.page - 1,
              //             }))
              //           }
              //         >
              //           <span className="pagination-box"></span>
              //           <span className="pagination-label">Preview</span>
              //         </div>

              //         <ul>
              //           <>
              //             {Array.from(
              //               { length: totalPages },
              //               (_, i) => i + 1
              //             ).map((page: number) => {
              //               if (
              //                 (page < pagination.page + 3 &&
              //                   page > pagination.page - 3) ||
              //                 page === 1 ||
              //                 page === totalPages
              //               ) {
              //                 return (
              //                   <React.Fragment key={page}>
              //                     {" "}
              //                     {page > 2 && page + 2 <= pagination.page && (
              //                       <span className="icon icon-pagination"></span>
              //                     )}
              //                     <li
              //                       onClick={() =>
              //                         page !== pagination.page &&
              //                         setPagination((prevState) => ({
              //                           ...prevState,
              //                           page,
              //                         }))
              //                       }
              //                       className={
              //                         page === pagination.page ? "active" : ""
              //                       }
              //                     >
              //                       <span className="pagination-box">
              //                         {page}
              //                       </span>
              //                     </li>
              //                     {page + 1 < totalPages &&
              //                       page - 2 >= pagination.page && (
              //                         <span className="icon icon-pagination"></span>
              //                       )}
              //                   </React.Fragment>
              //                 );
              //               }
              //               return (
              //                 <React.Fragment
              //                   key={`rf${page}`}
              //                 ></React.Fragment>
              //               );
              //             })}
              //           </>
              //         </ul>

              //         <div
              //           className="pagination-next"
              //           onClick={() =>
              //             pagination.page < totalPages &&
              //             setPagination((prevState) => ({
              //               ...prevState,
              //               page: prevState.page + 1,
              //             }))
              //           }
              //         >
              //           <span className="pagination-label">Next</span>
              //           <span className="pagination-box"></span>
              //         </div>
              //       </div>
              //       <div className="express-button-modal">
              //         <Button
              //           className="express-button-back mright10"
              //           onClick={() => setOpen(false)}
              //           type="default"
              //         >
              //           Cancel
              //         </Button>
              //         <Button
              //           className="express-button-success"
              //           type="default"
              //           onClick={() => setOpen(false)}
              //         >
              //           Add Selected
              //         </Button>
              //       </div>
              //     </>
              //   ),
              // },
            ]}
          ></Tabs>
        </div>
      </Modal>
    </>
  );
};

export default ExpressOffers;
