import { Button } from "antd";
import { useEffect, useRef, useState } from "react";
import useAxios from "../../../hooks/useAxios.hook";
import { ApiErrorMessages } from "../../../types/response.interfaces";
import checkIcon from "../../../assets/images/icons/check-green.svg";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { UserAuth } from "../../../types/user.interfaces";
import { UserCompany } from "../../../types/company.interfaces";
import useAuth from "../../../hooks/useAuth.hook";
import { COMPANY_TYPES } from "../../../utility/enums/user.enums";
import { PLATFORM_VIEW_MODE } from "../../express/types/express.enum";

const CodeAuthentication = () => {
  const { token } = useParams();
  const location = useLocation();
  const { myAxios } = useAxios();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const navigate = useNavigate();
  const { setAuth, setCompany } = useAuth();
  // const { response: responseValidate, axiosFetch: fetchValidate } = useAxios();
  // const { response: responseResend, axiosFetch: fetchResend } = useAxios();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [codeSent, setCodeSent] = useState<boolean>(false);
  const userRef1 = useRef<HTMLInputElement>(null);
  const userRef2 = useRef<HTMLInputElement>(null);
  const userRef3 = useRef<HTMLInputElement>(null);
  const userRef4 = useRef<HTMLInputElement>(null);
  const userRef5 = useRef<HTMLInputElement>(null);
  const userRef6 = useRef<HTMLInputElement>(null);

  const [code1, setCode1] = useState<string>("");
  const [code2, setCode2] = useState<string>("");
  const [code3, setCode3] = useState<string>("");
  const [code4, setCode4] = useState<string>("");
  const [code5, setCode5] = useState<string>("");
  const [code6, setCode6] = useState<string>("");

  const getCode = (): string => {
    return code1 + code2 + code3 + code4 + code5 + code6;
  };

  const login = async (code: string, token: string) => {
    setErrorMessage(undefined);
    setLoading(true);
    const { response } = await myAxios({
      method: "post",
      url: "/user/validateonetimecode",
      data: { code, token },
    });

    if (response?.data?.status) {
      const {
        id,
        tokens,
        onboardingStatus,
        email,
        networks,
        isVerified,
        userIntegrations,
        companies,
        isSuperAdmin,
        firstName,
        lastName,
      }: UserAuth = response.data.result;
      const auth: UserAuth = {
        id,
        tokens,
        onboardingStatus,
        firstName,
        lastName,
        email,
        companies,
        networks,
        isSuperAdmin,
        isVerified,
        userIntegrations,
      };

      setAuth(auth);
      onboardingStatus.completed &&
        localStorage.setItem("user", JSON.stringify(auth));

      if (response.data.result.companies && response.data.result.companies[0]) {
        const _firstCompany: UserCompany = response.data.result.companies[0];
        const _company: UserCompany = {
          ..._firstCompany,
          companyCurrentType:
            _firstCompany.companyType === COMPANY_TYPES.both
              ? COMPANY_TYPES.advertiser
              : _firstCompany.companyType,
        };
        response.data.result.userIntegrations &&
          response.data.result.userIntegrations.length > 0 &&
          getUserFromExpressId(_firstCompany.company_Id);
        localStorage.setItem("company", JSON.stringify(_company));
        setCompany(_company);
      }

      navigate("/");
    } else {
      response?.data?.result?.length > 0 &&
        response?.data?.result.forEach((value: ApiErrorMessages) => {
          setErrorMessage(value.message);
        });
    }

    setLoading(false);
  };

  const getUserFromExpressId = async (id: string) => {
    const { response } = await myAxios({
      method: "GET",
      url: `/express/${id}`,
    });
    if (response?.data?.status) {
      const _userCompany = response?.data?.result.company;
      const _company: UserCompany = {
        company_Id: _userCompany.id,
        companyType: COMPANY_TYPES.publisher,
        companyCurrentType: COMPANY_TYPES.publisher,
        name: _userCompany.name,
        platformViewMode: PLATFORM_VIEW_MODE.express,
      };
      localStorage.setItem("company", JSON.stringify(_company));
    }
  };

  const handleSubmit = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    token && login(getCode(), token);
  };

  useEffect(() => {
    userRef1?.current?.focus();
  }, []);

  useEffect(() => {
    setErrorMessage("");
  }, [code1, code2, code3, code4, code5, code6]);

  const handleInput1 = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace("-", "");
    setCode1(value.charAt(0));
    value.charAt(1) !== "" && setCode2(value.charAt(1));
    value.charAt(2) !== "" && setCode3(value.charAt(2));
    value.charAt(3) !== "" && setCode4(value.charAt(3));
    value.charAt(4) !== "" && setCode5(value.charAt(4));
    value.charAt(5) !== "" && setCode6(value.charAt(5));
    value.length > 1 && userRef1?.current?.blur();
    value.length === 1 && userRef2?.current?.focus();
  };

  const handleInput2 = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setCode2(value);
    e.target.value !== "" && userRef3?.current?.focus();
  };

  const handleInput3 = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setCode3(value);

    e.target.value !== "" && userRef4?.current?.focus();
  };

  const handleInput4 = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setCode4(value);

    e.target.value !== "" && userRef5?.current?.focus();
  };

  const handleInput5 = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setCode5(value);
    e.target.value !== "" && userRef6?.current?.focus();
  };

  const handleInput6 = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setCode6(value);
  };

  const handleResendCode = async (e: React.MouseEvent<HTMLSpanElement>) => {
    setCodeSent(false);

    if (location?.state?.email && token) {
      const { response } = await myAxios({
        method: "post",
        url: "/user/resendonetimecode",
        data: { email: location?.state?.email, token },
      });

      if (response?.data?.status) {
        if (response?.data?.status) {
          setCode1("");
          setCode2("");
          setCode3("");
          setCode4("");
          setCode5("");
          setCode6("");
          setCodeSent(true);
        } else {
          response?.data?.result?.length > 0 &&
            response?.data?.result.forEach((value: ApiErrorMessages) => {
              setErrorMessage(value.message);
            });
        }
      } else {
        response?.data?.result?.length > 0 &&
          response?.data?.result.forEach((value: ApiErrorMessages) => {
            setErrorMessage(value.message);
          });
      }
    }
  };

  return (
    <>
      <div className="unauth-container-wrapper">
        <div className="unauth-container">
          <div className="widget unauth-widget">
            <form className="widget-form verification">
              <h3 className="widget-form-title">Confirm your login, please</h3>
              <div className="widget-form-description">
                <span>Please enter the 6 digit code we sent to </span>
                <span className="font-warning font-small semibold">
                  {location?.state?.email}
                </span>
                <div>
                  or
                  <span
                    className="font-active font-small font-hover"
                    onClick={handleResendCode}
                  >
                    {" "}
                    click to resend{" "}
                  </span>
                </div>
              </div>

              {codeSent && (
                <>
                  <div className="widget-form-row unauth-link">
                    <span className="font-warning font-small">
                      <img src={checkIcon} alt="completed" /> New code has been
                      sent to your email.
                    </span>
                  </div>
                </>
              )}

              <div className="widget-form-row widget-form-row-inline">
                <div className="widget-input email-verification-input">
                  <input
                    type="text"
                    id="code0"
                    ref={userRef1}
                    value={code1}
                    onChange={handleInput1}
                    aria-describedby="nide"
                    autoComplete="off"
                  />
                </div>
                <div className="widget-input email-verification-input">
                  <input
                    type="text"
                    id="code0"
                    maxLength={1}
                    ref={userRef2}
                    value={code2}
                    onChange={handleInput2}
                    onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
                      if (code2 === "") {
                        event.keyCode === 8 && userRef1?.current?.focus();
                      }
                    }}
                    aria-describedby="nide"
                    autoComplete="off"
                  />
                </div>
                <div className="widget-input email-verification-input">
                  <input
                    type="text"
                    id="code0"
                    maxLength={1}
                    ref={userRef3}
                    value={code3}
                    onChange={handleInput3}
                    onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
                      if (code3 === "") {
                        event.keyCode === 8 && userRef2?.current?.focus();
                      }
                    }}
                    aria-describedby="nide"
                    autoComplete="off"
                  />
                </div>
                <div className="widget-input-devider">
                  <span></span>
                </div>
                <div className="widget-input email-verification-input">
                  <input
                    type="text"
                    id="code0"
                    maxLength={1}
                    ref={userRef4}
                    value={code4}
                    onChange={handleInput4}
                    onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
                      if (code4 === "") {
                        event.keyCode === 8 && userRef3?.current?.focus();
                      }
                    }}
                    autoComplete="off"
                  />
                </div>

                <div className="widget-input email-verification-input">
                  <input
                    type="text"
                    id="code0"
                    maxLength={1}
                    ref={userRef5}
                    value={code5}
                    onChange={handleInput5}
                    onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
                      if (code5 === "") {
                        event.keyCode === 8 && userRef4?.current?.focus();
                      }
                    }}
                    autoComplete="off"
                  />
                </div>

                <div className="widget-input email-verification-input">
                  <input
                    type="text"
                    id="code0"
                    maxLength={1}
                    ref={userRef6}
                    value={code6}
                    onChange={handleInput6}
                    onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
                      if (code6 === "") {
                        event.keyCode === 8 && userRef5?.current?.focus();
                      }
                    }}
                    autoComplete="off"
                  />
                </div>
              </div>

              {errorMessage && (
                <div className="widget-form-error">
                  <p className="error-show error-line">{errorMessage}</p>
                </div>
              )}

              <div className="widget-form-actions unauth-button">
                <div className="widget-form-button">
                  <Button
                    type="default"
                    loading={isLoading}
                    onClick={handleSubmit}
                    className="button-success"
                    htmlType="submit"
                    disabled={getCode().length < 6}
                  >
                    Next
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default CodeAuthentication;
